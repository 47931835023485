<template>
  <div class="s_board">
	<date-range-picker ref="picker" class="datePicker" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
          :showWeekNumbers="true" :showDropdowns="true" :timePicker="true" :autoApply="true" opens="right" @select="fetchPicks" @update="fetchPicks" v-model="dateRange">
          <template v-slot:input="picker">
            {{ moment(picker.startDate).format("DD-MM-YYYY") | date }} -
            {{ moment(picker.endDate).format("DD-MM-YYYY") | date }}
          </template>
    </date-range-picker>
	<br><br>
	<div class="flex s_board__conn">
		<div :class="'s_board__chart flex-50 flex-100-md flex-100-sm ' + (isMaximizedPick ? 'maximized' : 'minimized') ">
			<span @click="isMaximizedPick = !isMaximizedPick;">
				{{ isMaximizedPick ? "↙️" : "↗️" }}
			</span>
			<div v-if="picks.length === 0">No data</div>
			<GChart v-if="ready" :type="'ColumnChart'" :data="filteredPicks" :options="chartOptionsPick" />
			<div class="s_board__table">
			<span v-if="JSON.stringify(player_picks) === '{}'" @click="() => { player_picks = getUserSum(picks)}">...</span>
			<div v-if="Object.keys(player_picks).length > 0">
				<table class="table-m">
					<tr>
						<th><p>Player</p></th>
						<th><p>Picks</p></th>
					</tr>
					<tr v-for="(value, key) in player_picks" :key="value+key">
						<td><p>{{ key }}</p></td>
						<td><p>{{ value }}</p></td>
					</tr>
				</table>
			</div>
		</div>
		</div>
		<div :class="'s_board__chart flex-50 flex-100-md flex-100-sm ' + (isMaximizedPack ? 'maximized' : 'minimized') ">
			<span @click="isMaximizedPack = !isMaximizedPack;">
				{{ isMaximizedPack ? "↙️" : "↗️" }}
			</span>
			<div v-if="packs.length === 0">No data</div>
			<GChart v-if="ready" :type="'ColumnChart'" :data="filteredPacks" :options="chartOptionsPack" />
			<div class="s_board__table">
				<span v-if="JSON.stringify(player_packs) === '{}'" @click="() => { player_packs = getUserSum(packs)}">...</span>
				<div v-if="Object.keys(player_packs).length > 0">
					<table class="table-m">
						<thead>
							<tr>
								<th width="50%" style="text-align: left;"><p>Player</p></th>
								<th width="50%" style="text-align: center;"><p>Packs</p></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(value, key) in player_packs" :key="value+key">
								<td width="50%"><p>{{ key }}</p></td>
								<td width="50%" style="text-align: center;"><p>{{ value }}</p></td>
							</tr>
						</tbody>
						
					</table>
				</div>
			</div>

		</div>
		
	</div>

	<br /><br />
	<div class="flex">
		<div class="flex-100">
			<highcharts ref="packChart" :options="chartOptions"></highcharts>
		</div>
	</div>
	
  </div>
</template>
<script>
import api from "@/api";
import { GChart } from "vue-google-charts";
import Highcharts from 'highcharts';

import moment from "moment";

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Scoreboard",
  data() {
	const today = moment();
	const isWeekend = today.day() === 6 || today.day() === 0; // 6 = Saturday, 0 = Sunday

	return {
		moment,
		dateRange: {
			startDate: isWeekend ? moment().subtract(1, 'weeks').startOf('isoWeek') : moment().startOf("day"),
			endDate: isWeekend ? moment().subtract(1, 'weeks').endOf('isoWeek') : moment().endOf("day"),
		},
		isMaximizedPick: false,
		isMaximizedPack: false,
		ready: false,
		picks: [],
		packs: [],
		packs_details: [],
		player_picks: {},
		player_packs: {},
		chartOptions: {
			chart: {
				type: 'column',
			},
			title: {
				text: 'Packed by type',
			},
			xAxis: {
				categories: [], // Will be populated with hours
				title: {
					text: 'Hour',
				},
			},
			yAxis: {
				min: 0,
				title: {
					text: 'Count',
				},
				stackLabels: {
					enabled: true,
					style: {
					fontWeight: 'bold',
					color:
						(Highcharts.defaultOptions.title.style &&
						Highcharts.defaultOptions.title.style.color) ||
						'gray',
					},
				},
			},
			credits: {
				enabled: false,
			},
			legend: {
				align: 'center',
				verticalAlign: 'bottom',
				layout: 'horizontal',
				enabled: false,
			},
			tooltip: {
			//   shared: true,
			//   formatter: function () {
			//     let tooltipHtml = `<b>Hour: ${this.x}</b><br/>`;
			//     this.points.forEach((point) => {
			//       tooltipHtml += `${point.series.name}: ${point.y}<br/>`;
			//     });
			//     return tooltipHtml;
			//   },
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					dataLabels: {
					enabled: false,
					},
				},
			},
			series: [], // Will be populated with data
		},
		
    };
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchPicks();
    this.ready = true;
  },
  methods: {
	redrawChart() {
		this.ready = false; // Temporarily hide chart
		
		this.$nextTick(() => {
			this.ready = true; // Redraw chart
		});
    },
    async fetchPicks() {
		const pick = await api.get("/report/pick?start=" + new Date(this.dateRange.startDate) + "&end=" + new Date(this.dateRange.endDate));
		const pack = await api.get("/report/pack?start=" + new Date(this.dateRange.startDate) + "&end=" + new Date(this.dateRange.endDate));
		const packs_details = await api.get("/report/pack_details?start=" + new Date(this.dateRange.startDate) + "&end=" + new Date(this.dateRange.endDate));
		packs_details

		this.picks = pick.data;
		this.packs = pack.data;

		// Set categories (users) and series (package types)
        this.chartOptions.xAxis.categories = packs_details.data.categories; // Users
        this.chartOptions.series = packs_details.data.series; // Package types data
    },
    getUserSum(list) {
		let player_index = [];
		let players = {};
		list.forEach((item, idx) => {
			if (idx === 0) {
				// We are in the header
				player_index.push(0) // Add the first column so that we don't have to think about it later
				item.forEach((player, idx) => {
					if (idx > 0) {
						player_index.push(player);
						players[player] = 0;
					}
				});
			} else {
				// We are in the data
				item.forEach((player, idx) => {
					if (idx > 0) {
						players[player_index[idx]] += player;
					}
				});
			}
		});

		// Sort the players
		players = Object.fromEntries(
			Object.entries(players).sort(([,a],[,b]) => b-a)
		);

		return players;
	},
  },
  components: {
    GChart,
	DateRangePicker,
  },
  computed: {
    filteredPicks() {
      return this.picks.filter((row, index) => {
        // Keep the header row and rows where there is at least one non-zero value in the data columns
        return index === 0 || row.slice(1).some((value) => value !== 0);
      });
    },
    filteredPacks() {
      return this.packs.filter((row, index) => {
        // Keep the header row and rows where there is at least one non-zero value in the data columns
        return index === 0 || row.slice(1).some((value) => value !== 0);
      });
    },
	hoursTicksPicks() {
		// Extract unique hours from the picks data
		const hours = this.picks.slice(1).map(row => row[0]);
		return [...new Set(hours)]; // Remove duplicates
	},
	hoursTicksPacks() {
		// Extract unique hours from the packs data
		const hours = this.packs.slice(1).map(row => row[0]);
		return [...new Set(hours)]; // Remove duplicates
	},
	chartOptionsPick() {
		return {
			title: "Picking Performance",
			titleTextStyle: { color: "#444444", fontSize: 20 }, // Title color and size
			// backgroundColor: "#000000", // Set chart background to black
			height: 400, // Set chart height directly here
			// chartArea: {
			// 	width: '60%', // Adjust width as needed
			// 	height: '60%' // Adjust height within the chart area
			// },
			hAxis: {
				title: "Hour",
				// textStyle: { color: "#FFFFFF" }, // Axis text color for readability
				// titleTextStyle: { color: "#FFFFFF" }, // Axis title color
				gridlines: { color: "#f0eeee" }, // Dark gray gridlines for contrast
				ticks: this.hoursTicksPicks,
			},
			vAxis: {
				title: "Count",
				// textStyle: { color: "#FFFFFF" },
				// titleTextStyle: { color: "#FFFFFF" },
				gridlines: { color: "#f0eeee" },
			},
				legend: {
				position: "top",
				// textStyle: { color: "#FFFFFF" }, // Legend text color
			},
			
			bar: { groupWidth: "35%" },
			isStacked: true,
			colors: [
				"#2E7D32",  // Dark Green
				"#388E3C",  // Primary Green
				"#43A047",  // Accent Green
				"#4CAF50",  // Medium Green
				"#66BB6A",  // Lighter Green
				"#81C784",  // Light Green
				"#A5D6A7",  // Softer Green
				"#C8E6C9",  // Very Light Green
				"#E8F5E9",  // Lightest Green Background
				"#1B5E20"   // Deep Green
			]
		}
	},
	chartOptionsPack() { return {
			title: "Packing Performance",
			titleTextStyle: { fontSize: 20 },
			// backgroundColor: "#000000",
			height: 400, // Set chart height directly here
			// chartArea: {
			// 	width: '60%', // Adjust width as needed
			// 	height: '60%' // Adjust height within the chart area
			// },
			hAxis: {
				title: "Hour",
				// textStyle: { color: "#FFFFFF" },
				// titleTextStyle: { color: "#FFFFFF" },
				gridlines: { color: "#f0eeee" },
				ticks: this.hoursTicksPacks,
			},
			vAxis: {
				title: "Count",
				// textStyle: { color: "#FFFFFF" },
				// titleTextStyle: { color: "#FFFFFF" },
				gridlines: { color: "#f0eeee" },
			},
			legend: {
				position: "top",
				// textStyle: { color: "#FFFFFF" },
			},
			
			bar: { groupWidth: "35%" },
			isStacked: true,
			colors: [
				"#1B5E20",  // Deep Green
				"#2E7D32",  // Dark Green
				"#388E3C",  // Primary Green
				"#43A047",  // Accent Green
				"#4CAF50",  // Medium Green
				"#66BB6A",  // Bright Green
				"#8BC34A",  // Yellow-Green
				"#9CCC65",  // Fresh Green
				"#AED581",  // Soft Lime Green
				"#C0CA33",  // Olive Green
				"#CDDC39",  // Lively Green
				"#D4E157",  // Light Yellow-Green
				"#81C784",  // Soft Green
				"#A5D6A7",  // Pastel Green
				"#C8E6C9"   // Very Light Green
			]
		}
	}
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
  watch: {
    isMaximizedPick() {
      this.redrawChart();
    },
	isMaximizedPack() {
      this.redrawChart();
    },
  },
};
</script>
<style lang="scss" scoped>

.s_board {
	@include box-bg-solid;
	padding: 2rem;
	margin-top: -8rem;
	&__conn {
		align-items: flex-start
	}
	&__chart {
		// @include box-bg-white;
		padding: 0.5rem;
	}
	&__table {
		margin-top: 5rem;
		margin-left: -1rem;
		span {
			cursor: pointer;
			font-size: 2rem;
			margin-left: 1rem;
			font-size: 2rem;
			line-height: 3rem;
			display: flex;
			width: 98%;
			justify-content: center;
			align-items: center;
			background: var(--color-grey-light-2);
			text-align: center;
			border-bottom-left-radius: var(--border-radius);
			border-bottom-right-radius: var(--border-radius);
			&:hover {
				background: var(--color-primary);
			}
		}
	}
	
	@media all and (max-width:  $bp-medium ) {
		margin-top: 2rem !important;
	}
}

.maximized {
  display: table;
  width: 80vw;
  height: 80vh;
  position: fixed;
  vertical-align: middle;
  top: 10vh;
  left: 12vw;
  background-color: #000;
  z-index: 999991000;
  
}

.minimized {
  width: auto;
  height: auto; /* Adjust the default size */
  span {
	margin-top: -5rem;
  }
}


</style>
