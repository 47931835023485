<template>
  <div class="orgs-section">
    <h2>{{trx.shops['Shops']}} <router-link :to="{ path:'/orgs/add' }" class="new" > +</router-link></h2>
   

    <div class="orgs">
       <input type="text" v-model="searchTerm" placeholder="Search organizations...">
       <div v-for="org in filteredOrgs" :class=" current_org_auth ? org._id === current_org_auth._id ? 'flex org active ' : 'flex org ' : 'flex org '  " :key="org.id" :style="org.active == false ? 'background-color: red;' : null"  >
          
          <div class="flex-30">
            <h3>{{org.name}} - {{org.users.length}}</h3>
            {{org._id}}
          </div>
          <div class="flex-20 flex-20 org__details">
            <h4>{{org.contact_person}} / {{org.phone}}</h4>
          </div>
          <div v-if="authCheck('/orgs/delete/*', 'DELETE', user.curr_role)" class="flex-20 orgs__delete">
            <button v-if="org.active == false" v-on:click="activateOrg(org._id)">Activeren</button>
            <button v-if="org.active == true || org.active == null" style="background-color: red;" v-on:click="deleteOrg(org._id)">Deactiveren</button>
          </div>
          <div class="flex-20 controls">
              <a v-on:click="changeOrg(org._id)" title="Set as current">
                <i class="fas fa-arrow-right"></i>  
                <img class="set_current" src="@/assets/icons/right-icon.svg">
              </a>
          </div>
      </div>
    </div>  
    <section v-show='this.$route.name == "add_organization"' class="sub backdrop light big">
          <router-view></router-view>
    </section>
  </div>
</template>

<script>
import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';
import authCheck from "@/lib/authCheck";

export default {
  name: 'Organizations',
  components: {
  },
  methods: {
    ...mapActions(['setCurrOrg', 'deleteOrg', 'activateOrg', 'fetchOrgs', 'fetchDeactiveOrgs']),
    authCheck,
    async changeOrg(org_id) {
      await this.setCurrOrg(org_id);
      this.fetchOrgs();
    },
    
  },
  created() {
    this.fetchOrgs();
    if (this.authCheck('/orgs/delete/*', 'DELETE', this.user.curr_role)) {
      this.fetchDeactiveOrgs();
    }
  },
  computed: {
    ...mapGetters(['orgs', 'deactiveOrgs', 'current_org_auth', 'user']),
    filteredOrgs() {
      const combinedOrgs = [...this.orgs, ...this.deactiveOrgs];
      const filteredOrgs = combinedOrgs.filter(org => org.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || org.contact_person.toLowerCase().includes(this.searchTerm.toLowerCase()));
      return filteredOrgs;
    }
  },
  data () {
    return {
      trx,
      searchTerm: '' 
    }
  }
}
</script>

<style lang="scss" scoped>
.add_org {
  margin: 5rem 0rem;
}
.new {
    justify-content: center;
    align-items: center;
    padding: 1rem 1.5rem;
    
    @include box-bg-dash;
    &:hover {
      @include inverse-primary-light;
      cursor: pointer;
      transition: all 0.2s ease;
      h3 {
        color: var(--color-white)
      }
    }
    color: var(--color-white);
    font-size: 2rem;
    background: var(--color-primary);
    border-radius: 50px;
  }
.orgs {
  margin:5rem 0rem;
  .org {
    @include box-bg-dash;
    padding: 2rem;
    background: var(--color-white);
    margin: 1rem;
    position: relative;
    &.active {
      @include box-bg;
      h3 {
        color: var(--color-white);
        font-size:2rem;
      }
    }
    &__details {
      // position: absolute;
      // background: var(--color-primary-dark);
      // h4 {
      //   color: var(--color-white);
      //   margin:0;
      // }
      // padding: 1rem;
      // line-height: 10px;
      // bottom:-2px; 
      // left: -2px;
      // border-radius: var(--border-radius);
      // border-top-left-radius: 0px;
      // border-bottom-right-radius: 0px;
    }
    
    h3 {
      font-size:2rem;
    }
    .controls {
      display: flex;
      justify-content: flex-end;
    }
    .set_current {
      width: 2rem;
      height: auto;
    }
  }
}
</style>
