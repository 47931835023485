<template>
<div class="inb" >
  <div class="inb__container">
    <!-- <div class="switchInput">
        <SwitchInput :id="inbound_item.product_id._id" :checked="inbound_item.product_id.fullfilled_bysf" :change="toggleFulfilment" :value="inbound_item.product_id._id" :name="inbound_item.product_id._id + '__check'" />
    </div> -->
      
    
    <div class="inb__container__name" >
      <span class="hidden">{{ inbound_item._id }}</span>
      <span :class="'inb__container__status ' + (inbound_item.status === 'pending' ? 'open' : 'closed')  "> {{ inbound_item.status === 'pending' ? '' : '' }}</span>
      <p :title="inbound_item.product_id.name">{{ inbound_item.product_id.name }}</p>
    </div>

    <div class="inb__container__ean">
        {{trx.orders["EAN"]}}
        <p> {{ inbound_item.product_id.ean }}</p>
    </div>

  

  <div class="inb__container__center">
    {{trx.inventory["delivered"]}}
    <p>{{ inbound_item.pieces_checked_in }}</p>
  </div>

  <div class="inb__container__boxes">
    <span>{{trx.inventory.Boxes}}</span>
    
    <p>{{ inbound_item.pieces_checked_in / inbound_item.packed_per  }} / <input v-if="inbound_item.status === 'pending'" v-model="inbound_item.units" type="number" :readonly="inbound_item.status != 'pending'" @change="edit_inb_item(inbound_item)" /> <span v-else>{{ inbound_item.units }}</span></p>
    
  </div>

  <div class="inb__container__boxes">
    <span>per</span>
    <p v-if="inbound_item.status != 'pending'">{{ inbound_item.packed_per }}</p>
    <p v-else><input  v-model="inbound_item.packed_per" type="number" :readonly="inbound_item.status != 'pending'" @change="edit_inb_item(inbound_item)" /></p>
  </div>

  <div class="inb__container__center">
    {{trx.inventory["Pre-announced"]}}
    <p>{{ inbound_item.pieces_expected }}</p>
  </div>

    <div v-if="authCheck('/inbound_item/*', 'GET', user.curr_role )" class="classDetails">
      <button @click="goToInboundDetails(inbound_item._id)">Details</button>
    </div> 

    <div v-if="inbound_item.status === 'pending' && authCheck('/inbound_item/delete/*/*', 'DELETE', user.curr_role )" class="classDetails">
      <button  @click="delete_inb_item({inbound_id: inbound_id, item_id: inbound_item._id})" class="btn_del">X</button>
    </div> 

    <div class="inb__container__pdf flex" >
      <div class="pdf_download flex-20" @click="download_qr(inbound_item._id)" >
        <svg
          width="30"
          height="30"
          viewBox="0 0 42 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5694 27.5926C19.0315 25.8713 19.0423 22.547 19.3542 22.547C20.2579 22.547 20.1719 26.5168 19.5694 27.5926ZM19.3865 32.6706C18.5581 34.8437 17.5253 37.3289 16.3312 39.416C18.2999 38.663 20.5269 37.5656 23.0981 37.06C21.7318 36.0272 20.4193 34.5425 19.3865 32.6706ZM9.26293 46.097C9.26293 46.183 10.683 45.516 13.0176 41.7721C12.2968 42.4499 9.88691 44.4079 9.26293 46.097ZM26.6807 17.2539H41.312V52.5412C41.312 53.9721 40.1609 55.1232 38.73 55.1232H2.582C1.15114 55.1232 0 53.9721 0 52.5412V2.62253C0 1.19167 1.15114 0.0405273 2.582 0.0405273H24.0987V14.6719C24.0987 16.092 25.2606 17.2539 26.6807 17.2539ZM25.82 35.7367C23.6683 34.4242 22.2375 32.6168 21.2262 29.9487C21.7103 27.9584 22.4742 24.9353 21.8932 23.0418C21.3876 19.8789 17.3317 20.1909 16.7507 22.3103C16.2128 24.2791 16.7077 27.0547 17.6222 30.5942C16.3742 33.5635 14.5345 37.5441 13.2328 39.8249C13.222 39.8249 13.222 39.8356 13.2112 39.8356C10.2957 41.331 5.2931 44.6231 7.34794 47.1513C7.95041 47.8936 9.06928 48.2271 9.66099 48.2271C11.5867 48.2271 13.5017 46.2906 16.2343 41.5785C19.01 40.664 22.0546 39.5236 24.7334 39.0825C27.068 40.352 29.8006 41.1804 31.6188 41.1804C34.7602 41.1804 34.9754 37.7377 33.7381 36.5113C32.2427 35.0482 27.8964 35.4677 25.82 35.7367ZM40.5589 11.3368L30.0158 0.793611C29.5316 0.309486 28.8754 0.0405273 28.1868 0.0405273H27.5413V13.8112H41.312V13.1549C41.312 12.4772 41.0431 11.8209 40.5589 11.3368ZM32.587 38.8028C33.0281 38.5123 32.318 37.5226 27.9824 37.8346C31.9738 39.5344 32.587 38.8028 32.587 38.8028Z"
            fill="black"
          />
        </svg>    
      </div>

      <!-- <div class="pdf_download flex-20"  @click="download_inbound_item_packing(inbound_item._id)">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.5" d="M2.87994 16.56C2.08465 16.56 1.43994 15.9153 1.43994 15.12V9.35999H2.51994" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M4.31988 1.44V15.12C4.31988 15.9153 3.67517 16.56 2.87988 16.56H15.1199C15.9152 16.56 16.5599 15.9153 16.5599 15.12V1.44H4.31988Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path opacity="0.5" d="M6.83997 10.08H14.04" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path opacity="0.5" d="M6.83997 12.96H14.04" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
          <path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M6.83997 4.32001H14.04V7.20001H6.83997V4.32001Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div> -->
    </div>
    
  </div>
</div>       

</template>

<script>
import {mapMutations, mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
import authCheck from '../../../lib/authCheck';
import api from "@/api";

// import SwitchInput from '@/components/Controls/Switch';
/* eslint-disable no-debugger */

export default {
  name: 'InboundItem',
  computed: {
    ...mapGetters(['selectedFiles', 'uploadStatus', 'user', 'pending_inbounds',])
  },
  methods: {
    authCheck,
    ...mapActions(['selectFiles', 'expand_amounts', 'upload','uploadImages', 'toggleFulfilment',
     'send_checkin_confirmation', 'update_check_in', 'cr_checkin_code', 'fetchInboundItem', 'edit_inb_item', 'delete_inb_item', 'fetchInboundItems' ]),
    ...mapMutations(['SET_SELECTED_FILES', 'upload', 'SET_UPLOAD_STATUS']),
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      this.SET_SELECTED_FILES(files);
    },
    async download_inbound_item_packing(id) {
      const response = await api.get(`/inbound_item/packing_slip/${id}`, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const pdfWindowShip = window.open(fileURL);
      pdfWindowShip.print();
    },
    goToInboundDetails(id) {
      this.$router.push(`/inbound_details/${id}`)
    },
    async download_qr(id) {
      const response = await api.get(`/inbounds/qr/${id}`, {
        responseType: "blob",
      });
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = window.URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const pdfWindowShip = window.open(fileURL);
      pdfWindowShip.print();
    },
    
  },
  components: {
    
  },
  created() {

  },
  data () {
    return {
      trx,
    }
  },
  props: {
    inbound_item: Object,
    inbound_id: String
  },
}
</script>

<style lang="scss" scoped>

.inb {
  &__container {
    @include box-bg-white;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-start;
    padding: 0.5rem;
    min-height: 50px;
    max-height: 50px;
    &__status {
      transform: rotate(270deg);
      position: absolute;
      left: -3rem;
      min-height: 5px;
      min-width: 20px;
      max-width: 50px;
      min-width: 50px;
      bottom: 11px;
      line-height: 0;
      text-align: center;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);

        &.open {
          background: var(--color-yellow);
          color: var(--color-white);

          padding: 1rem !important;
        }
        &.closed {
          background: var(--color-green);
          color: var(--color-white);
          padding: 1rem !important;
        }
    }

    div {
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }

    &__ean {
      border-right: 1px solid;
    }

    &__center {
      border-right: 1px solid;
      text-align: center;
    }

    &__boxes {
      border-right: 1px solid;
      text-align: center;
      position: relative;
      padding-right: 5px !important;
      flex-grow: 1;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      >span {
        position: absolute;
        rotate: 270deg;
        left: -1rem;
        bottom: 12px;
        min-width: 50px;
        max-width: 50px;
        color: var(--color-white);

        background: var(--color-grey-dark-3);
      }
      
      input {
        width: 50px;
        min-height: 40px;
        max-height: 40px;
        text-align: center;
        padding: 0px;
        font-size: 1.7rem;
        margin-right: 0;
        @include box-bg-dash;
      }
    }

    .classDetails {
      border-right: 1px solid;
    }

    &__name {
        width: 20%;
        position: relative;
        p { 
          min-height: 20px;
          max-height: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; 
          text-overflow: ellipsis;
        }
        border-right: 1px solid;
      p { 
        font-size: 1.7rem;
      }
    }

    &__pdf {
      cursor: pointer;
      flex-direction: row !important;
      width: 10%;
    }

    .borderRight {
      border-right: 1px solid;
    }
  }
}

</style>

