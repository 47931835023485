export default {
  "date_format": "DD-MM-YY",
  "date_time_format": "DD-MM-YY HH:MM",
  "time_format": "HH:MM",
  "delete": "حذف",
  "continue": "استمرار",
  "update": "تحديث",
  "Show preview": "عرض المعاينة",
  "Data preview": "معاينة البيانات",
  "Select a file to start import": "اختر ملفًا لبدء الاستيراد",
  "Total": "الإجمالي",
  "Count": "العدد",
  "Sub total": "المجموع الفرعي",
  "Discount": "خصم",
  "Add": "إضافة",
  "Import": "استيراد",
  "Date": "تاريخ",
  "Track & Trace": "تتبع وتعقب",
  "Phone": "هاتف",
  "Click here your select an Excel file": "اضغط هنا لاختيار ملف إكسل",
  "Something went wrong, please conduct your logs": "حدث خطأ ما، يرجى مراجعة السجلات",
  "Sender": "المرسل",
  "Email": "البريد الإلكتروني",
  "Location": "الموقع",
  "Comment": "تعليق",
  "Amount": "المبلغ",
  "search": "بحث",
  "expand": "توسيع",
  "condense": "تكثيف",
  "save": "حفظ",
  "read": "قراءة",
  "pick_note": "ملاحظة الاختيار",
  limit: "الحد",
  refresh: "تحديث",
  import: "استيراد",
  "common": {
    "name": "اسم",
    "search_results": "نتائج البحث",
    "select_date_time": "اختر التاريخ والوقت"
  },
  "admin": {
    "name": "إعدادات الإدارة",
    "search_orderid": {
      "name": "البحث برقم الطلب"
    },
    "ship_boxes": {
      "name": "صناديق الشحن والأبعاد",
      "max_size": "الحجم الأقصى (الطول، العرض، الارتفاع)",
      "package": "الحزمة",
      "product_size": "حجم المنتج",
      "package_cost": "تكلفة الحزمة",
      "purchase_cost": "تكلفة الشراء",
      "package_code": "رقم الصنف",
      "fulfillment_cost": "تكلفة التنفيذ",
      "location": "الموقع",
      "create": "إنشاء نوع تغليف جديد"
    },
    "labels": {
      "name": "ملصقات الفحص"
    },
    "locations": {
      "name": "إعدادات الموقع"
    },
    "shipment_method": {
      "name": "خيارات الشحن",
      "code": "الرمز",
      "price": "السعر",
      "purchase_price": "سعر الشراء",
      "cut_off": "الحد الأقصى",
      "late_drop_surcharge": "رسوم التسليم المتأخر",
      "peak_surcharge": "رسوم الذروة"
    },
    "surcharge": {
      "name": "الرسوم الإضافية",
      "code": "الرمز",
      "price": "السعر",
      "note": "ملاحظة",
      "type": "نوع"
    },
    "users": {
      "name": "المستخدمون والمجموعات",
      "password_hint": "على الأقل 9 أحرف"
    },
    "transfers": {
      "name": "جميع التحويلات المفتوحة"
    }
  },
  "settings": {
    "apis": "الروابط",
    "Settings": "الإعدادات",
    "Your organization": "متجرك",
    "Subscription": "الاشتراكات",
    "Users": "المستخدمون",
    "invoice": {
      "total_used_packaging": "التغليفات المستخدمة",
      "total_used_shipment_method": "طرق الشحن المستخدمة",
      "total_used_surcharge": "الرسوم الإضافية المستخدمة",
      "total_fulfilment": "التنفيذ الكلي",
      "total": "الإجمالي",
      "download_details": "تحميل التفاصيل",
      "rack-box": "مواقع الرف",
      "half-box": "مواقع نصف الرف",
      "warehousing": "مواقع المستودع",
      "pallet": "مواقع البليت",
      "1/2pallet": "مواقع نصف البليت",
      "1/3pallet": "مواقع ثلث البليت"
    }
  },
  "organizations": {
    "name": "المتاجر",
    "organization": "المتجر",
    "Organization address": "عنوان المتجر",
    "description": "نظرة عامة على جميع متاجرك",
    "Organization name": "اسم المتجر",
    "Your organization details": "تفاصيل متجرك",
    "type": "نوع المتجر",
    "about": "حول",
    "coc_number": "رقم السجل التجاري",
    "vat_number": "رقم الضريبة",
    "surchrages": "الرسوم الإضافية",
    "Contact Person": "جهة الاتصال",
    "Contact Person Email": "البريد الإلكتروني لجهة الاتصال",
    "delete_org_help": "منطقة خطرة، حذف المتجر الخاص بك",
    "Phone": "الهاتف",
    "Branche": "الفرع"
  },
  "users": {
    "Name": "الاسم",
    "Email": "البريد الإلكتروني",
    "Login": "تسجيل الدخول",
    "First name": "الاسم الأول",
    "Last name": "الاسم الأخير",
    "Password": "كلمة المرور",
    "Phone": "رقم الهاتف",
    "Licence": "الترخيص",
    "Verified": "موثق",
    "Signup": "التسجيل",
    "Create a new user": "إنشاء مستخدم جديد",
    "Users in your organization": "أعضاء فريق في متجرك",
    "Import users": "استيراد المستخدمين",
    "Stakeholder Group": "مجموعة أصحاب المصلحة",
    "Already have an account, login": "لديك حساب؟ تسجيل الدخول",
    "Signup to get started": "سجل للبدء",
    "Not a user? Signup!": "لا تمتلك حساب؟ سجل الآن!",
    "reset_password": "نسيت كلمة المرور؟",
    "reset": "استعادة كلمة المرور",
    "Please enter your email": "يرجى إدخال بريدك الإلكتروني",
    "Know your password?, Login": "تعرف على كلمة المرور؟ تسجيل الدخول!",
    "adress_hint": "ادخل عنوانك، استخدم فاصلة لفصل الرمز البريدي والمدينة"
  },


  "plans": {
    "name": "الخطط"
  },
  "subscriptions": {
    "name": "الأسعار",
    "Standard": "القياسية",
    "Discount for yearly payment": "خصم على الدفع السنوي",
    "Terms of Service": "شروط الخدمة",
    "Your current subscription": "اشتراكك الحالي",
    "I have read and accept the terms of condition": "لقد قرأت وأوافق على الشروط",
    "CSR Managers": "مديرو المسؤولية الاجتماعية",
    "free_plan_help": "",
    "Stakeholders": "أصحاب المصلحة",
    "Select your plan": "اختر خطتك",
    "Enterprise": "المؤسسية",
    "Start for free": "ابدأ مجانًا",
    "Price / order": "السعر لكل طلب",
    "Invoices": "الفواتير",
    "description": "يعرض نظرة عامة على جميع الاشتراكات المتاحة",
    "new": "اشتراك جديد",
    "Pricing knowledge": "معرفة التسعير"
  },
  "shops": {
    "Create a new Shop": "إنشاء متجر جديد",
    "Shops": "المتاجر"
  },
  "products": {
    "button_options_name": "خيارات المنتج",
    "button_link_name": "روابط المنتج",
    "Product": "المنتج",
    "search_placeholder": "البحث عن المنتج بالاسم أو الرقم التعريفي",
    "ProductName": "اسم المنتج",
    "Add inventory": "إضافة إلى المخزون",
    "Inventory checkin": "تسجيل المخزون",
    "Sync with bol.com": "التزامن مع bol.com",
    "Note": "ملاحظة",
    "Return instruction": "تعليمات الإرجاع",
    "sf_stock": "مخزون Smart",
    "total_stock": "إجمالي المخزون",
    "lvb_stock": "مخزون LVB",
    "warehouse_stock": "مخزون المستودع",
    "import_your_products": "استورد منتجاتك هنا",
    "create": "إضافة منتج جديد",
    "note": "ملاحظة",
    "ean": "EAN",
    "search": "البحث عن المنتج بالاسم أو الرقم التعريفي",
    "pick_note": "ملاحظة الاختيار",
    "pack_note": "ملاحظة التعبئة",
    "customs": {
      "button_name": "الجمارك",
      "settings": "إعدادات الجمارك",
      "customs": "الجمارك (الشحنات خارج الاتحاد الأوروبي)",
      "hs": "رمز HS",
      "value": "القيمة باليورو",
      "weight_kg": "الوزن بالكيلوغرام"
    },
    "recount": {
      "button_name": "إعادة العد",
      "recounting": "إعادة العد",
      "settings": "إعدادات إعادة العد"
    },
    "import": {
      "add_product": "⥊ إضافة منتج",
      "xlsx": "⥊ Excel",
      "barcode_sku_ean": "EAN / SKU / الباركود",
      "Product_name": "المنتج",
      "fullfilled_bysf": "تفعيل/تعطيل",
      "length": "الطول",
      "height": "الارتفاع",
      "width": "العرض",
      "weight_kg": "الوزن (كغ)"
    },
    "sync": {
      "bol": "➡ Bol.com",
      "woo": "⥊ WooCommerce",
      "shopify": "⥊ Shopify",
      "mirakl": "⥊ Mirakl",
      shopware: "⥊ Shopware",
      "excel": "⥊ Excel",
      "lightspeed": "⥊ Lightspeed",
      "help": "استخدم هذه الأزرار لاستيراد المنتجات التي نشرتها على منصات مختلفة.",
      "import_products": "الاستيراد"
    }
  },
  "inventory": {
    "Pre-announced": "مُعلن مسبقًا",
    delivered: "تم التسليم",
    "Box": "صندوق",
    "Boxes": "صناديق",
    "Pallet": "منصة نقالة",
    "Pallets": "منصات نقالة"
  },
  "returns": {
    "name": "المرتجعات",
    "return_number_or_barcode": "رقم المرتجع أو الباركود",
    "scan_help_text": "أدخل رقم المرتجع أو امسح الباركود",
    "location": "الموقع",
    "product": "المنتج",
    "return_id": "رقم المرتجع",
    "return_instruction": "تعليمات الإرجاع",
    "damage": "الضرر",
    "damaged_package": "ضرر في العبوة",
    "damaged_product": "ضرر في المنتج",
    "damaged_product_package": "ضرر في عبوة المنتج",
    "registered": "تاريخ التسجيل",
    "product_state": "حالة المنتج",
    "reason": "سبب الإرجاع من العميل",
    "customer_details": "تفاصيل العميل",
    "no_returns_to_show": "لا توجد مرتجعات للعرض",
    "handel_return_close": "إنهاء وإغلاق",
    "RETURN_RECEIVED": "تم استلام المرتجع",
    "EXCHANGE_PRODUCT": "تبادل المنتج",
    "RETURN_DOES_NOT_MEET_CONDITIONS": "المرتجع لا يستوفي الشروط",
    "REPAIR_PRODUCT": "إصلاح المنتج",
    "CUSTOMER_KEEPS_PRODUCT_PAID": "العميل يحتفظ بالمنتج مقابل الدفع",
    "STILL_APPROVED": "لا يزال موافقًا عليه",
    "product_excellent": "المنتج في حالة ممتازة ويمكن بيعه مجددًا",
    "product_good": "المنتج جيد ويمكن إعادته إلى الرف",
    "product_bad_state": "المنتج/التغليف تالف ولا يمكن بيعه"
  },
  "orders": {
    "Order ID": "رقم الطلب",
    "order": "الطلب",
    "Date": "التاريخ",
    "Add order": "إضافة طلب",
    "Your orders": "طلباتك",
    "Orders": "الطلبات",
    multi_orders: "طلبات متعددة",
    warning_orders: "تحذير: هذا الطلب يحتوي على منتجات متعددة",
    "Status": "الحالة",
    "Create": "إنشاء طلب",
    "Shipments": "الشحنات",
    "Products": "المنتجات",
    "Receiver": "المستلم",
    "name": "الاسم",
    "surename": "اللقب",
    "streetname": "اسم الشارع",
    "housenumber": "رقم المنزل",
    "addition": "الإضافة",
    "postalcode": "الرمز البريدي",
    "place": "المكان",
    "city": "المدينة",
    "country": "البلد",
    "company": "اسم الشركة",
    "Sync with bol.com": "التزامن مع bol.com",
    "Send products to us": "أرسل المنتجات إلينا",
    "EAN": "EAN",
    "Delivery time": "مدة التسليم",
    "Own stock": "المخزون الخاص",
    "SF stock": "مخزون SF",
    "Stock": "المخزون",
    "Fulfil by SF": "التنفيذ بواسطة SF",
    "Fulfilled by BOL (FBB)": "التنفيذ بواسطة BOL (FBB)",
    "FBB": "FBB",
    "SF": "SF",
    "Add inventory": "إضافة إلى المخزون",
    "Send your products to us": "أرسل منتجاتك إلينا",
    "Default shipment": "طريقة الشحن الافتراضية",
    "Location": "الموقع",
    "Box size": "حجم الصندوق",
    "Reciver": "المستلم",
    "email": "البريد الإلكتروني",
    "phone": "الهاتف",
    "Select products you want to send": "اختر المنتجات التي تريد إرسالها",
    "Wrong product": "منتج خاطئ",
    "Wrong quantity": "كمية خاطئة",
    "Damaged product": "منتج تالف",
    "Webshop order": "طلب المتجر الإلكتروني",
    "order_comments": "تعليقات الطلب",
    "import_your_orders": "استورد طلباتك هنا",
    "shipping_info": "معلومات الشحن",
    "confirmation": "التأكيد",
    "product_selector_text": "اختر المنتجات التي تريد إرسالها",
    "products_in_order": "المنتجات في هذا الطلب",
    "to_sending": "إلى معلومات الشحن",
    "to_confirm": "إلى التأكيد",
    "to_create": "تأكيد وإرسال",
    "shipping_method": "طريقة الشحن",
    "attachment": "المرفق (PDF فقط)",
    "attachment_placeholder": "التعليمات حول كيفية التعامل مع المرفق",
    "send": "تم إنشاء الطلب",
    "import": {
      "order_id": "رقم الطلب",
      "product_name": "المنتج",
      "quantity": "الكمية",
      "city": "المدينة",
      "countryCode": "رمز البلد",
      "email": "البريد الإلكتروني",
      "firstName": "الاسم الأول",
      "surName": "اللقب",
      "houseNumber": "رقم المنزل",
      "houseNumberExtension": "الامتداد",
      "language": "اللغة",
      "salutation": "التحية",
      "streetName": "اسم الشارع",
      "zipcode": "الرمز البريدي",
      "deliveryPhoneNumber": "رقم هاتف التوصيل",
      "reason": "السبب",
      "notes_text": "ملاحظة",
      "notes_color": "لون الملاحظة",
      "company": "الشركة"
    },
    "manual_send": {
      "reason": "سبب الطلب اليدوي",
      "received": "الطلب لم يتم استلامه بواسطة PostNL",
      "incorrect": "منتج خاطئ",
      "quantity": "كمية خاطئة",
      "damaged": "منتج تالف",
      "webshop": "طلب المتجر الإلكتروني"
    }
  },
  "pick_list": {
    "name": "قائمة الاختيار",
    "qty": "عدد القطع"
  },
  "shipments": {
    "sent_orders": "الطلبات المرسلة",
    "24h": "24 ساعة",
    "> 2 days": "> 2 أيام",
    "on_the_way": "في الطريق",
    "not_sorted": "غير مرتبة",
    "orderd_by": "مطلوب بواسطة",
    "order_date": "تاريخ الطلب",
    "sent_date": "تاريخ الإرسال",
    "delivery_date": "تاريخ التسليم",
    "shipment_method": "طريقة الشحن",
    "day_delivered": "< 24 ساعة تسليم",
    "two_day_delivered": "< 48 ساعة تسليم",
    "search_placeholder": "البحث بالاسم، الطلب أو الرمز البريدي",
    "Shipment Address": "عنوان الشحن",
    "Amount": "الكمية",
    "Ready for shipment": "جاهز للشحن",
    "Packed": "معبأ",
    "Shipped": "مُرسل",
    "Shipment options": "خيارات الشحن",
    "Open": "مفتوح",
    "Pallets": "منصات نقالة",
    "Coli": "صناديق/منصات نقالة",
    "Type": "نوع",
    "remark": "ملاحظة"
  },
  "checkinv": {
    "created_at": "تم الإرسال",
    "expected_delivery": "التسليم المتوقع",
    "status": "الحالة",
    "checked_in_date": "تاريخ التسجيل",
    "product_name": "اسم المنتج",
    "product_ean": "Ean/SKU",
    "expected": "متوقع",
    "final_check_in": "تسجيل نهائي",
    "comment": "تعليق",
    "pallets": "منصات نقالة",
    "pallet": "منصة نقالة",
    "box": "صندوق",
    "boxes": "صناديق",
    "change_checkin": "عرض/تعديل التسجيل",
    "search": "البحث عن التسجيل باسم",
    "edit_checkin": "عرض/تعديل التسجيل",
    "received": "تم الاستلام",
    "add_checkin": {
      "overview": "نظرة عامة",
      "out_of_stock": "غير متوفر",
      "application": "التطبيق",
      "reason": "ما الغرض من هذا؟",
      "stock_checkin": "تسجيل المخزون",
      "stock_checkin_info": "انقر هنا لإبلاغنا عن شحناتك المخطط لها، لنتمكن من التحضير لاستقبالها وتخزينها بعناية، وإدارة سلعك بفعالية.",
      "change_checkin": "عرض/تعديل التسجيل",
      "change_checkin_info": "عرض أو تعديل شحناتك المسجلة سابقًا للحفاظ على تفاصيلها محدثة وضمان تجربة لوجستية سلسة.",
      "type": "النوع",
      "products": "المنتجات",
      "data": "البيانات",
      "confirmation": "التأكيد"
    },
    "checkin_products": {
      "choose_products_in_box": "اختر المنتج الذي في هذا الصندوق",
      "no_products": "ليس لديك منتجات في نظامنا بعد، قم بذلك أولاً",
      "search_products": "البحث عن المنتج باسمه أو بالرقم التعريفي"
    },
    "checkin_product": {
      "unit_amount": "عدد الوحدات (صناديق)*",
      "amount": "الكمية",
      "box_amount": "عدد في الصندوق*",
      "total_amount": "المجموع الكلي للقطع (CE)",
      "expiration_date": "تاريخ الصلاحية",
      "batch_number": "رقم الدفعة",
      "add": "إضافة",
      "divergent": "* هل هناك صندوق مختلف؟ أكمل هذه الخطوة بالصناديق المتساوية ثم أضف نفس المنتج مرة أخرى في الخطوة السابقة.",
      "check": "تحقق من إعدادات منتجاتك بشكل صحيح",
      "back": "عودة",
      "totals": "المجموعات",
      "per_box": "المنتجات لكل صندوق",
      "boxes": "الصناديق",
      "submit_checkin": "تأكيد التسجيل"
    },
    "checkin_success": {
      "shipment_instructions": "تعليمات الشحن:",
      "documents_sent": "تم إرسال الوثائق عبر البريد الإلكتروني. يمكنك أيضًا تحميلها مباشرة من هنا",
      "checkin_step1": "قم بتحميل بطاقة الشحن وضعها في الحزمة",
      "checkin_step2": "ثم قم بتحميل رمز الاستجابة السريعة والصقه على الجانب الخارجي للحزمة",
      "checkin_important": "مهم: باستخدام رمز الاستجابة السريعة هذا، سيتم تسجيل حزمتك. إذا لم يتم إدراجه، فسيتأخر عملية التسجيل."
    },
    "checkin_information": {
      "type": "حدد المنتجات التي تحتويها",
      "products": "اختر منتجًا تلو الآخر لإضافته",
      "information": "املأ المعلومات أدناه لإكمال التسجيل"
    },
    "checkin_confirmation": {
      "contact_person_label": "جهة الاتصال",
      "contact_person_placeholder": "جهة الاتصال",
      "contact_person_email_label": "البريد الإلكتروني لجهة الاتصال",
      "contact_person_email_placeholder": "البريد الإلكتروني لجهة الاتصال",
      "phone_label": "رقم الهاتف",
      "phone_placeholder": "الهاتف",
      "shipping_address_label": "عنوان الشحن",
      "shipping_address_placeholder": "عنوان الشحن",
      "track_trace_label": "تتبع وتعقب",
      "track_trace_placeholder": "تتبع وتعقب",
      "expected_delivery_label": "التسليم المتوقع",
      "expected_delivery_placeholder": "التسليم المتوقع",
      "button_submit": "إنشاء التسجيل"
    },
    "checkin_done": {
      "confirmation_message": "تم استلام تسجيلك!",
      "email_instructions": "تم إرسال الوثائق عبر البريد الإلكتروني. يمكنك أيضًا تحميلها مباشرة من هنا.",
      "shipping_instructions_title": "تعليمات الشحن:",
     

 "shipping_instruction_1": "قم بتحميل بطاقة الشحن وضعها في الحزمة/أو اطلب من شخص آخر وضعها.",
      "shipping_instruction_2": "ثم قم بتحميل رمز الاستجابة السريعة والصقه على الجانب الخارجي للصندوق.",
      "important": "مهم:",
      "important_note": "باستخدام رمز الاستجابة السريعة هذا، سيتم تسجيل حزمتك. إذا لم يتم إدراجه، فسيتأخر عملية التسجيل وقد يؤدي إلى إعادة الحزمة إلى المورد.",
      "view_information_button": "عرض المعلومات",
      "documents_per_product": "الوثائق لكل منتج",
      "box_label": "صندوق",
      "packing_slip_label": "بطاقة الشحن",
      "qr_code_label": "رمز الاستجابة السريعة",
      "download_all_documents_button": "تحميل جميع الوثائق",
      "processing_shipment": "نحن نعالج تسجيلك. ابقَ على هذه الصفحة!"
    }
  },
  "inbound_details": {
    "delivery": {
      "delivery": "التسليم",
      "expected": "متوقع",
      "delivered": "تم التوصيل",
      "organisation": "المنظمة",
      "contactInformation": "معلومات الاتصال",
      "units": "الوحدات",
      "packedPer": "معبأة لكل",
      "transit": "في العبور"
    },
    "location": {
      "location": "الموقع",
      "expirationDate": "تاريخ انتهاء الصلاحية",
      "isPackageClosed": "هل وصل الطرد مغلقًا"
    },
    "extraWork": "الأعمال الإضافية والرسوم",
    "note": "ملاحظات"
  },
  "history_locations": {
    "button_name": "المواقع التاريخية",
    "name": "المواقع التاريخية للمنتج",
    "none": "لم يتم العثور على مواقع تاريخية"
  },
  "stock_analysis": {
    "button_name": "تحليل المخزون",
    "analysis": "تحليل",
    "checked_in": "تم التسجيل",
    "date": "التاريخ",
    "amount": "الكمية",
    "retours": "المرتجعات",
    "period": "الفترة",
    "orders": "الطلبات",
    "logical_stock": "المخزون المنطقي"
  },
  "automations": {
    "button_name": "الأتمتة"
  },
  "validation_trx": {
    "fieldIsRequired": "هذا الحقل مطلوب!",
    "invalidFormat": "تنسيق غير صحيح!",
    "numberTooSmall": "الرقم صغير جدًا! الحد الأدنى: {0}",
    "numberTooBig": "الرقم كبير جدًا! الحد الأقصى: {0}",
    "invalidNumber": "رقم غير صالح",
    "invalidInteger": "القيمة ليست عددًا صحيحًا",
    "textTooSmall": "طول النص صغير جدًا! الحالي: {0}, الحد الأدنى: {1}",
    "textTooBig": "طول النص كبير جدًا! الحالي: {0}, الحد الأقصى: {1}",
    "thisNotText": "هذا ليس نصًا!",
    "thisNotArray": "هذا ليس مصفوفة!",
    "selectMinItems": "اختر على الأقل {0} عناصر!",
    "selectMaxItems": "اختر أقصى {0} عناصر!",
    "invalidDate": "تاريخ غير صالح!",
    "dateIsEarly": "التاريخ مبكر جدًا! الحالي: {0}, الحد الأدنى: {1}",
    "dateIsLate": "التاريخ متأخر جدًا! الحالي: {0}, الحد الأقصى: {1}",
    "invalidEmail": "البريد الإلكتروني غير صحيح!",
    "invalidURL": "URL غير صحيح!",
    "invalidCard": "تنسيق البطاقة غير صحيح!",
    "invalidCardNumber": "رقم البطاقة غير صحيح!",
    "invalidTextContainNumber": "نص غير صالح! يجب ألا يحتوي على أرقام أو رموز خاصة",
    "invalidTextContainSpec": "نص غير صحيح! يجب ألا يحتوي على رموز خاصة",
    "add_product_name": "أضف اسم المنتج",
    "add_ean": "أضف EAN",
    "invalid_product_items": "أضف على الأقل منتج واحد",
    "invalid_streetname": "أضف اسم الشارع",
    "invalid_housenumber": "أضف رقم المنزل",
    "invalid_postcode": "أضف الرمز البريدي",
    "invalid_country": "أضف البلد",
    "invalid_ship_option": "اختر خيار الشحن",
    "invalid_type": "اختر سبب إنشاء هذا الطلب",
    "invalid_email": "أضف البريد الإلكتروني",
    "invalid_EAN_input": "EAN غير صحيح، يجب أن يكون 13 رقمًا",
    "invalid_phone": "أضف رقم الهاتف",
    "invalid_firstname": "أضف الاسم",
    "invalid_surname": "أضف اللقب",
    "invalid_company": "أضف اسم الشركة",
    "invalid_city": "أضف المدينة",
    "invalid_hs": "أضف رمز HS",
    "invalid_value": "أضف قيمة",
    "invalid_weight": "أضف وزن",
    "invalid_shipment_method": "اختر طريقة الشحن",
    "invalid_attachment_text": "أضف تعليقًا على المرفق",
    "invalid_attachment_files": "أضف مرفقًا إذا كان لديك تعليمات"
  }
}