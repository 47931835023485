<template>
  <div class="flex-80-md">
    <div class="assign_location" draggable @dragend="dragEnd($event, node._id)" @dragstart="startDrag($event, node)" >  
     
      <div class="location_box flex flex-nowrap flex-90 flex-80-md flex-80-sm">
        
        <div class="location flex-70 flex-80-md flex-80-sm">
          <p drag class="drag_icon">≡</p>
          <div class="loc"> <span>{{location_rack}}</span>-<span>{{location_number}}</span></div>
          <input v-model="stock" type="number" v-on:change="update_location_stock({product_id: node.product_id, location_product_id: node._id,  amount: stock, search: null, product_ean: ean })" placeholder="Amount" >
        </div> 
        <div class="buttonsContainer flex-30 flex-30-md flex-10-sm">
          
          <button class="transferButton" @click="toggleTransferAndFocus">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M4 7h16M4 7l4-4M4 7l4 4"/>
              <path d="M20 17H4M20 17l-4-4M20 17l-4 4"/>
            </svg>
          </button>
          <button  class="color_dark_red btn-danger" v-on:click="deassign_location({location_product_id: node._id, product_id: node.product_id, location_id: node.location_id._id, product_ean: ean, search: null })" >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="3 6 5 6 21 6"/>
              <path d="M19 6L17.5 21H6.5L5 6"/>
              <path d="M10 11V17"/>
              <path d="M14 11V17"/>
              <path d="M8 6V3H16V6"/>
            </svg>
          </button>
            
        </div>
        <!-- <div class="print">
          <button v-on:click="print_label({_id: id, rack: location_rack, number: location_number})">⎙</button>
        </div> -->
      </div>

      <input v-if="show_transfer" ref="showTransferInput" v-model="new_transfer" type="text" v-on:change="new_transfer_location( { product_id: node.product_id, location_product_id: node._id, new_loc_id: new_transfer })" placeholder="scan_location" >
      <!-- <button v-if="show_transfer" class="transferButton" @click="transfer_location({loc_prod_id: node._id })">🔀</button> -->

    </div>

    <div class="drop-zone"
      :ref="node._id"
      @dragover.prevent
      @dragenter="onDragEnter($event, node._id)" 
      @dragleave="onDragLeave($event, node._id)"
      @drop="onDrop($event, node._id)">
      <br>
    </div>
  </div>
  
</template>

<script>

import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';

/* eslint-disable no-debugger */


export default {
  name: 'AssignLocation',
  computed: {
    ...mapGetters(['products'])
  },
  methods: {
    ...mapActions(['setProductLocation', 'switch_loc_position', 'deassign_location', 'transfer_location', 'new_transfer_location', 'update_location_stock','link_barcode',  'linkProductLocation', 'print_label', 'addTransfer']),
    startDrag (evt, item) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.target.classList.add('dragging')
      evt.dataTransfer.setData('itemID', item._id)
    },
    toggleTransferAndFocus() {
        this.show_transfer = !this.show_transfer;

        // Ensure the DOM updates before focusing
        this.$nextTick(() => {
            if (this.show_transfer && this.$refs.showTransferInput) {
                this.$refs.showTransferInput.focus();
            }
        });
    },
    dragEnd (evt) {
      evt.target.classList.remove('dragging')
    },
    onDrop (evt, id) {
        const itemID = evt.dataTransfer.getData('itemID');
        this.$refs[id].classList.remove('drag-enterd')
        this.switch_loc_position({id_to_switch: itemID, id_to_be: id, product_id: this.node.product_id })
    },
    onDragEnter (evt, id) {
      evt.prevent;
      this.$refs[id].classList.add('drag-enterd');
    },
    onDragLeave (evt, id) {
      evt.prevent;
      this.$refs[id].classList.remove('drag-enterd');
    }
  },
  components: {
   
  },
  props: {
    id: String,
    location_rack: String,
    location_number: String,
    stock: Number,
    node: Object,
    ean: String,
    inboundItemId: String,
  },
  data () {
    return {
      trx,
      amount: 0,
      new_transfer: null,
      show_transfer: false,
    }
  }
}

</script>

<style lang="scss" scoped>

  .drag {
    cursor:move;
    z-index: 9999;
  }

  .drag_icon {
    line-height: 0rem;
    font-weight: 400;
    font-size: 5rem;
    position: absolute;
    top: 1.9rem;
    left: -0.5rem;
    z-index: 1;
    cursor: move;
    // @media all and (max-width: $bp-medium) {
    //    top: 5.5rem
    // }
  }
  
  .buttonsContainer {
    display: flex;
    align-content: center;
    @mixin icon-box {
      max-height: 4rem;
      max-width: 4rem;
      min-width:  4rem;
      min-height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0.5rem 0.2rem;
      padding: 0;
    }
    .transferButton {
      margin-right: 0.5rem;
      // font-size: 2rem;
      @include icon-box;
      @media all and (max-width: $bp-medium) {
        font-size: 1.2rem;
      }
      &:hover {
          background-color: var( --color-purple);
      }
    }
    .color_dark_red {
      @include icon-box;
      &:hover {
        background-color: var(--color-red-dark);
      }
    }
    
  }

  .assign_location {
     position: relative;
     margin-right: auto;
     .location {
        font-size: 1.5rem !important;
        line-height: 3rem;
        @media all and (max-width: $bp-medium) {
          font-size: 1.5rem !important;
          line-height: 3rem;
        }
       font-weight: 500;
       max-width: 20rem;
       color: var(--color-primary);
       z-index: 2;

        .loc {
          position: absolute;
          border-right: 2px solid var(--color-primary);
          padding: 0rem 1rem;
          // left: 1rem;
          top: 1rem;
          left: 2rem;
          @media all and (max-width: $bp-medium ) {
            // top: 3.7rem
          }
        }
       input {
          margin-right: 0px;
          height: 4rem;
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 2rem;
          padding-left: 10rem;
          padding-top: 0px;
          padding-bottom: 0px;
          display: flex;
          justify-content: center;
          padding-right: 0rem;
          font-size: 1.8rem !important;
          line-height: 3rem;
          max-width: 19rem;
          @media all and (max-width: $bp-medium) {
            font-size: 1.5rem !important;
            line-height: 3rem;
          }
          font-weight: 700;
          align-items: center;
       }
     }
     select {
       width: 8rem;
       height: 6rem;
       padding: 0.5rem;
     }
  }

  .barcode {
     display: 100;
  }

  .drag-enterd {
    padding: 0.5rem;
    @include box-bg-dash;
   
  }

  .dragging {
    @include box-bg-dash;
  }

  .location_box {
    position: relative;
    justify-content: space-between;
    .print {
      position: absolute;
      padding: 1rem;
      right: 10px;
      top: -0.5rem;
      font-size: 2rem;
      button {
        font-size: 4rem;
        padding: 5px;
      }
    }
  }
   
</style>
