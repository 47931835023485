import api from '@/api';
import router from '@/router';
import * as XLSX from 'xlsx';
import moment from 'moment';

/* eslint-disable no-debugger */

const state = {
    current_subscr: null,
    subscriptions: [],
    plans: null,
    totals: {
        total: 0,
        subTotal: 0,
        discount: 0
    },
    curr_invoice: [],
    storage_data: [],
    storage_price_total: null,
    storage_price: null

};

const getters = {
    current_subscr: state => state.current_subscr,
    subscriptions: state => state.subscriptions,
    plans: state => state.plans,
    totals: state => state.totals,
    curr_invoice: state => state.curr_invoice,
    storage_data: state => state.storage_data,
    storage_price_total: state => state.storage_price_total,
    storage_price: state => state.storage_price
};

const actions = {
    async fetchCurrSubscr({commit}) {
        const response = await api.get("/current_subscr");
        response !== undefined ? commit('setCurrSubscr', response.data) : '';  
    },
    async fetchSubscriptions({commit}) {
        const response = await api.get("/subscriptions");
        response !== undefined ? commit('setSubscriptions', response.data) : '';  
    },
    async fetchPlans({commit}) {
        const plans = await api.get("/plans");
        plans.data !== undefined ? commit('setPlans', plans.data) : '';  
    },
    calcTotal({commit, state}, id) {
        const plan = state.plans.filter(x => { return x.id == id})[0];
        plan.total = plan.price * parseFloat(event.currentTarget.value),
        plan.count = parseFloat(event.currentTarget.value)
        const newPlans = [
            ...state.plans.filter(x => { return x.id !== id }),
            plan,
        ]
        state.totals.subTotal = Math.round(newPlans.map((z) => { return z.total }).reduce((a, b) => a + b, 0));
        state.totals.discount = Math.round(state.totals.subTotal - (state.totals.subTotal * 0 ));
        state.totals.total = Math.round(state.totals.subTotal - state.totals.discount);
        
        newPlans.sort((a,b) => {return a.id - b.id});
        commit('setPlans', newPlans);
    },
    async setCurrSubscr({commit}, id) {
        const response = await api.post('/subscriptions/set_curr', {customer_id: id})
        response !== undefined ? commit('setCurrSubscr', response.data) : '';  
    },
    async createSubscr({commit, state}) {
        const model = {
            subscription: state.plans.map(x => { return { id: x.id, count: x.count }})
        };
        const response = await api.post("/subscriptions", model);
        commit('setCurrSubscr', response.data.customer);
        router.push('terms');
    },
    async updateSubscr({commit, state}) {
        const model = {
            subscription: state.plans.map(x => { return { id: x.id, count: x.count }})
        };
        const response = await api.put("/subscriptions", model);
        commit('setCurrSubscr', response.data.customer);
    },
    async fetch_invoice({commit}, model) {
        const start_date = new Date(moment(model.startDate).startOf("day"));
        const end_date = new Date(moment(model.endDate).endOf("day"));

        const response = await api.get(`/invoice/get/${start_date}/${end_date}`)
        response !== undefined ? commit('set_curr_invoice', response.data) : '';  
    },
    async export_invoice_data({state}) {

        state.curr_invoice[0].orders ? state.curr_invoice[0].orders : state.curr_invoice[0].orders = [] ;
       
        const data = state.curr_invoice[0].orders.map(x => {

            return {
                orderId: x.orderId,
                api_name: x.api_name,
                total_used_shipment_method: x.total_used_shipment_method,
                total_used_packaging: x.total_used_packaging, 
                total_used_surcharge: x.total_used_surcharge,
                total_fulfilment: x.total_fulfilment,
                total_order: x.total_order,
                used_packaging: x.used_packaging ?  x.used_packaging.map(x => {return x.name }).join(' | ') : "",
                used_shipment_method: x.used_shipment_method ?  x.used_shipment_method.map(x => {return x.name }).join(' | ') : "",
                used_surcharge: x.used_surcharge ? x.used_surcharge.map(x => {return x.name }).join(' | ') : "",
                eans: x.eans.join("|"),
                placed: x.placed,
                fulfilled: x.fulfilled,
                track_trace: x.track_trace
            }
        });

        // Prepare the additional data (assuming it's in a similar format)
        const storage_data = state.curr_invoice[0].storage.storage.map(x => {
            return {
                date: x.date,
                inbounds: x.inbounds,
                outbounds: x.outbounds,
                total_locations: x.total_locations,
                transfers: x.transfers,
                price: x.price,
            }
        });

        // Prepare the product location data
        const prodloc_data = state.curr_invoice[0].storage.storage.flatMap(x => {
            return x.location_products.map(lp => {
                return {
                    date: x.date,
                    product_ean: lp.product.ean,
                    daily_rate: lp.daily_rate,
                    location_name: lp.location.name,
                    location_price: lp.location.price,
                    location_type: lp.location.type,
                    product_name: lp.product.name,
                }
            });
        });

       // Summarize the data to get the total daily rate for each product
        const summarized_data = prodloc_data.reduce((acc, item) => {
            const existing = acc.find(i => i.ean === item.product_ean);
            debugger;
            if (existing) {
                debugger;
                existing.total_rate =  item.daily_rate + existing.total_rate;
            } else {
                acc.push({ 
                    ean: item.product_ean,
                    name: item.product_name,
                    total_rate: item.daily_rate
                });
            }
            return acc;
        }, []);



        // const mutation_data = state.curr_invoice[0].mutation_data.map(x => {

        //     data = x.inbounds.map(x => {
        //         return {
        //             type: 'inbound',
        //             location: x.location,
        //             product: x.product,
        //             quantity: x.quantity,
        //             price: x.price
        //         }
        //     })
        //     return {
                
        //     }
        // });
       
        const storageWs = XLSX.utils.json_to_sheet(storage_data); // Convert additional data to sheet
        const prodlocWs = XLSX.utils.json_to_sheet(prodloc_data); // Convert product location data to sheet
        const prod_stor_sumWS = XLSX.utils.json_to_sheet(summarized_data);

        // const mutationWs = XLSX.utils.json_to_sheet(mutation_data); // Convert additional data to sheet
        
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, ws, 'Orders')
        XLSX.utils.book_append_sheet(wb, storageWs, 'Storage'); 
        XLSX.utils.book_append_sheet(wb, prodlocWs, 'Product Locations');
        XLSX.utils.book_append_sheet(wb, prod_stor_sumWS, 'Product Storage Summary');    
        // XLSX.utils.book_append_sheet(wb, mutationWs, 'Mutations'); 

        XLSX.writeFile(wb, state.curr_invoice[0].org[0].name+'.export.xlsx')
    },
    async get_storage_data({commit}) {
        const response = await api.get(`/invoice/storage`)
        response !== undefined ? commit('set_storage_data', response.data) : '';  
    },
    async get_storage_price({commit}) {
        const response = await api.get(`/invoice/storage/price/false`)
        response !== undefined ? commit('set_storage_price', response.data) : '';  
    },
    async get_storage_price_total({commit}) {
        const response = await api.get(`/invoice/storage/price/true`)
        response !== undefined ? commit('set_storage_price_total', response.data) : '';  
    }
    // async verify({commit}, model) {
    //     const response = await api.post("/orgs/verify", {
    //         email: model.email,
    //         password: model.password
    //     });
    //     commit('setOrgs', response.data);
    // },
    
    // async delete({commit}) {
    //     const response = await api.get("/orgs");
    //     window.location.href = "/";
    //     commit('removeUser', response.data)
    // },
};

const mutations = {
    setCurrSubscr: (state, subscr) => (state.current_subscr = subscr),
    setSubscriptions: (state, subscriptions) => (state.subscriptions = subscriptions),
    setPlans: (state, plans) => (state.plans = plans),
    set_curr_invoice: (state, invoice) => (state.curr_invoice = invoice ),
    set_storage_data: (state, storage_data) => (state.storage_data = storage_data ),
    set_storage_price: (state, data) => (state.storage_price = data),
    set_storage_price_total: (state, data) => (state.storage_price_total = data[0] ? data[0].total : 0)
};

export default {
  state,
  getters,
  actions,
  mutations
}
