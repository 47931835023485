import api from '@/api';
import router from '../../router';

/* eslint-disable no-debugger */

const state = {
    orders: [],
    order: [],
    shipments: [],
    p_size: 1,
    shipment_options: [],
    order_report: [],
    shipping_options: [],
    orders_date_range: null,
    order_products: [],
    shipment_details: {},
    possible_addresses: [],
    route_plan: [],
    current_delivery: null,
    set_current_location: null,
    order_report_all: [],
    order_report_notes: [],
    order_report_open: [],
    order_report_fulfilled: [],
    limit: 5,
    shipments_dr: null,
    picked_location: null,
    attachment_files: [],
    created_order_id: null,
    order_image: {},
    selected_shipment_method: null,
    selected_packaging_option: null,
    selected_product: null,
    red_orders: false,
    fulfilled_orders: []
};

const getters = {
    orders: state => state.orders,
    order: state => state.order,
    shipments: state => state.shipments,
    order_report: state => state.order_report,
    order_report_all: state => state.order_report_all,
    shipping_options: state => state.shipping_options, 
    order_products: state => state.order_products,
    shipment_details: state => state.shipment_details,
    possible_addresses: state => state.possible_addresses,
    route_plan: state => state.route_plan,
    current_delivery: state => state.current_delivery,
    current_location: state => state.current_location,
    order_report_notes: state => state.order_report_notes,
    order_report_open: state => state.order_report_open,
    order_report_fulfilled: state => state.order_report_fulfilled,
    p_size: state => state.p_size, 
    limit: state => state.limit,
    shipments_dr : state => state.get_shipments_dr,
    picked_location: state => state.picked_location,
    attachment_files: state => state.attachment_files,
    created_order_id: state => state.created_order_id,
    order_image: state => state.order_image,
    selected_shipment_method: state => state.selected_shipment_method,
    selected_packaging_option: state => state.selected_packaging_option,
    selected_product: state => state.selected_product,
    red_orders: state => state.red_orders,
    fulfilled_orders: state => state.fulfilled_orders
};

const actions = {
    async getOrders({commit, dispatch, state}, model) {
       
        if (model) {
            state.orders_date_range = model;
        }
        const response = await api.get("/orders/open/"+
            model.startDate+"/"+ 
            model.endDate+"/"+
            state.p_size + 
            '?location='+state.current_location +
            '&current_delivery='+state.current_delivery + 
            '&limit='+state.limit + 
            '&picked_location='+state.picked_location  +
            '&red_orders='+state.red_orders +
            '&ean='+state.selected_product
        );
        await dispatch('getShippingOptions', response.data );

        response !== undefined ? commit('setOrders', response.data) : '';
    },
    async toggleDoubleOrders({dispatch, state}) {
        if (state.p_size === 1) {
            state.p_size = 2; 
            dispatch('getOrders', state.orders_date_range)
        } else {
            state.p_size = 1;
            dispatch('getOrders', state.orders_date_range)
        }
    },
    async getShippingOptions({commit}, model) {
       const options = await model.map(m => {
            const opt = m.orders.map((x) => {
                const def_shipping = x.products[0].product_id.def_shipping;
                if (x.ship_options && x.ship_options.length > 0) {
                    const def_option = x.ship_options.filter(f => {
                        return f.transporterCode == def_shipping
                    });    
                
                    if (def_option.length >0  ) {
                        return {
                            _id: x._id,
                            options: def_option
                        }
                    } else {
                        return {
                        _id: x._id,
                        options: x.ship_options.sort((a, b) => parseFloat(a.labelPrice.totalPrice) - parseFloat(b.labelPrice.totalPrice))
                        }
                    }
                    
                } else {
                    return {
                        _id: x._id,
                        options: []  
                    }
                }
            });
            return opt
        }); 
        commit('setShipmentOptions', options);
    },
    async getOrderReport({commit},model) {
        const response = await api.post("/orders/report", model);
        response !== undefined ? commit('setOrderReport', response.data) : '';  
    },
    async get_order_report_notes({commit}) {
        const response = await api.get("/orders/report/notes");
        response !== undefined ? commit('set_order_report_notes', response.data) : '';  
    },
    async get_order_report_fulfilled({commit}) {
        const response = await api.get("/orders/report/fulfilled?date_time="+new Date() );
        response !== undefined ? commit('set_order_report_fulfilled', response.data) : '';  
    },
    async getShipments({commit, state}, model) {
        if (model) {
            state.shipments_dr = model;
        }
        const response = await api.get("/orders/"+model.startDate+"/"+ model.endDate);
        response !== undefined ? commit('setShipments', response.data) : '';  
    },
    async get_order({commit}, model) {
        const response = await api.get("/order/get/"+model);
        response !== undefined ? commit('set_order', response.data) : '';  
    },
    async search__fulfild__order({commit, state}, model) {
        let params = {};

        if (model.orderId)
            params.orderId = model.orderId

        if (model.postCode)
            params.postCode = model.postCode
        
        if (model.name)
            params.name = model.name


        const final_params = new URLSearchParams(params).toString();
        const dr = state.shipments_dr;
        
        const response = await api.get("/orders/"+dr.startDate+"/"+ dr.endDate +"?" +final_params );
        response !== undefined ? commit('setShipments', response.data) : '';  
    },
    async downloadOrderList({dispatch}, model) {
        try {
            const response = await api.post("/orders/bol/shipmentlist", model, { responseType: 'blob'}) 
            const fileURL = window.URL.createObjectURL(response.data);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;

            window.open(fileURL);
            dispatch('dummyLog')
            
            // console.log(response, commit);
        } catch (err) {
            // console.log(err);
        }
    },
    async get_order_report_all({commit, state}) {
        const d = state.order_report.all.map((x,i) => {
            if (i != 0)
                return [ new Date(x[0]), x[1], x[2]]
            else 
                return [ x[0], x[1], x[2]] 
        })

        commit('set_order_report_all', d)
    },
    async get_order_report_open({commit}) {
        const response = await api.get("/orders/report/open?date_time="+new Date());
        response !== undefined ? commit('set_order_report_open', response.data) : '';  
    },
    async restore_order({dispatch}, model) {
        await api.post('order/restore', {_id: model});
        dispatch('getShipments');
    },
    async createOrder({state, dispatch}, model) {

        model.products = model.products.map(x => {
            return {
                title: x.title,
                ean: x.ean,
                product_id: x._id,
                quantity: x.quantity
            }
        })
        try {
            const res = await api.post('orders', model);
            const data = res.data;
            
            if (res.status === 201) {
                // Order created, add the Documents if those are needed
                state.created_order_id = data.order_id;
                if (model.attachments && model.attachments.available) {
                    await dispatch('uploadDocuments', data.order_id);
                }
            }
        } catch (err) {
            console.log(err);
        }
    },
    async searchAddress({commit}, model) {
       const res = await api.post('checkaddress/', model);
       commit('setPossibleAddresses',res.data)
    },
    async setAddress({commit}, model) {
        commit('setAddress', model )
     },
    async addOrderProduct({ commit }, model ) {
        let order_products_copy = state.order_products;
        if (state.order_products.length > 0) {
            // Check if product already exists in order
            // if so add quantity to existing product and return

            for (let product of order_products_copy) {
                if (product._id === model._id) {
                    product.quantity += model.quantity;
                    commit('setOrderProducts', order_products_copy);
                    return;
                }
            }
        }
        
        order_products_copy.push(model);
        
        commit('setOrderProducts', order_products_copy);
    },
    async removeOrderProduct({ commit }, model ) {
        let order_products_copy = state.order_products;
        order_products_copy = order_products_copy.filter(x => x._id !== model._id);
        
        commit('setOrderProducts', order_products_copy);
    },
    async resetOrderProducts({ commit }) {
        commit('setOrderProducts', []);
    },
    async resetShipmentDetails({ commit }) {
        commit('setShipmentDetails', {});
    },
    async modify_limit({commit, dispatch}, model ) {
        commit('set_limit', model);
        dispatch('getOrders', state.orders_date_range)
    },
    async modify_red_orders({commit, dispatch} ) {
        if (state.red_orders === true) {
            commit('set_red_orders', false);
        } else {   
            commit('set_red_orders', true);
        }
       
        debugger;
        dispatch('getOrders', state.orders_date_range)
    },
    // async fulfillOrder({dispatch, state}, model) {
    //     try {
    //         await api.post("/orders/fulfil", model);
    //         dispatch('getOrders', state.orders_date_range);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // },   
    async ship({dispatch, state}, model) {
        try {
            const response = await api.post("/orders/ship", model, { responseType: 'blob'});
            
            const blob = new Blob([response.data], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            
            const iframe = model.iframe;
            
            iframe.src = url;
            
            const printIframe = () => {
                return new Promise((resolve) => {
                    iframe.onload = () => {
                        iframe.contentWindow.print();
                        setTimeout(resolve, 1000); // Adding a delay to ensure print dialog is handled
                    };
                });
            };

            await printIframe()
            
            await dispatch('set_order_to_shipped', model.order._id);
            
            await dispatch('getOrders', state.orders_date_range);
            console.log(state.orders_date_range)
            
        } catch (err) {
            // console.log(err);
        }
    },
    async shipPostNL({dispatch},  model) {
        model.order.Product_join = null;
        model.order.Product_images = null;
        model.order.products = null;
        try {
            const response = await api.post("/orders/ship/postnl", model, { responseType: 'blob'});
             
            const blob = new Blob([response.data], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            
            const iframe = model.iframe;
            console.log(iframe);
            iframe.src = url;
            
            const printIframe = () => {
                return new Promise((resolve) => {
                    iframe.onload = () => {
                        iframe.contentWindow.print();
                        setTimeout(resolve, 1000); // Adding a delay to ensure print dialog is handled
                    };
                });
            };

            await printIframe()

            // await dispatch('fulfillOrder', model.order_id);
            // Ship order
            await dispatch('set_order_to_shipped', model.order._id);
            await dispatch('getOrders', state.orders_date_range);

        } catch (err) {
            console.log(err);
        }
    },
    async shipSMF({dispatch},  model) {
        try {
            const response = await api.post("/orders/ship/smf", model, { responseType: 'blob'});
            
            const blob = new Blob([response.data], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            
            const iframe = model.iframe;
            
            iframe.src = url;
            
            const printIframe = () => {
                return new Promise((resolve) => {
                    iframe.onload = () => {
                        iframe.contentWindow.print();
                        setTimeout(resolve, 1000); // Adding a delay to ensure print dialog is handled
                    };
                });
            };

            await printIframe()
            
            // await dispatch('fulfillOrder', model.order_id);
            await dispatch('set_order_to_shipped', model.order._id);
            await dispatch('getOrders', state.orders_date_range);

        } catch (err) {
            console.log(err);
        }
    },
    async shipDHL({dispatch } , model) {
        try {

            const response = await api.post("/dhl/ship", model, { responseType: 'blob'});

            const blob = new Blob([response.data], { type: 'application/pdf' });

            const url = URL.createObjectURL(blob);
            
            const iframe = model.iframe;
            iframe.src = url;
            
            const printIframe = () => {
                return new Promise((resolve) => {
                    iframe.onload = () => {
                        iframe.contentWindow.print();
                        setTimeout(resolve, 1000); // Adding a delay to ensure print dialog is handled
                    };
                });
            };

            await printIframe()
            
            //await dispatch('fulfillOrder', model.order_id);
            await dispatch('set_order_to_shipped', model.order._id);
            await dispatch('getOrders', state.orders_date_range);
            
        } catch (err) {
            //console.log(err);
        }
    }, 
    async setNotes ({dispatch }, model ) {
        model.Product_join = null;
        model.Product_images = null;

        await api.put('order/notes', { _id : model._id, notes_text: model.notes_text, notes_color: model.notes_color, notes_notify: model.notes_notify });
        if (router.currentRoute ==! 'pick')
            await dispatch('getOrders', state.orders_date_range);
    },
    async getRoutePlan({commit}) {
        const resp = await api.get('orders/drive');
        commit('setRoutePlan', resp.data);
    },
    async filterDelivery({commit, state, dispatch}, model) {
        state.current_delivery === model ? model = null : null;
        commit('setCurrentDelivery', model);
        await dispatch('getOrders', state.orders_date_range);
    },
    async filter_locations({commit, state, dispatch}, model) {
        state.set_current_location === model ? model = null : '';
        commit('set_current_location', model);
        await dispatch('getOrders', state.orders_date_range);
    },
    async filter_picked_location({commit, state, dispatch}, model) {
        state.set_picked_location === model ? model =null : '';
        commit('set_picked_location', model);
        debugger;
        await dispatch('getOrders', state.orders_date_range)
    },
    async filter_product({commit, state, dispatch}, model) {
        try {
            await commit('set_selected_product', model);
            await dispatch('getOrders', state.orders_date_range);
        } catch (err) {
            console.log(err);
        }
    },
    /**
     * 
     * @param {Object} model - The order to print
     */
    async product_ship({dispatch}, model) {
        // Get the first order with a selected shipment method
        console.log(model);
        // This order we need to fulfill
        await dispatch('getOrders', state.orders_date_range);
    },
    async uploadDocuments({dispatch}, model) {
        // model: order_id
        console.log("Uploading documents")

        const formData = new FormData();
        console.log(state.attachment_files)

        state.attachment_files.forEach((file) => {
            formData.append(`files[]`, file);
        });
        console.log(formData)

        const order_id = model;
        console.log(order_id)

        const data = await api.post('/order_attachments/'+order_id, formData);
        console.log(data)
        await dispatch('getOrders', state.orders_date_range);
    },
    async get_attachment_files({commit}, model) {
        // model: order_id
        const response = await api.get('/order_attachments/'+model);
        commit('set_attachment_files', response.data);
    },
    async get_order_image({commit}, model) {
        // model: order_id
        try {
            const response = await api.get('/product/get_image/'+model);
            if (response.status === 200) {
                commit('set_order_image', response.data);
            } else {
                commit('set_order_image', {});
            }
        } catch (err) {
            console.log(err)
            commit('set_order_image', {});
        }
    },
    async update_selected_shipment_method({commit, dispatch}, model) {
        console.log(model)
        try {
            console.log(model)
            const response = await api.post('/order/shipment_method/' + model.orderId, {code: model.code});
            console.log(response)
        } catch  (err) {
            console.log(err)
            commit('set_selected_shipment_method', null)
        }

        commit('set_selected_shipment_method', model);
        dispatch('getOrders', state.orders_date_range)
    },
    async update_selected_packaging_option({commit, dispatch}, model) {
        try {
            console.log(model)
            const response = await api.post('/order/shipment_package/' + model.orderId, {id: model.id});
            console.log(response)
        } catch  (err) {
            console.log(err)
            commit('set_selected_packaging_option', null)
        }

        commit('set_selected_packaging_option', model);
        dispatch('getOrders', state.orders_date_range)
    },
    async set_order_to_shipped({dispatch}, id) {
        try {
            await api.post(`/order/shipped/${id}`);
            dispatch('dummyLog');
        } catch (err) {
            console.log(err)
            await api.post(`/order/shipped/${id}`);
        }
    },
    async weekEmail({dispatch}) {
        try {
            await api.get('/orders/weekly_report');
            dispatch('dummyLog');
        } catch (err) {
            console.log(err)
        }
    },
    async get_fulfilled_orders({commit}) {
        try {
            const response = await api.get('/user/orders/fulfilled');
            if (response !== undefined && response.status === 200) {
                commit('set_fulfilled_orders', response.data);
            }
        } catch (err) {
            console.log(err);
        }
    },
    async add_selected_product({commit}, model) {
        commit( 'set_selected_product', model )
    } 
};

const mutations = {
    setOrders: (state, orders) => (state.orders = orders),
    set_order:(state, order) => (state.order = order),
    setShipments: (state, shipments) => (state.shipments = shipments),
    setShipmentDetails: (state, details) => (state.shipment_details = details),
    setShipmentOptions: (state, options) => (state.shipping_options = options),
    setOrderReport: (state, report)  => (state.order_report = report),
    setOrderProducts: (state, order_products)  => (state.order_products = order_products),
    setPossibleAddresses: (state, details) => { state.possible_addresses = details},
    setAddress: (state, ad) => {
        state.possible_addresses = [],
        state.shipment_details.streetname = ad.street,
        state.shipment_details.housenumber = ad.housenumber,
        state.shipment_details.addition = ad.addition,
        state.shipment_details.postalcode = ad.postcode
        state.shipment_details.city = ad.city
        state.shipment_details.country = ad.country
    },
    setCurrentDelivery: (state, current_delivery) => { state.current_delivery = current_delivery},
    set_current_location: (state, current_location) => {state.current_location = current_location},
    setRoutePlan: (state, route_plan) => { state.route_plan = route_plan},
    set_order_report_all: (state, report) => { state.order_report_all = report},
    set_order_report_notes: (state, order_report_notes) => {state.order_report_notes = order_report_notes },
    set_order_report_open: (state, order_report_open ) => {state.order_report_open = order_report_open},
    set_order_report_fulfilled: (state, order_report_fulfilled ) => {state.order_report_fulfilled = order_report_fulfilled},
    set_limit: (state, limit) => {state.limit = limit},
    set_red_orders: (state, red_orders) => {
        state.red_orders = red_orders
    },
    set_picked_location: (state, picked_location) => { state.picked_location = picked_location},
    set_attachment_files: (state, attachment) => { state.attachment_files = attachment},
    set_order_image: (state, order_image) => { state.order_image = order_image},
    set_selected_shipment_method: (state, selected_shipment_method) => { state.selected_shipment_method = selected_shipment_method},
    set_selected_packaging_option: (state, selected_packaging_option) => { state.selected_packaging_option = selected_packaging_option},
    set_selected_product: (state, selected_product) => { state.selected_product = selected_product},
    set_fulfilled_orders: (state, fulfilled_orders) => { state.fulfilled_orders = fulfilled_orders}
};

export default {
  state,
  getters,
  actions,
  mutations
}
