<template>
  <div class="ck_inv__container">
    <div class="ck_inv__container__row flex">
      <div class="ck_inv__container__row__id">
        {{ node._id }}
      </div>
      <div  :class="'ck_inv__container__row__status ' + (node.status === 'pending' ? 'open' : 'closed')  "> 
        {{ node.status === 'pending' ? 'open' : 'closed' }}
      </div>
      <div class="flex-5">
        <button v-on:click="expanded = !expanded">{{expanded ? '▲' : '▶'}}</button>
        <Popup :closed="add_popup_closed" @handleClose="add_popup_closed = true" :title="'Product toevoegen'">
          <AddProduct :inbound_id="node._id" />
        </Popup>
      </div>

      <div class="flex-15">
        <div class="">{{ trx["Track & Trace"] }}</div>
        <a target="blank" :href="node.track_trace">{{node.track_trace}}</a>
      </div>
      
      <div class="flex-10">
        <div class="text-center">
          {{trx.inventory.Boxes}}  {{trx.inventory["delivered"]}}
         <p><b>{{ pieces_checked_in }} / {{ expected_pieces }}</b></p> 
        </div>
        
      </div>

      <div class="flex-20">
        <div class="">{{ trx["Date"] }}</div>
        <p>
          {{
            node.created_at
              ? moment(node.created_at).format("DD-MM-YYYY")
              : ""
          }} ==> {{ node.expected_delivery ? moment(node.expected_delivery).format("DD-MM-YYYY")
              : "" }}
        </p>
      </div>
      
      
     
      <div class="flex-25">
        {{ trx["Contact"] }} {{ trx["Email"] }} /  {{ trx["Phone"] }}
        <p>{{ node.contact_person_email }} / {{ node.phone }}</p>
      </div>
      
      
    </div>

		<div v-if="expanded">
			<InventoryProducts
      v-for="item in node.items"
      v-bind:key="item._id"
      :node="item"
      :id="node._id"
			/>
      <div class="control-btns">
        <button v-if="node.status === 'pending'" @click="$router.push(`/overview_checkin/${node._id}`)" class="button">Edit details</button>
        <button v-if="node.status === 'pending'" class="btn_add" @click="add_popup_closed = false">+ Product</button>
      </div>
      
		</div>
  </div>
</template>
<script>
import trx from "@/translations";
import InventoryProducts from "./InventoryProducts.vue";
import moment from "moment";
import Popup from "@/components/Layout/popup.vue";
import AddProduct from "./add_product.vue";

export default {
  name: "InboundProduct",
  props: {
    node: Object,
  },
  computed: {
    expected_pieces() {
      let pieces_expected = 0;
      this.node.items.map( x => { pieces_expected = pieces_expected + (x.inv_prod_id[0].pieces_expected / x.inv_prod_id[0].packed_per); });
      return pieces_expected
    }, 
    pieces_checked_in() {
      let pieces_checked_in = 0;
      this.node.items.map( x => {
          pieces_checked_in = pieces_checked_in + (x.inv_prod_id[0].pieces_checked_in / x.inv_prod_id[0].packed_per);
      });
      return pieces_checked_in
    }
  },
  data() {
    return {
      trx,
			moment,
			expanded: false,
      checked_in: false,
      add_popup_closed: true,
    };
  },
	components: {
		InventoryProducts,
    AddProduct,
    Popup
	},
  created() {
    this.checked_in = this.node.status === "checked_in";
  }
};
</script>
<style lang="scss" scoped>
.ck_inv__container  {
     &__row {
       margin:1rem 0rem;
       
       flex-wrap: wrap;
       height: auto;
       justify-content: flex-start;
       color: var(--color-grey-dark-1);
       align-items: center;
       @include box-bg-white;
       padding: 1rem;
       border-color:  var(--color-grey-dark-1);
       position: relative;
       
       &__status {
            top:-2px;
            right: 16rem;
            position: absolute;
            padding: 0.5rem 2rem;
            border-top-right-radius: 3px;
            border-bottom-left-radius: 10px;
            &.open {
              color: var(--color-white);
              background: var(--color-yellow);
              
            }
            &.closed {
              color: var(--color-white);
              background: var(--color-grey-dark-1);
            }
       }
       &__id {
            top:-2px;
            right: -2px;
            position: absolute;
            padding: 0.5rem 2rem;
            background: var(--color-grey-dark-2);
            color: var(--color-white);
            border-top-right-radius: 3px;
            border-bottom-left-radius: 10px;
       }
     }
   }

   .control-btns {
     margin-left: 5rem;
     margin-top: 2rem;
     margin-bottom:2rem;
     >button {
      margin-right: 1rem;
     }
   }
</style>