export default {
  "date_format": "DD-MM-YY",
  "date_time_format": "DD-MM-YYYY HH:MM",
  "time_format": "HH:MM",
  "delete": "删除",
  "continue": "继续",
  "update": "更新",
  "Show preview": "显示预览",
  "Data preview": "数据预览",
  "Select a file to start import": "选择文件开始导入",
  "Total": "总计",
  "Count": "计数",
  "Sub total": "小计",
  "Discount": "折扣",
  "Add": "添加",
  "Import": "导入",
  "Date": "日期",
  "Track & Trace": "追踪与追溯",
  "Phone": "电话",
  "Click here your select an Excel file": "点击这里选择一个Excel文件",
  "Something went wrong, please conduct your logs": "出现错误，请检查您的日志",
  "Sender": "发送者",
  "Email": "电子邮件",
  "Location": "位置",
  "Comment": "评论",
  "Amount": "金额",
  "search": "搜索",
  "expand": "展开",
  "condense": "压缩",
  "save": "保存",
  "read": "阅读",
  "pick_note": "拣选单据",
  limit: "限制",
  refresh: "刷新",
  import: "导入",
  "common": {
    "name": "名称",
    "search_results": "搜索结果",
    "select_date_time": "选择日期和时间"
  },
  "admin": {
    "name": "管理员设置",
    "search_orderid": {
      "name": "搜索订单ID"
    },
    "ship_boxes": {
      "name": "发货箱子和尺寸",
      "max_size": "最大尺寸（长,宽,高）",
      "package": "包裹",
      "product_size": "产品尺寸",
      "package_cost": "包装成本",
      "purchase_cost": "采购成本",
      "package_code": "物品编号",
      "fulfillment_cost": "履行成本",
      "location": "位置",
      "create": "创建新的包装类型"
    },
    "labels": {
      "name": "扫描标签"
    },
    "locations": {
      "name": "位置设置"
    },
    "shipment_method": {
      "name": "运输选项",
      "code": "代码",
      "price": "价格",
      "purchase_price": "购买价格",
      "cut_off": "截止时间",
      "late_drop_surcharge": "晚间附加费",
      "peak_surcharge": "高峰附加费"
    },
    "surcharge": {
      "name": "附加费",
      "code": "代码",
      "price": "价格",
      "note": "备注",
      "type": "类型"
    },
    "users": {
      "name": "用户和组",
      "password_hint": "最少9个字符"
    },
    "transfers": {
      "name": "所有打开的转账"
    }
  },
  "settings": {
    "apis": "集成",
    "Settings": "设置",
    "Your organization": "您的组织",
    "Subscription": "订阅",
    "Users": "用户",
    "invoice": {
      "total_used_packaging": "使用的包装",
      "total_used_shipment_method": "使用的邮资",
      "total_used_surcharge": "使用的附加费",
      "total_fulfillment": "履行总计",
      "total": "总计",
      "download_details": "下载详情",
      "rack-box": "货架位置",
      "half-box": "半货架位置",
      "warehousing": "仓库位置",
      "pallet": "托盘位置",
      "1/2pallet": "半托盘位置",
      "1/3pallet": "三分之一托盘位置"
    }
  },
  "organizations": {
    "name": "商店",
    "organization": "商店",
    "Organization address": "商店地址",
    "description": "您所有商店的概览",
    "Organization name": "商店名称",
    "Your organization details": "您的商店详情",
    "type": "商店类型",
    "about": "关于",
    "coc_number": "商会号码",
    "vat_number": "增值税号",
    "surchrages": "附加费",
    "Contact Person": "联系人",
    "Contact Person Email": "联系人电子邮件",
    "delete_org_help": "危险区域，删除您的商店",
    "Phone": "电话",
    "Branche": "行业"
  },
  "users": {
    "Name": "姓名",
    "Email": "电子邮件",
    "Login": "登录",
    "First name": "名字",
    "Last name": "姓氏",
    "Password": "密码",
    "Phone": "电话号码",
    "Licence": "许可证",
    "Verified": "已验证",
    "Signup": "注册",
    "Create a new user": "创建新用户",
    "Users in your organization": "组织中的用户",
    "Import users": "导入用户",
    "Stakeholder Group": "利益相关者群体",
    "Already have an account, login": "已有账户？登录",
    "Signup to get started": "注册以开始",
    "Not a user? Signup!": "不是用户？现在注册！",
    "reset_password": "忘记密码？",
    "reset": "重置密码",
    "Please enter your email": "请输入您的电子邮件",
    "Know your password?, Login": "知道密码？登录！",
    "adress_hint": "输入您的地址，使用逗号分隔邮编和地点"
  },
  "plans": {
    "name": "计划"
  },
  "subscriptions": {
    "name": "价格",
    "Standard": "标准",
    "Discount for yearly payment": "年付折扣",
    "Terms of Service": "服务条款",
    "Your current subscription": "您当前的订阅",
    "I have read and accept the terms of condition": "我已阅读并接受条款和条件",
    "CSR Managers": "CSR管理者",
    "free_plan_help": "",
    "Stakeholders": "利益相关者",
    "Select your plan": "选择您的计划",
    "Enterprise": "企业",
    "Start for free": "免费开始",
    "Price / order": "每单价格",
    "Invoices": "发票",
    "description": "显示所有可用订阅的概览",
    "new": "新订阅",
    "Pricing knowledge": "定价知识"
  },
  "shops": {
    "Create a new Shop": "创建新商店",
    "Shops": "商店"
  },
  "products": {
    "button_options_name": "产品选项",
    "button_link_name": "产品链接",
    "Product": "产品",
    "ProductName": "产品名称",
    "Add inventory": "报告产品",
    "Inventory checkin": "库存登记",
    "Sync with bol.com": "与bol.com同步",
    "Note": "备注",
    "Return instruction": "退货指南",
    "search_placeholder": "按名称或EAN搜索产品",
    "sf_stock": "智能库存",
    "total_stock": "总库存",
    "lvb_stock": "LVB库存",
    "warehouse_stock": "仓库库存",
    "import_your_products": "在这里导入您的产品",
    "create": "添加新产品",
    "note": "备注",
    "ean": "EAN",
    "search": "按名称或EAN搜索产品",
    "pick_note": "拣选单据",
    "pack_note": "包装单据",
    "customs": {
      "button_name": "海关",
      "settings": "海关设置",
      "customs": "海关（欧盟外发货）",
      "hs": "HS码",
      "value": "价值（欧元）",
      "weight_kg": "重量（公斤）"
    },
    "recount": {
      "button_name": "重新计数",
      "recounting": "重新计数",
      "settings": "重新计数设置"
    },
    "import": {
      "add_product": "⥊ 添加产品",
      "xlsx": "⥊ Excel",
      "barcode_sku_ean": "EAN / SKU / 条码",
      "Product_name": "产品",
      "fullfilled_bysf": "开/关",
      "length": "长度",
      "height": "高度",
      "width": "宽度",
      "weight_kg": "重量（公斤）"
    },
    "sync": {
      "bol": "⥊ Bol.com",
      "woo": "⥊ WooCommerce",
      "shopify": "⥊ Shopify",
      "mirakl": "⥊ Mirakl",
      "lightspeed": "⥊ Lightspeed",
      "shopware": "⥊ Shopware",
      "excel": "⥊",

      "help": "使用这些按钮导入您在不同平台发布的产品。",
      "import_products": "导入"
    }
  },
  "inventory": {
    "Pre-announced": "预先公告",
    delivered: "已交付",
    "Box": "盒子",
    "Boxes": "盒子",
    "Pallet": "托盘",
    "Pallets": "托盘"
  },
  "returns": {
    "name": "返回",
    "return_number_or_barcode": "退货号或条形码",
    "scan_help_text": "输入退货号或扫描条形码",
    "location": "位置",
    "product": "产品",
    "return_id": "退货号",
    "return_instruction": "退货指南",
    "damage": "损坏",
    "damaged_package": "包装损坏",
    "damaged_product": "产品损坏",
    "damaged_product_package": "产品包装损坏",
    "registered": "注册日期",
    "product_state": "产品状况",
    "reason": "客户/消费者退货原因",
    "customer_details": "消费者详情",
    "no_returns_to_show": "没有退货显示",
    "handel_return_close": "完成并关闭",
    "RETURN_RECEIVED": "收到退货",
    "EXCHANGE_PRODUCT": "交换产品",
    "RETURN_DOES_NOT_MEET_CONDITIONS": "退货不符合条件",
    "REPAIR_PRODUCT": "修复产品",
    "CUSTOMER_KEEPS_PRODUCT_PAID": "客户保留已支付产品",
    "STILL_APPROVED": "仍然批准",
    "product_excellent": "产品看起来整洁，可以重新销售",
    "product_good": "产品相当好，可以回到货架上",
    "product_bad_state": "产品/包装损坏，不能销售"
  },
  "orders": {
    "Order ID": "订单ID",
    "order": "订单",
    "Date": "日期",
    "Add order": "添加订单",
    "Your orders": "您的订单",
    "Orders": "订单",
    multi_orders: "多订单",
    warning_orders: "警告订单",
    "Status": "状态",
    "Create": "创建新订单",
    "Shipments": "发货",
    "Products": "产品",
    "Receiver": "接收者",
    "name": "姓名",
    "surename": "姓氏",
    "streetname": "街道名称",
    "housenumber": "门牌号",
    "addition": "附加",
    "postalcode": "邮政编码",
    "place": "地点",
    "city": "城市",
    "country": "国家",
    "company": "公司名称",
    "Sync with bol.com": "与bol.com同步",
    "Send products to us": "发送产品给我们",
    "EAN": "EAN",
    "Delivery time": "交货时间",
    "Own stock": "自有库存",
    "SF stock": "SF库存",
    "Stock": "库存",
    "Fulfil by SF": "由SF履行",
    "Fulfilled by BOL (FBB)": "由BOL履行 (FBB)",
    "FBB": "FBB",
    "SF": "SF",
    "Add inventory": "添加库存",
    "Send your products to us": "将您的产品发送给我们",
    "Default shipment": "默认发货方式",
    "Location": "位置",
    "Box size": "盒子大小",
    "email": "电子邮件",
    "phone": "电话",
    "Select products you want to send": "选择您想发送的产品",
    "Wrong product": "错误产品",
    "Wrong quantity": "错误数量",
    "Damaged product": "损坏的产品",
    "Webshop order": "网店订单",
    "order_comments": "订单备注",
    "import_your_orders": "在这里导入您的订单",
    "shipping_info": "运输信息",
    "confirmation": "确认",
    "product_selector_text": "选择您想发送的产品",
    "products_in_order": "订单中的产品",
    "to_sending": "到发货详情",
    "to_confirm": "到确认",
    "to_create": "确认并发送",
    "shipping_method": "运输方式",
    "attachment": "附件（仅限PDF）",
    "attachment_placeholder": "处理附件的说明",
    "send": "已创建订单",
    "import": {
      "order_id": "订单ID",
      "product_name": "产品",
      "quantity": "数量",
      "city": "城市",
      "countryCode": "国家代码",
      "email": "电子邮件",
      "firstName": "名字",
      "surName": "姓氏",
      "houseNumber": "门牌号",
      "houseNumberExtension": "门牌号扩展",
      "language": "语言",
      "salutation": "称呼",
      "streetName": "街道名称",
      "zipcode": "邮政编码",
      "deliveryPhoneNumber": "配送电话号码",
      "reason": "原因",
      "notes_text": "笔记",
      "notes_color": "笔记颜色",
      "company": "公司"
    },
    "manual_send": {
      "reason": "手动订单的原因",
      "received": "订单未由PostNL接收",
      "incorrect": "错误产品",
      "quantity": "错误数量",
      "damaged": "损坏的产品",
      "webshop": "网店订单"
    }
  },
  "pick_list": {
    "name": "拣选列表",
    "qty": "数量"
  },
  "shipments": {
    "sent_orders": "已发送订单",
    "24h": "24小时",
    "> 2 days": "> 2天",
    "on_the_way": "在途中",
    "not_sorted": "未分类",
    "ordered_by": "订购人",
    "order_date": "订购日期",
    "sent_date": "发送日期",
    "delivery_date": "送货日期",
    "shipment_method": "运输方式",
    "day_delivered": "< 24小时内送达",
    "two_day_delivered": "< 48小时内送达",
    "search_placeholder": "按姓名、订单或邮编搜索",
    "Shipment Address": "运送地址",
    "Amount": "数量",
    "Ready for shipment": "准备发货",
    "Packed": "已打包",
    "Shipped": "已发货",
    "Shipment options": "运输选项",
    "Open": "打开",
    "Pallets": "托盘",
    "Coli": "包裹/箱",
    "Type": "类型",
    "remark": "备注"
  },
  "checkinv": {
    "created_at": "已发货",
    "expected_delivery": "预计",
    "status": "状态",
    "checked_in_date": "已登记",
    "product_name": "产品",
    "product_ean": "Ean/SKU",
    "expected": "预计",
    "final_check_in": "已登记",
    "comment": "评论",
    "pallets": "托盘",
    "pallet": "托盘",
    "box": "盒子",
    "boxes": "盒子",
    "change_checkin": "查看/修改登记",
    "search": "按名称搜索登记",
    "edit_checkin": "查看/修改登记",
    "add_checkin": {
      "overview": "概览",
      "out_of_stock": "缺货",
      "application": "申请",
      "reason": "您想做什么？",
      "stock_checkin": "库存登记",
      "stock_checkin_info": "点击这里告知我们您计划的发货，以便我们可以最佳地准备接收和存储，并仔细管理您的货物。",
      "change_checkin": "查看/修改登记",
      "change_checkin_info": "查看或编辑您之前报告的发货，以保持详细信息的最新并确保无缝的物流体验。",
      "type": "类型",
      "products": "产品",
      data: "事实",
      "confirmation": "确认"
    },
    "type": {
      "package": "包裹发货",
      "package_text": "选择此选项以注册较小的、单独包装的物品。由PostNL、UPS、DpD或其他快递服务交付",
      "pallet": "托盘发货",
      "pallet_text": "选择此项以进行较大的托盘发货，用于组织运输。",
      "container_other": "集装箱/其他发货",
      "container_other_text": "用于登记集装箱或非标准尺寸的发货。"
    },
    "checkin_products": {
      "choose_products_in_box": "选择此箱中的产品",
      "no_products": "您的系统中还没有产品，请先执行此操作",
      "search_products": "按名称或EAN搜索产品"
    },
    "checkin_product": {
      "unit_amount": "单位数（盒子）*",
      "amount": "数量",
      "box_amount": "盒内数量*",
      "total_amount": "总件数（CE）",
      "expiration_date": "到期日期",
      "batch_number": "批号",
      "add": "添加",
      "divergent": "* 有一盒不同吗？完成这个步骤与匹配的盒子然后再次添加相同的产品，在前一步。",
      "check": "检查您的产品是否正确设置",
      "back": "返回",
      "totals": "总计",
      "per_box": "每盒产品",
      "boxes": "盒子",
      "submit_checkin": "确认登记"
    },
    "checkin_success": {
      "shipment_instructions": "发货指南：",
      "documents_sent": "文件已通过电子邮件发送。您也可以直接从这里下载",
      "checkin_step1": "下载装箱单并将其放入包装中",
      "checkin_step2": "然后下载QR码并将其贴在箱子外面",
      "checkin_important": "重要提示：此QR码将登记您的包裹。如果缺少，登记过程将会延迟。"
    },
    "checkin_information": {
      "type": "指明产品所在位置",
      "products": "逐一选择您想添加的产品",
      "information": "填写以下信息以完成登记"
    },
    "checkin_confirmation": {
      "contact_person_label": "联系人",
      "contact_person_placeholder": "联系人",
      "contact_person_email_label": "联系人电子邮件",
      "contact_person_email_placeholder": "联系人电子邮件",
      "phone_label": "电话号码",
      "phone_placeholder": "电话",
      "shipping_address_label": "发货地址",
      "shipping_address_placeholder": "发货地址",
      "track_trace_label": "追踪与追溯",
      "track_trace_placeholder": "追踪与追溯",
      "expected_delivery_label": "预期送达",
      "expected_delivery_placeholder": "预期送达",
      "button_submit": "创建登记"
    },
    "checkin_done": {
      "confirmation_message": "您的登记已接收！",
      "email_instructions": "文件已通过电子邮件发送。您也可以直接从这里下载。",
      "shipping_instructions_title": "发货指南：",
      "shipping_instruction_1": "确保产品按照我们的 交付条件</a>进行包装。",
      "shipping_instruction_2": "然后下载QR码并将其贴在箱子外面。",
      "important": "重要：",
      "important_note": "此QR码将登记您的包裹。如果缺少，您的发货将不被接受，并将被退回给供应商。",
      "view_information_button": "查看信息",
      "documents_per_product": "每个产品的文件",
      "box_label": "盒子",
      "packing_slip_label": "装箱单",
      "qr_code_label": "QR码",
      "download_all_documents_button": "下载所有文件",
      "processing_shipment": "我们正在处理您的登记。请留在本页面！"
    }
  },
  "inbound_details": {
    "delivery": {
      "delivery": "交货",
      "expected": "预期",
      "delivered": "已交付",
      "organisation": "组织",
      "contactInformation": "联系信息",
      "units": "单位",
      "packedPer": "每包装",
      "transit": "运输中"
    },
    "location": {
      "location": "位置",
      "expirationDate": "到期日期",
      "isPackageClosed": "包裹是否已关闭到达"
    },
    "extraWork": "额外工作和附加费",
    "note": "备注"
  },
  "history_locations": {
    "button_name": "历史位置",
    "name": "产品的历史位置",
    "none": "未找到历史位置"
  },
  "stock_analysis": {
    "button_name": "库存分析",
    "analysis": "分析",
    "checked_in": "已登记",
    "date": "日期",
    "amount": "数量",
    "retours": "退货",
    "period": "期间",
    "orders": "订单",
    "logical_stock": "逻辑库存"
  },
  "automations": {
    "button_name": "自动化"
  },
  "validation_trx": {
    "fieldIsRequired": "此字段是必填项！",
    "invalidFormat": "格式无效！",
    "numberTooSmall": "数字太小！最小值：{0}",
    "numberTooBig": "数字太大！最大值：{0}",
    "invalidNumber": "数字无效",
    "invalidInteger": "值不是整数",
    "textTooSmall": "文本长度太短！当前：{0}, 最小：{1}",
    "textTooBig": "文本长度太长！当前：{0}, 最大：{1}",
    "thisNotText": "这不是文本！",
    "thisNotArray": "这不是数组！",
    "selectMinItems": "至少选择{0}个项目！",
    "selectMaxItems": "最多选择{0}个项目！",
    "invalidDate": "日期无效！",
    "dateIsEarly": "日期太早了！当前：{0}, 最小：{1}",
    "dateIsLate": "日期太晚了！当前：{0}, 最大：{1}",
    "invalidEmail": "电子邮件地址无效！",
    "invalidURL": "URL无效！",
    "invalidCard": "卡格式无效！",
    "invalidCardNumber": "卡号无效！",
    "invalidTextContainNumber": "文本无效！不应包含数字或特殊字符",
    "invalidTextContainSpec": "文本无效！不应包含特殊字符",
    "add_product_name": "添加产品名称",
    "add_ean": "添加EAN",
    "invalid_product_items": "至少添加一个产品",
    "invalid_streetname": "添加街道名称",
    "invalid_housenumber": "添加门牌号",
    "invalid_postcode": "添加邮政编码",
    "invalid_country": "添加国家",
    "invalid_ship_option": "选择一个运输选项",
    "invalid_type": "选择为什么要创建此订单",
    "invalid_email": "添加电子邮件",
    "invalid_EAN_input": "EAN无效，必须包含13位数字",
    "invalid_phone": "添加电话号码",
    "invalid_firstname": "添加名字",
    "invalid_surname": "添加姓氏",
    "invalid_company": "添加公司名称",
    "invalid_city": "添加城市",
    "invalid_hs": "添加HS码",
    "invalid_value": "添加价值",
    "invalid_weight": "添加重量",
    "invalid_shipment_method": "选择一个运输选项",
    "invalid_attachment_text": "为您的附件添加备注",
    "invalid_attachment_files": "如果您有指南，请添加附件"
  }
}
