<template>
    <div class="product__surcharge" v-if="surcharges_list">
        <h4 class="product__surcharge--title">
          <img src="../../assets/icons/delivery_icons/J01.svg" :title="trx.admin.surcharge.name">
        </h4>
        <v-select v-model="product.surcharges"  @input="select_surcharges({product_id: product._id, items: product.surcharges})" taggable multiple push-tags :options="surcharges_list" label="name" >
            
            <template slot="selected-option" slot-scope="option" >
                <div style="display: flex; align-items: baseline">
                    <strong style="margin-right:2px">{{ option.name }}</strong> {{ option.price }}
                </div>
            </template>

            <template slot="option" slot-scope="option" >
                <p>{{option.name}}</p>
                <small class="text-muted">
                  € {{ option.price }}
                </small>
            </template>    
        </v-select>   
    </div>
</template>

<script>

/* eslint-disable no-debugger */

import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SelectShippingMethod',
  computed: {
        ...mapGetters(['products', 'surcharges_list'])
    },
  methods: {
        ...mapActions([
            'fetch_surcharges_list',
            'select_surcharges',
        ])
    },
  components: {

  },
  created() {
    if (this.fetch_surcharges_list === null) {
        this.fetch_surcharges_list()
    }
  },
  props: {
    product: Object,
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>
    .product__surcharge {
      position: relative;
      &--title {
       position: absolute;
       width: 5rem;
       z-index: auto;
       left: -3.8rem;
       display: flex;
       align-content: center;
       align-items: center;
       justify-content: center;
       height: 4rem;
       border-top-left-radius: var(--border-radius);
       border-bottom-left-radius: var(--border-radius);
       background-color: var(--color-grey-light-2);
       img {
        width: 3.4rem;
       }
      }

      .v-select.vs--multiple.vs--searchable {
        position: relative;
        width:calc(100% - 4rem)  !important;
        height: 4rem;
        padding: 0;
        right: -1rem;
        border-top-right-radius: 0px;
        border-top-left-radius: 0px;
      }

      &--value {
          position: absolute;
          top: 3.7rem;
          left:2rem;
          z-index: 99;
      }
    }
</style>


    