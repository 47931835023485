import api from '@/api';

/* eslint-disable no-debugger */

const state = {
    pick_list: [],
    curr_rows: null,
    skip: 0,
    product: undefined,
};

const getters = {
    pick_list: state => state.pick_list,
    product: state => state.product
};

const actions = {
    async fetch_pick_list({ dispatch, commit, state }, model) {

        state.pick_list = [];

        if (model) {
            state.curr_rows = model.locations ? model.locations : null;
            state.curr_rows = state.curr_rows != "" ? state.curr_rows : null;
            state.orders_date_range = model.date_range;
            state.query_type = model.query_type;
            state.skip = model.skip;
            state.delivery = model.delivery;
            state.sequence = model.sequence;
            state.single_orders = model.single_orders ? model.single_orders : false;
            state.multi_orders = model.multi_orders ? model.multi_orders : false;
            state.orgs = model.orgs ? model.orgs : null;
            state.order_id = model.order_id ? model.order_id : null;
        }

        const response = await api.get("/pick_list/pick/" + state.curr_rows + '/' + state.query_type + '/'
        + state.orders_date_range.startDate + '/' + state.orders_date_range.endDate + '/' + state.orgs+ "?skip=" + state.skip
        + "&delivery=" + state.delivery + "&sequence=" + state.sequence + "&single_orders=" + state.single_orders + "&multi_orders=" + state.multi_orders + "&order_id=" + state.order_id);

        const pick_list = response.data;
        if (pick_list.length === 0) {
            dispatch('speak', 'Hoppa! je bent klaar met picken!')
            return;
        }
        dispatch('speak',  pick_list[0].rack.split('').join(' ')+'  '+pick_list[0].loc_number + '  ' + pick_list[0].qty +' Keer')
        response !== undefined ? commit('set_pick_list', response.data) : '';
    },
    async link_pick_location({ dispatch }, model) {
        await api.post('/pick_list', model)
        dispatch('fetch_pick_list');
    },
    async delete_pick_location({ dispatch }, model) {
        await api.delete(`/locations/clear/${model.location_product_id}/${model.product_id}/${model.location_id}`, model)
        dispatch('fetch_pick_list');
    },
    async setProduct({commit}, product__id) {
        const response = await api.get("/product/" + product__id);
        response !== undefined ? commit('set_product', response.data[0]) : '';
    },
    async update_recount({dispatch}, model) {
        await api.post("/product/recount/" + model.product__id, {
          recount: model.recount,
        });
        dispatch('fetch_pick_list');
    },
    speak(commit, text) {
        // let hasEnabledVoice = false;

        // document.addEventListener('click', () => {
        // if (hasEnabledVoice) {
        //     return;
        // }
        // const lecture = new SpeechSynthesisUtterance('hello');
        //     lecture.volume = 0;
        //     speechSynthesis.speak(lecture);
        //     hasEnabledVoice = true;
        // });

        const msg = new SpeechSynthesisUtterance();
        
        msg.text = text;
        msg.volume = 1.0; // speech volume (default: 1.0)
        msg.pitch = 1.0; // speech pitch (default: 1.0)
        msg.rate = 0.8; // speech rate (default: 1.0)
        msg.lang = "nl-NL"; // speech language (default: 'en-US')
        

        msg.onboundary = function (event) {
            console.log("Speech reached a boundary:", event.name);
        };
        msg.onpause = function (event) {
            console.log("Speech paused:", event.utterance.text.substring(event.charIndex));
        };
        msg.onend = function(event) {
            console.log("Speech finished:", event);
        };
        msg.onerror = function(event) {
            console.log("Speech error:", event);
        };
        // Check if speech synthesis is supported
        if ('speechSynthesis' in window) {

            // alert("Speech synthesis supported.");
            window.speechSynthesis.speak(msg);
            console.log(commit);
        } else {
            alert("Speech synthesis not supported on this browser.");
        }
    },
    async correct_stock({dispatch}, model) {
        await api.post("/correct_stock/", model);
        dispatch('fetch_pick_list');
    },
    async submit_popup_pick({ commit, state }, model) {
        console.log(model, commit)

        // get current pick list
        console.log(state.pick_list[0].qty)

        // change pick_list qty
        console.log(state.pick_list[0].orders)


        // commit to state

        // refresh pick list
    }

};

const mutations = {
    set_pick_list: (state, pick_list) => (state.pick_list = pick_list),
    set_product: (state, product) => (state.product = product)
};

export default {
    state,
    getters,
    actions,
    mutations
}