<template>
    <div class="charts" v-if="order_report_all?.length > 1">
        <h3 class="no-margin text-center">Orders afgelopen 30 dagen</h3>
        <GChart
            
            type="ColumnChart"
            style="height:320px"
            :data="order_report_all"
            :options="{ 
                title: '', 
                curveType: 'function', 
                legend: { position: 'bottom'},
                isStacked: false, 
                backgroundColor: {
                    fill: 'none',
                },
                colors: ['#009344', '#006433'],
                hAxis: {
                    format: 'd-MM',
                    gridlines: {
                        count: 32,
                        units: {
                            days: {format: ['m']}
                        }
                    },
                    minorGridlines: {
                        count: 0
                    }
                },
                vAxis: {
                    gridlines: {
                        count: 0,
                        units: {
                            days: {format: ['MM']}
                        }
                    },
                    minorGridlines: {
                       count: 0
                    }
                }
            }"
          /> 

    </div>
   
</template>

<script>
import { GChart } from "vue-google-charts";
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

/* eslint-disable no-debugger */

export default {
  name: "Report_orders",
  components: {
    GChart
  },
  computed: {
      ...mapGetters(['order_report', 'order_report_all']),
  },
  methods: {
    ...mapActions(['getOrderReport', 'get_order_report_all'])
  },
  created: async function() {
    await this.getOrderReport(this.dateRange);
    await this.get_order_report_all()
  },
  data () {
      let start = new Date();
      let end = new Date();

      start.setDate(start.getDate() - 30)

      const startDate = new Date(moment(start).startOf('day').toISOString()) 
      const endDate = new Date(moment(end).endOf('day').toISOString())
      return {
        dateRange: {startDate , endDate}
      }
    },
    filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    }
};
</script>

<style lang="scss" scoped>
    h3.no-margin {
        margin-bottom: 1rem;
        font-size: 2rem;
    } 
  .charts_conn {
    overflow: auto;
  }
  .charts {
    .gauge {
      display: flex;
      justify-content: center;
      align-items: center;
    }
   
  }
  
</style>
