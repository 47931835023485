import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import HighchartsVue from 'highcharts-vue';

Vue.use(HighchartsVue);

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')

// Define a global filter in Vue
Vue.filter('formatEuro', function(value) {
  if (!value) return '';
  // Create an instance of Intl.NumberFormat for Euro formatting
  const formatter = new Intl.NumberFormat('nl-NL', { // Using Ireland locale, you can choose others like 'de-DE' for Germany etc.
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(value);
});

Vue.directive("focus-on-visible", {
  inserted(el) {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          el.focus();
        }
      },
      { threshold: 1.0 }
    );

    el._observer = observer;
    observer.observe(el);
  },
  unbind(el) {
    if (el._observer) {
      el._observer.disconnect();
    }
  }
});
