export default {
  "date_format": "JJ-MM-AA",
  "date_time_format": "JJ-MM-AAAA HH:MM",
  "time_format": "HH:MM",
  "delete": "Supprimer",
  "continue": "Continuer",
  "update": "Mettre à jour",
  "Show preview": "Afficher l'aperçu",
  "Data preview": "Aperçu des données",
  "Select a file to start import": "Sélectionnez un fichier pour commencer l'importation",
  "Total": "Total",
  "Count": "Compte",
  "Sub total": "Sous-total",
  "Discount": "Remise",
  "Add": "Ajouter",
  "Import": "Importer",
  "Date": "Date",
  "Track & Trace": "Suivi & Localisation",
  "Phone": "Téléphone",
  "Click here your select an Excel file": "Cliquez ici pour sélectionner un fichier Excel",
  "Something went wrong, please conduct your logs": "Un problème est survenu, veuillez consulter vos journaux",
  "Sender": "Expéditeur",
  "Email": "E-mail",
  "Location": "Emplacement",
  "Comment": "Commentaire",
  "Amount": "Montant",
  "search": "Rechercher",
  "expand": "Déployer",
  "condense": "Condenser",
  "save": "Sauvegarder",
  "read": "Lire",
  "pick_note": "Note de prélèvement",
  limit: "Limite",
  refresh: "Rafraîchir",
  import: "Importer",
  "common": {
    "name": "Nom",
    "search_results": "Résultats de recherche",
    "select_date_time": "Sélectionner la date & l'heure"
  },
  "admin": {
    "name": "Paramètres admin",
    "search_orderid": {
      "name": "Rechercher par ID de commande"
    },
    "ship_boxes": {
      "name": "Boîtes d'expédition & dimensions",
      "max_size": "Dimension maximale (l,x,h)",
      "package": "Paquet",
      "product_size": "Taille du produit",
      "package_cost": "Coût de l'emballage",
      "purchase_cost": "Coûts d'achat",
      "package_code": "Numéro d'article",
      "fulfillment_cost": "Coûts de réalisation",
      "location": "Emplacement",
      "create": "Créer un nouveau type d'emballage"
    },
    "labels": {
      "name": "Étiquettes pour le scan"
    },
    "locations": {
      "name": "Paramètres de localisation"
    },
    "shipment_method": {
      "name": "Options d'expédition",
      "code": "Code",
      "price": "Prix",
      "purchase_price": "Prix d'achat",
      "cut_off": "Heure limite",
      "late_drop_surcharge": "Supplément pour dépôt tardif",
      "peak_surcharge": "Supplément de pointe"
    },
    "surcharge": {
      "name": "Suppléments",
      "code": "Code",
      "price": "Prix",
      "note": "Note",
      "type": "Type"
    },
    "users": {
      "name": "Utilisateurs & groupes",
      "password_hint": "Au moins 9 caractères"
    },
    "transfers": {
      "name": "Tous les transferts ouverts"
    }
  },
  "settings": {
    "apis": "Intégrations",
    "Settings": "Paramètres",
    "Your organization": "Votre organisation",
    "Subscription": "Abonnements",
    "Users": "Utilisateurs",
    "invoice": {
      "total_used_packaging": "Emballages utilisés",
      "total_used_shipment_method": "Méthodes d'expédition utilisées",
      "total_used_surcharge": "Suppléments utilisés",
      "total_fulfilment": "Totalisation",
      "total": "Total",
      "download_details": "Télécharger les détails",
      "rack-box": "Emplacements des étagères",
      "half-box": "Demi-emplacements des étagères",
      "warehousing": "Emplacements d'entrepôt",
      "pallet": "Emplacements des palettes",
      "1/2pallet": "Demi-emplacements des palettes",
      "1/3pallet": "Tiers des emplacements des palettes"
    }
  },
  "organizations": {
    "name": "Magasins",
    "organization": "Magasin",
    "Organization address": "Adresse du magasin",
    "description": "Un aperçu de tous vos magasins",
    "Organization name": "Nom du magasin",
    "Your organization details": "Détails de votre magasin",
    "type": "Type de magasin",
    "about": "À propos",
    "coc_number": "Numéro de registre du commerce",
    "vat_number": "Numéro de TVA",
    "surchrages": "Suppléments",
    "Contact Person": "Personne de contact",
    "Contact Person Email": "E-mail de la personne de contact",
    "delete_org_help": "Zone dangereuse, supprimez votre magasin",
    "Phone": "Téléphone",
    "Branche": "Branche"
  },
  "users": {
    "Name": "Nom",
    "Email": "E-mail",
    "Login": "Connexion",
    "First name": "Prénom",
    "Last name": "Nom de famille",
    "Password": "Mot de passe",
    "Phone": "Numéro de téléphone",
    "Licence": "Licence",
    "Verified": "Vérifié",
    "Signup": "Inscription",
    "Create a new user": "Créer un nouvel utilisateur",
    "Users in your organization": "Membres de votre organisation",
    "Import users": "Importer des utilisateurs",
    "Stakeholder Group": "Groupe d'intérêt",
    "Already have an account, login": "Vous avez déjà un compte ? Connexion",
    "Signup to get started": "Inscrivez-vous pour commencer",
    "Not a user? Signup!": "Pas encore utilisateur ? Inscrivez-vous maintenant !",
    "reset_password": "Mot de passe oublié ?",
    "reset": "Réinitialiser le mot de passe",
    "Please enter your email": "Veuillez entrer votre e-mail",
    "Know your password?, Login": "Vous connaissez votre mot de passe ? Connexion !",
    "adress_hint": "Entrez votre adresse, utilisez une virgule pour séparer le code postal et le lieu"
  },
  "plans": {
    "name": "Plans"
  },
  "subscriptions": {
    "name": "Tarifs",
    "Standard": "Standard",
    "Discount for yearly payment": "Réduction pour paiement annuel",
    "Terms of Service": "Conditions de service",
    "Your current subscription": "Votre abonnement actuel",
    "I have read and accept the terms of condition": "J'ai lu et j'accepte les conditions",
    "CSR Managers": "Gestionnaires RSE",
    "free_plan_help": "",
    "Stakeholders": "Parties prenantes",
    "Select your plan": "Sélectionnez votre plan",
    "Enterprise": "Entreprise",
    "Start for free": "Commencez gratuitement",
    "Price / order": "Prix par commande",
    "Invoices": "Factures",
    "description": "Affiche un aperçu de tous les abonnements disponibles",
    "new": "Nouvel abonnement",
    "Pricing knowledge": "Connaissance des prix"
  },
  "shops": {
    "Create a new Shop": "Créer un nouveau magasin",
    "Shops": "Magasins"
  },
  "products": {
    "button_options_name": "Options de produit",
    "button_link_name": "Liens de produit",
    "Product": "Produit",
    "ProductName": "Nom du produit",
    "Add inventory": "Ajouter à l'inventaire",
    "Inventory checkin": "Enregistrement de l'inventaire",
    "Sync with bol.com": "Synchroniser avec bol.com",
    "Note": "Note",
    "Return instruction": "Instruction de retour",
    "search_placeholder": "Rechercher un produit par nom ou EAN",
    "sf_stock": "Stock intelligent",
    "total_stock": "Stock total",
    "lvb_stock":  "Stock LVB",
    "warehouse_stock": "Stock d'entrepôt",
    "import_your_products": "Importez vos produits ici",
    "create": "Ajouter un nouveau produit",
    "note": "Note",
    "ean": "EAN",
    "search": "Rechercher un produit par nom ou EAN",
    "pick_note": "Note de prélèvement",
    "pack_note": "Note d'emballage",
    "customs": {
      "button_name": "Douane",
      "settings": "Paramètres douaniers",
      "customs": "Douane (expéditions hors UE)",
      "hs": "Code SH",
      "value": "Valeur en euros",
      "weight_kg": "Poids en kilogrammes"
    },
    "recount": {
      "button_name": "Recomptage",
      "recounting": "Recomptage",
      "settings": "Paramètres de recomptage"
    },
    "import": {
      "add_product": "⥊ Ajouter un produit",
      "xlsx": "⥊ Excel",
      "barcode_sku_ean": "EAN / SKU / code-barres",
      "Product_name": "Produit",
      "fullfilled_bysf": "Activé/Désactivé",
      "length": "Longueur",
      "height": "Hauteur",
      "width": "Largeur",
      "weight_kg": "Poids (kg)"
    },
    "sync": {
      "bol": "⥊ Bol.com",
      "woo": "⥊ WooCommerce",
      "shopify": "⥊ Shopify",
      "mirakl": "⥊ Mirakl",
      "lightspeed": "⥊ Lightspeed",
      shopware: "⥊ Shopware",
      "excel": "⥊",

      "help": "Utilisez ces boutons pour importer des produits que vous avez publiés sur différentes plateformes.",
      "import_products": "Importer"
    }
  },
  "inventory": {
    "Pre-announced": "Préannoncé",
    delivered: "Livré",
    "Box": "Boîte",
    "Boxes": "Boîtes",
    "Pallet": "Palette",
    "Pallets": "Palettes"
  },
  "returns": {
    "name": "Retours",
    "return_number_or_barcode": "Numéro de retour ou code-barres",
    "scan_help_text": "Entrez un numéro de retour ou scannez un code-barres",
    "location": "Emplacement",
    "product": "Produit",
    "return_id": "Numéro de retour",
    "return_instruction": "Instruction de retour",
    "damage": "Dommage",
    "damaged_package": "Emballage endommagé",
    "damaged_product": "Produit endommagé",
    "damaged_product_package": "Emballage de produit endommagé",
    "registered": "Date d'enregistrement",
    "product_state": "État du produit",
    "reason": "Raison du retour client/consommateur",
    "customer_details": "Détails du consommateur",
    "no_returns_to_show": "Aucun retour à afficher",
    "handel_return_close": "Finaliser et fermer",
    "RETURN_RECEIVED": "Retour reçu",
    "EXCHANGE_PRODUCT": "Échanger le produit",
    "RETURN_DOES_NOT_MEET_CONDITIONS": "Le retour ne répond pas aux conditions",
    "REPAIR_PRODUCT": "Réparer le retour",
    "CUSTOMER_KEEPS_PRODUCT_PAID": "Le client conserve le produit payé",
    "STILL_APPROVED": "TOUJOURS APPROUVÉ",
    "product_excellent": "Le produit semble soigné et peut être revendu",
    "product_good": "Produit assez correct, peut retourner sur l'étagère",
    "product_bad_state": "Produit/emballage endommagé ne peut pas être vendu"
  },
  "orders": {
    "Order ID": "ID de commande",
    "order": "Commande",
    "Date": "Date",
    "Add order": "Ajouter commande",
    "Your orders": "Vos commandes",
    "Orders": "Commandes",
    multi_orders: "Commandes multiples",
    warning_orders: "Commandes en attente",
    "Status": "Statut",
    "Create": "Créer une nouvelle commande",
    "Shipments": "Expéditions",
    "Products": "Produits",
    "Receiver": "Récepteur",
    "name": "Nom",
    "surename": "Nom de famille",
    "streetname": "Nom de la rue",
    "housenumber": "Numéro de maison",
    "addition": "Addition",
    "postalcode": "Code postal",
    "place": "Lieu",
    "city": "Ville",
    "country": "Pays",
    "company": "Nom de l'entreprise",
    "Sync with bol.com": "Synchroniser avec bol.com",
    "Send products to us": "Envoyez-nous des produits",
    "EAN": "EAN",
    "Delivery time": "Délai de livraison",
    "Own stock": "Stock propre",
    "SF stock": "Stock SF",
    "Stock": "Stock",
    "Fulfil by SF": "Traitement par SF",
    "Fulfilled by BOL (FBB)": "Traitement par BOL (FBB)",
    "FBB": "FBB",
    "SF": "SF",
    "Add inventory": "Ajouter au stock",
    "Send your products to us": "Envoyez vos produits chez nous",
    "Default shipment": "Méthode d'expédition par défaut",
    "Location": "Emplacement",
    "Box size": "Dimension de la boîte",
    "email": "E-mail",
    "phone": "Téléphone",
    "Select products you want to send": "Sélectionnez les produits que vous souhaitez envoyer",
    "Wrong product": "Produit incorrect",
    "Wrong quantity": "Quantité incorrecte",
    "Damaged product": "Produit endommagé",
    "Webshop order": "Commande de boutique en ligne",
    "order_comments": "Commentaires sur la commande",
    "import_your_orders": "Importez vos commandes ici",
    "shipping_info": "Informations sur l'expédition",
    "confirmation": "Confirmation",
    "product_selector_text": "Sélectionnez les produits que vous souhaitez envoyer",
    "products_in_order": "Produits dans cette commande",
    "to_sending": "Vers les détails d'expédition",
    "to_confirm": "Pour confirmer",
    "to_create": "Confirmer et envoyer",
    "shipping_method": "Méthode d'expédition",
    "attachment": "Pièce jointe (PDF uniquement)",
    "attachment_placeholder": "Instructions pour la manipulation de la pièce jointe",
    "send": "Commande créée",
    "import": {
      "order_id": "ID de commande",
      "product_name": "Produit",
      "quantity": "Quantité",
      "city": "Ville",
      "countryCode": "Code du pays",
      "email": "E-mail",
      "firstName": "Prénom",
      "surName": "Nom de famille",
      "houseNumber": "Numéro de maison",
      "houseNumberExtension": "Extension du numéro de maison",
      "language": "Langue",
      "salutation": "Salutation",
      "streetName": "Nom de la rue",
      "zipcode": "Code postal",
      "deliveryPhoneNumber": "Numéro de téléphone pour la livraison",
      "reason": "Raison",
      "notes_text": "Note",
      "notes_color": "Couleur de la note",
      "company": "Entreprise"
    },
    "manual_send": {
      "reason": "Raison de la commande manuelle",
      "received": "Commande non reçue par PostNL",
      "incorrect": "Produit incorrect",
      "quantity": "Quantité incorrecte",
      "damaged": "Produit endommagé",
      "webshop": "Commande de boutique en ligne"
    }
  },
  "pick_list": {
    "name": "Liste de prélèvement",
    "qty": "Quantité"
  },
  "shipments": {
    "sent_orders": "Commandes envoyées",
    "24h": "24 heures",
    "> 2 days": "> 2 jours",
    "on_the_way": "En route",
    "not_sorted": "Non trié",
    "ordered_by": "Commandé par",
    "order_date": "Date de commande",
    "sent_date": "Date d'envoi",
    "delivery_date": "Date de livraison",
    "shipment_method": "Méthode d'expédition",
    "day_delivered": "< 24 heures livrées",
    "two_day_delivered": "< 48 heures livrées",
    "search_placeholder": "Rechercher par nom, commande ou code postal",
    "Shipment Address": "Adresse d'expédition",
    "Amount": "Quantité",
    "Ready for shipment": "Prêt à être expédié",
    "Packed": "Emballé",
    "Shipped": "Expédié",
    "Shipment options": "Options d'expédition",
    "Open": "Ouvrir",
    "Pallets": "Palettes",
    "Coli": "Colis/Boîtes",
    "Type": "Type",
    "remark": "Remarque"
  },
  "checkinv": {
    "created_at": "Envoyé",
    "expected_delivery": "Attendu",
    "status": "Statut",
    "checked_in_date": "Enregistré",
    "product_name": "Produit",
    "product_ean": "Ean/SKU",
    "expected": "Attendu",
    "final_check_in": "Enregistré",
    "comment": "Commentaire",
    "pallets": "Palettes",
    "pallet": "Palette",
    "box": "Boîte",
    "boxes": "Boîtes",
    "change_checkin": "Voir/modifier l'enregistrement",
    "search": "Rechercher un enregistrement par nom",
    "edit_checkin": "Voir/modifier l'enregistrement",
    "add_checkin": {
      "overview": "Aperçu",
      "out_of_stock": "Rupture de stock",
      "application": "Application",
      "reason": "Que voulez-vous faire ?",
      "stock_checkin": "Enregistrement du stock",
      "stock_checkin_info": "Cliquez ici pour nous informer de vos expéditions prévues, afin que nous puissions préparer de manière optimale la réception et le stockage, et gérer soigneusement vos marchandises.",
      "change_checkin": "Voir/modifier l'enregistrement",
      "change_checkin_info": "Vérifiez ou modifiez vos expéditions précédemment signalées pour maintenir les détails à jour et assurer une expérience logistique sans faille.",
      "type": "Type",
      "products": "Produits",
      data: "faits",
      "confirmation": "Confirmation"
    },
    "type": {
      "package": "Expédition de colis",
      "package_text": "Choisissez cette option pour l'enregistrement d'articles plus petits, emballés individuellement. Livré par PostNL, UPS, DpD ou d'autres services de colis",
      "pallet": "Expédition de palettes",
      "pallet_text": "Sélectionnez ceci pour les expéditions plus grandes sur des palettes pour un transport organisé.",
      "container_other": "Expédition de conteneur / autres",
      "container_other_text": "Pour l'enregistrement des expéditions dans des conteneurs ou des tailles non standard."
    },
    "checkin_products": {
      "choose_products_in_box": "Choisissez le produit qui se trouve dans cette boîte",
      "no_products": "Vous n'avez encore aucun produit dans notre système, veuillez d'abord faire cela",
      "search_products": "Rechercher un produit par nom ou EAN"
    },
    "checkin_product": {
      "unit_amount": "Nombre d'unités (boîtes)*",
      "amount": "Quantité",
      "box_amount": "Quantité dans la boîte*",
      "total_amount": "Nombre total de pièces (CE)",
      "expiration_date": "Date d'expiration",
      "batch_number": "Numéro de lot",
      "add": "Ajouter",
      "divergent": "* Une boîte est-elle différente ? Terminez cette étape avec les boîtes correspondantes puis ajoutez à nouveau le même produit, dans l'étape précédente.",
      "check": "Vérifiez si vos produits sont correctement configurés",
      "back": "Retour",
      "totals": "Totaux",
      "per_box": "Produits par boîte",
      "boxes": "Boîtes",
      "submit_checkin": "Confirmer l'enregistrement"
    },
    "checkin_success": {
      "shipment_instructions": "Instructions d'expédition:",
      "documents_sent": "Les documents ont été envoyés par courrier électronique. Vous pouvez également les télécharger directement d'ici",
      "checkin_step1": "Téléchargez le bordereau d'emballage et placez-le dans le colis",
      "checkin_step2": "Ensuite, téléchargez le code QR et collez-le sur l'extérieur de la boîte",
      "checkin_important": "Important : ce code QR enregistrera votre colis. S'il manque, le processus d'enregistrement sera retardé."
    },
    "checkin_information": {
      "type": "Indiquez où se trouvent les produits",
      "products": "Choisissez un par un les produits que vous souhaitez ajouter",
      "information": "Remplissez les informations ci-dessous pour compléter l'enregistrement"
    },
    "checkin_confirmation": {
      "contact_person_label": "Personne de contact",
      "contact_person_placeholder": "Personne de contact",
      "contact_person_email_label": "E-mail de la personne de contact",
      "contact_person_email_placeholder": "E-mail de la personne de contact",
      "phone_label": "Numéro de téléphone",
      "phone_placeholder": "Téléphone",
      "shipping_address_label": "Adresse d'expédition",
      "shipping_address_placeholder": "Adresse d'expédition",
      "track_trace_label": "Suivi & Localisation",
      "track_trace_placeholder": "Suivi & Localisation",
      "expected_delivery_label": "Livraison prévue",
      "expected_delivery_placeholder": "Livraison prévue",
      "button_submit": "Créer l'enregistrement"
    },
    "checkin_done": {
      "confirmation_message": "Votre enregistrement a été reçu !",
      "email_instructions": "Les documents ont été envoyés par e-mail. Vous pouvez également les télécharger directement ici.",
      "shipping_instructions_title": "Instructions d'expédition:",
      "shipping_instruction_1": "Téléchargez le bordereau d'emballage et placez-le avec le colis / faites-le placer avec le colis.",
      "shipping_instruction_2": "Ensuite, téléchargez le code QR et collez-le sur l'extérieur de la boîte.",
      "important": "Important :",
      "important_note": "Ce code QR enregistrera votre colis. S'il manque, votre envoi ne sera pas accepté et sera renvoyé au fournisseur.",
      "view_information_button": "Voir les informations",
      "documents_per_product": "Documents par produit",
      "box_label": "Boîte",
      "packing_slip_label": "Bordereau d'emballage",
      "qr_code_label": "Code QR",
      "download_all_documents_button": "Télécharger tous les documents",
      "processing_shipment": "Nous traitons votre enregistrement. Restez sur cette page !"
    }
  },
  "inbound_details": {
    "delivery": {
      "delivery": "Livraison",
      "expected": "Attendu",
      "delivered": "Livré",
      "organisation": "Organisation",
      "contactInformation": "Coordonnées",
      "units": "Unités",
      "packedPer": "Emballé par",
      "transit": "En transit"
    },
    "location": {
      "location": "Emplacement",
      "expirationDate": "Date d'expiration",
      "isPackageClosed": "Le paquet est-il arrivé fermé"
    },
    "extraWork": "Travaux supplémentaires & surcharges",
    "note": "Remarques"
  },
  "history_locations": {
    "button_name": "Emplacements historiques",
    "name": "Emplacements historiques du produit",
    "none": "Aucun emplacement historique trouvé"
  },
  "stock_analysis": {
    "button_name": "Analyse des stocks",
    "analysis": "Analyse",
    "checked_in": "Enregistré",
    "date": "Date",
    "amount": "Quantité",
    "retours": "Retours",
    "period": "Période",
    "orders": "Commandes",
    "logical_stock": "Stock logique"
  },
  "automations": {
    "button_name": "Automatisations"
  },
  "validation_trx": {
    "fieldIsRequired": "Ce champ est requis !",
    "invalidFormat": "Format invalide !",
    "numberTooSmall": "Le nombre est trop petit ! Minimum : {0}",
    "numberTooBig": "Le nombre est trop grand ! Maximum : {0}",
    "invalidNumber": "Nombre invalide",
    "invalidInteger": "La valeur n'est pas un entier",
    "textTooSmall": "La longueur du texte est trop petite ! Actuel : {0}, Minimum : {1}",
    "textTooBig": "La longueur du texte est trop grande ! Actuel : {0}, Maximum : {1}",
    "thisNotText": "Ce n'est pas du texte !",
    "thisNotArray": "Ce n'est pas un tableau !",
    "selectMinItems": "Sélectionnez au moins {0} éléments !",
    "selectMaxItems": "Sélectionnez au maximum {0} éléments !",
    "invalidDate": "Date invalide !",
    "dateIsEarly": "La date est trop tôt ! Actuel : {0}, Minimum : {1}",
    "dateIsLate": "La date est trop tard ! Actuel : {0}, Maximum : {1}",
    "invalidEmail": "Adresse e-mail invalide !",
    "invalidURL": "URL invalide !",
    "invalidCard": "Format de carte invalide !",
    "invalidCardNumber": "Numéro de carte invalide !",
    "invalidTextContainNumber": "Texte invalide ! Ne doit pas contenir de chiffres ou de caractères spéciaux",
    "invalidTextContainSpec": "Texte invalide ! Ne doit pas contenir de caractères spéciaux",
    "add_product_name": "Ajoutez un nom de produit",
    "add_ean": "Ajoutez un EAN",
    "invalid_product_items": "Ajoutez au moins un produit",
    "invalid_streetname": "Ajoutez un nom de rue",
    "invalid_housenumber": "Ajoutez un numéro de maison",
    "invalid_postcode": "Ajoutez un code postal",
    "invalid_country": "Ajoutez un pays",
    "invalid_ship_option": "Choisissez une option d'expédition",
    "invalid_type": "Choisissez pourquoi vous créez cette commande",
    "invalid_email": "Ajoutez un e-mail",
    "invalid_EAN_input": "EAN invalide, doit contenir 13 chiffres",
    "invalid_phone": "Ajoutez un numéro de téléphone",
    "invalid_firstname": "Ajoutez un prénom",
    "invalid_surname": "Ajoutez un nom de famille",
    "invalid_company": "Ajoutez un nom d'entreprise",
    "invalid_city": "Ajoutez une ville",
    "invalid_hs": "Ajoutez un code SH",
    "invalid_value": "Ajoutez une valeur",
    "invalid_weight": "Ajoutez un poids",
    "invalid_shipment_method": "Choisissez une méthode d'expédition",
    "invalid_attachment_text": "Ajoutez une note pour votre pièce jointe",
    "invalid_attachment_files": "Ajoutez une pièce jointe si vous avez des instructions"
  }
}
