<template>
  <div class="orgs-form">
        <vue-form-generator :schema="schema" :model="FormModel" :options="formOptions"></vue-form-generator>
  </div>
</template>

<script>


import Vue from 'vue';
import VueFormGenerator from "vue-form-generator";
import trx from '@/translations';

Vue.use(VueFormGenerator)

/* eslint-disable no-debugger */


export default {
  name: 'organization-form',
  components: {
    "vue-form-generator": VueFormGenerator.component,
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0]; // Get the selected file
      debugger;
      if (file) {
        try {
          const base64String = await this.convertFileToBase64(file);
          this.FormModel.company_logo = {
            img_data: base64String,
            img_ext: file.type.split('/')[1]
          };
          console.log("Converted to Base64:", base64String);

        } catch (error) {
          console.error("Error converting file to Base64:", error);
        }
      }
    },
    convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(',')[1]); // Get the Base64 data
        };
        reader.onerror = reject;
        reader.readAsDataURL(file); // Read the file as a data URL
      });
    }
},
  props: {
    submit: Function,
    submitNmae: String,
    model: {}
  },
  mounted() {
    // Directly access the file input through a querySelector
    const fileInput = this.$el.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.addEventListener('change', this.handleFileChange);
    }
  },
  beforeDestroy() {
    // Clean up the event listener if necessary
    const fileInput = this.$el.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.removeEventListener('change', this.handleFileChange);
    }
  },
  data () {
    return {
      trx,
      FormModel: this.model,
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Organization name'] + "*",
            model: 'name',
            placeholder: 'Apple incl.',
            required: true,
            validator: VueFormGenerator.validators.string
          },
          {
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Contact Person']+ "*",
            placeholder: 'John Doe',
            model: 'contact_person',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'email',
            label: trx.organizations['Contact Person Email']+ "*",
            placeholder: 'mail@exaple.com',
            model: 'contact_person_email',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Organization address']+ "*",
            placeholder: 'Databankweg 18, 3821AL, Amersfoort',
            model: 'address.line1',
            required: true,
            validator: VueFormGenerator.validators.string,
            hint: trx.users.address_hint
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations['Phone']+ "*",
            placeholder: '+31 (0) 85 0609 250',
            model: 'phone',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations.coc_number + "*",
            placeholder: '82327025',
            model: 'coc_number',
            min: 8,
            max: 8,
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'text',
            label: trx.organizations.vat_number + "*",
            placeholder: '',
            model: 'vat_id',
            required: true,
            validator: VueFormGenerator.validators.string
          },{
            type: 'input',
            inputType: 'file',
            label: 'Logo (Recommended: PNG, 100x70 pixels)',
            placeholder: '',
            model: 'temp_company_logo',
            required: false,
            attributes: {
              accept: 'image/png, image/jpeg, image/jpg' // Specify accepted file types
            },
            validator: (value) => {
              this.validationMessage = ''; // Reset message

              const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
              if (!allowedTypes.includes(value?.type)) {
                this.validationMessage = "Only PNG and JPEG files are allowed.";
                return false;
              }
              
              const maxSize = 100 * 1024; // 100 KB in bytes
              if (value.size > maxSize) {
                this.validationMessage = "File size exceeds the limit of 100 KB.";
                return false;
              }

              return new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                  if (img.width <= 100 && img.height <= 70) {
                    resolve(true);
                  } else {
                    this.validationMessage = "Image dimensions should be 100x70 pixels or smaller.";
                    resolve(false);
                  }
                };
                img.onerror = () => {
                  this.validationMessage = "Error loading image for validation.";
                  resolve(false);
                };
                img.src = URL.createObjectURL(value); // Start loading the image
              });
            }
          },{
            type: 'Submit',
            buttonText: this.submitNmae,
            onSubmit: this.submit,
            validateAfterChanged: true,
            validateBeforeSubmit: true
          }
        ]
      },
      formOptions: {
        // validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
   
</style>
