export default {
  "date_format": "GG-MM-AA",
  "date_time_format": "GG-MM-AAAA OO:MM",
  "time_format": "OO:MM",
  "delete": "Elimina",
  "continue": "Continua",
  "update": "Aggiorna",
  "Show preview": "Mostra anteprima",
  "Data preview": "Anteprima dati",
  "Select a file to start import": "Seleziona un file per iniziare l'importazione",
  "Total": "Totale",
  "Count": "Conteggio",
  "Sub total": "Subtotale",
  "Discount": "Sconto",
  "Add": "Aggiungi",
  "Import": "Importa",
  "Date": "Data",
  "Track & Trace": "Tracciamento",
  "Phone": "Telefono",
  "Click here your select an Excel file": "Clicca qui per selezionare un file Excel",
  "Something went wrong, please conduct your logs": "Qualcosa è andato storto, controlla i tuoi registri",
  "Sender": "Mittente",
  "Email": "Email",
  "Location": "Località",
  "Comment": "Commento",
  "Amount": "Quantità",
  "search": "Cerca",
  "expand": "Espandi",
  "condense": "Comprimi",
  "save": "Salva",
  "read": "Leggi",
  "pick_note": "Nota di prelievo",
  limit: "Limite",
  refresh: "Aggiorna",
  import: "Importa",
  "common": {
    "name": "Nom",
    "search_results": "Résultats de recherche",
    "select_date_time": "Sélectionner la date & l'heure"
  },
  "admin": {
    "name": "Impostazioni Admin",
    "search_orderid": {
      "name": "Cerca ID ordine"
    },
    "ship_boxes": {
      "name": "Scatole di spedizione & dimensioni",
      "max_size": "Dimensione massima (l,l,a)",
      "package": "Pacchetto",
      "product_size": "Dimensione prodotto",
      "package_cost": "Costi di imballaggio",
      "purchase_cost": "Costi di acquisto",
      "package_code": "Codice articolo",
      "fulfillment_cost": "Costi di evasione",
      "location": "Località",
      "create": "Crea un nuovo tipo di pacchetto"
    },
    "labels": {
      "name": "Etichette per la scansione"
    },
    "locations": {
      "name": "Impostazioni località"
    },
    "shipment_method": {
      "name": "Opzioni di spedizione",
      "code": "Codice",
      "price": "Prezzo",
      "purchase_price": "Prezzo di acquisto",
      "cut_off": "Termine ultimo",
      "late_drop_surcharge": "Sovrapprezzo per consegna serale",
      "peak_surcharge": "Sovrapprezzo per picco"
    },
    "surcharge": {
      "name": "Supplementi",
      "code": "Codice",
      "price": "Prezzo",
      "note": "Nota",
      "type": "Tipo"
    },
    "users": {
      "name": "Utenti & gruppi",
      "password_hint": "Minimo 9 caratteri"
    },
    "transfers": {
      "name": "Tutti i trasferimenti aperti"
    }
  },
  "settings": {
    "apis": "Integrazioni",
    "Settings": "Impostazioni",
    "Your organization": "La tua organizzazione",
    "Subscription": "Abbonamenti",
    "Users": "Utenti",
    "invoice": {
      "total_used_packaging": "Imballaggi",
      "total_used_shipment_method": "Francobolli postali",
      "total_used_surcharge": "Supplementi",
      "total_fulfillment": "Evasione",
      "total": "Totale",
      "download_details": "Scarica dettagli",
      "rack-box": "Posizione scaffale(i)",
      "half-box": "Posizione mezzo scaffale(i)",
      "warehousing": "Posizione magazzino(i)",
      "pallet": "Posizione pallet(i)",
      "1/2pallet": "Posizione mezzo pallet(i)",
      "1/3pallet": "Posizione terzo pallet(i)"
    }
  },
  "organizations": {
    "name": "Negozi",
    "organization": "Negozio",
    "Organization address": "Indirizzo del negozio",
    "description": "Una panoramica di tutti i tuoi negozi",
    "Organization name": "Nome del negozio",
    "Your organization details": "Dettagli del tuo negozio",
    "type": "Tipo di negozio",
    "about": "Informazioni",
    "coc_number": "Numero di Camera di Commercio",
    "vat_number": "Partita IVA",
    "surchrages": "Supplementi",
    "Contact Person": "Persona di contatto",
    "Contact Person Email": "Email della persona di contatto",
    "delete_org_help": "Zona pericolosa, elimina il tuo negozio",
    "Phone": "Telefono",
    "Branche": "Settore"
  },
  "users": {
    "Name": "Nome",
    "Email": "Email",
    "Login": "Accesso",
    "First name": "Nome",
    "Last name": "Cognome",
    "Password": "Password",
    "Phone": "Numero di telefono",
    "Licence": "Licenza",
    "Verified": "Verificato",
    "Signup": "Registrati",
    "Create a new user": "Crea un nuovo utente",
    "Users in your organization": "Membri del team nella tua organizzazione",
    "Import users": "Importa utenti",
    "Stakeholder Group": "Gruppo di stakeholder",
    "Already have an account, login": "Hai già un account? Accedi",
    "Signup to get started": "Registrati per iniziare",
    "Not a user? Signup!": "Non sei un utente? Registrati ora!",
    "reset_password": "Password dimenticata?",
    "reset": "Reimposta password",
    "Please enter your email": "Inserisci la tua email",
    "Know your password?, Login": "Conosci la tua password? Accedi!",
    "adress_hint":
      "Inserisci il tuo indirizzo, usa una virgola per separare il codice postale e il luogo"
  },
  "plans": {
    "name": "Piani"
  },
  "subscriptions": {
    "name": "Prezzi",
    "Standard": "Standard",
    "Discount for yearly payment": "Sconto per pagamento annuale",
    "Terms of Service": "Termini di servizio",
    "Your current subscription": "Il tuo abbonamento attuale",
    "I have read and accept the terms of condition":
      "Ho letto e accetto i termini e le condizioni.",
    "CSR Managers": "Manager CSR",
    "free_plan_help": "",
    "Stakeholders": "Parti interessate",
    "Select your plan": "Seleziona il tuo piano",
    "Enterprise": "Enterprise",
    "Start for free": "Inizia gratuitamente",
    "Price / order": "Prezzo per ordine",
    "Invoices": "Fatture",
    "description": "Mostra una panoramica di tutti gli abbonamenti disponibili",
    "new": "Nuovo abbonamento",
    "Pricing knowledge": "Conoscenza dei prezzi"
  },
  "shops": {
    "Create a new Shop": "Crea un nuovo negozio",
    "Shops": "Negozi"
  },
  "products": {
    "button_options_name": "Opzioni prodotto",
    "button_link_name": "Link prodotto",
    "Product": "Prodotto",
    "ProductName": "Nome prodotto",
    "Add inventory": "Segnala prodotti",
    "Inventory checkin": "Registrazione inventario",
    "Sync with bol.com": "Sincronizza con bol.com",
    "search_placeholder": "Cerca prodotto per nome o EAN",
    "Note": "Nota",
    "Return instruction": "Istruzione di ritorno",
    "sf_stock": "Inventario Smart",
    "total_stock": "Inventario totale",
    "lvb_stock": "Inventario LVB",
    "warehouse_stock": "Inventario magazzino",
    "import_your_products": "Importa qui i tuoi prodotti",
    "create": "Aggiungi nuovo prodotto",
    "note": "Nota",
    "ean": "EAN",
    "search": "Cerca prodotto per nome o EAN",
    "pick_note": "Nota di prelievo",
    "pack_note": "Nota di imballaggio",
    "customs": {
      "button_name": "Dogana",
      "settings": "Impostazioni doganali",
      "customs": "Dogana (Spedizioni fuori dall'UE)",
      "hs": "Codice HS",
      "value": "Valore in euro",
      "weight_kg": "Peso in chilogrammi",
    },
    "recount": {
      "button_name": "Riconteggio",
      "recounting": "Ricontando",
      "settings": "Impostazioni di riconteggio"
    },
    "import": {
      "add_product": "⥊ Aggiungi prodotto",
      "xlsx": "⥊ Excel",
      "barcode_sku_ean": "EAN / SKU / codice a barre",
      "Product_name": "Prodotto",
      "fullfilled_bysf": "On/Off",
      "length": "Lunghezza",
      "height": "Altezza",
      "width": "Larghezza",
      "weight_kg": "Peso (kg)"
    },
    "sync": {
      "bol": "⥊ Bol.com",
      "woo": "⥊ WooCommerce",
      "shopify": "⥊ Shopify",
      "mirakl": "⥊ Mirakl",
      "lightspeed": "⥊ Lightspeed",
      shopware: "⥊ Shopware",
      "excel": "⥊",

      "help": "Usa questi pulsanti per importare prodotti che hai pubblicato su diverse piattaforme.",
      "import_products": "Importa"
    }
  },
  "inventory": {
    "Pre-announced": "Pre-annunciato",
    "Box": "Scatola",
    "Boxes": "Scatole",
    "Pallet": "Pallet",
    "Pallets": "Pallet"
  },
  "returns": {
    "name": "Resi",
    "return_number_or_barcode": "Numero di reso o codice a barre",
    "scan_help_text": "Inserisci un numero di reso o scansiona un codice a barre",
    "location": "Località",
    "product": "Prodotto",
    "return_id": "Numero di reso",
    "return_instruction": "Istruzione di reso",
    "damage": "Danno",
    "damaged_package": "Pacchetto danneggiato",
    "damaged_product": "Prodotto danneggiato",
    "damaged_product_package": "Danno alla confezione del prodotto",
    "registered": "Data di registrazione",
    "product_state": "Condizione del prodotto",
    "reason": "Motivo di reso cliente/consumatore",
    "customer_details": "Dettagli del consumatore",
    "no_returns_to_show": "Nessun reso da mostrare",
    "handel_return_close": "Completa e chiudi",
    "RETURN_RECEIVED": "Reso ricevuto",
    "EXCHANGE_PRODUCT": "Scambia prodotto",
    "RETURN_DOES_NOT_MEET_CONDITIONS": "Il reso non soddisfa le condizioni",
    "REPAIR_PRODUCT": "Ripara reso",
    "CUSTOMER_KEEPS_PRODUCT_PAID": "Il cliente mantiene il prodotto",
    "STILL_APPROVED": "ANCORA APPROVATO",
    "product_excellent": "Il prodotto sembra ordinato e può essere rivenduto",
    "product_good": "Il prodotto è abbastanza ok, può tornare sullo scaffale",
    "product_bad_state": "Prodotto/confezione danneggiata non può essere venduto"
  },
  "orders": {
    "Order ID": "ID Ordine",
    "order": "Ordine",
    "Date": "Data",
    "Add order": "Aggiungi ordine",
    "Your orders": "I tuoi ordini",
    "Orders": "Ordini",
    "Status": "Stato",
    "Create": "Crea nuovo ordine",
    "Shipments": "Spedito",
    "Products": "Prodotti",
    "Receiver": "Ricevente",
    "name": "Nome",
    "surename": "Cognome",
    "streetname": "Nome della strada",
    "housenumber": "Numero civico",
    "addition": "Aggiunta",
    "postalcode": "Codice postale",
    "place": "Luogo",
    "city": "Città",
    "country": "Paese",
    "company": "Nome della ditta",
    "Sync with bol.com": "Sincronizza con bol.com",
    "Send products to us": "Invia prodotti a noi",
    "EAN": "EAN",
    "Delivery time": "Tempo di consegna",
    "Own stock": "Scorta personale",
    "SF stock": "Scorta SF",
    "Stock": "Scorta",
    "Fulfil by SF": "Elaborato da SF",
    "Fulfilled by BOL (FBB)": "Elaborato da BOL (FBB)",
    "FBB": "FBB",
    "SF": "SF",
    "Add inventory": "Aggiungi inventario",
    "Send your products to us": "Invia i tuoi prodotti a noi",
    "Default shipment": "Metodo di spedizione predefinito",
    "Location": "Località",
    "Box size": "Dimensione della scatola",
    "email": "Email",
    "phone": "Telefono",
    "Select products you want to send": "Seleziona i prodotti che vuoi inviare",
    "Wrong product": "Prodotto sbagliato",
    "Wrong quantity": "Quantità sbagliata",
    "Damaged product": "Prodotto danneggiato",
    "Webshop order": "Ordine dal negozio online",
    "order_comments": "Note sull'ordine",
    "import_your_orders": "Importa qui i tuoi ordini",
    "shipping_info": "Dettagli di spedizione",
    "confirmation": "Conferma",
    "product_selector_text": "Seleziona i prodotti che vuoi inviare",
    "products_in_order": "Prodotti in questo ordine",
    "to_sending": "Ai dettagli di spedizione",
    "to_confirm": "Per confermare",
    "to_create": "Conferma e invia",
    "shipping_method": "Metodo di spedizione",
    "attachment": "Allegato (solo PDF)",
    "attachment_placeholder": "Istruzioni su come gestire l'allegato",
    "send": "Ordine creato",
    "import": {
      "order_id": "ID Ordine",
      "product_name": "Prodotto",
      "quantity": "Quantità",
      "city": "Città",
      "countryCode": "Codice paese",
      "email": "Email",
      "firstName": "Nome",
      "surName": "Cognome",
      "houseNumber": "Numero civico",
      "houseNumberExtension": "Estensione del numero civico",
      "language": "Lingua",
      "salutation": "Saluto",
      "streetName": "Nome della strada",
      "zipcode": "Codice postale",
      "deliveryPhoneNumber": "Numero di telefono",
      "reason": "Motivo",
      "notes_text": "Nota",
      "notes_color": "Colore della nota",
      "company": "Azienda"
    },
    "manual_send": {
      "reason": "Motivo dell'ordine manuale",
      "received": "Ordine non ricevuto da PostNL",
      "incorrect": "Prodotto sbagliato",
      "quantity": "Quantità sbagliata",
      "damaged": "Prodotto danneggiato",
      "webshop": "Ordine dal negozio online"
    }
  },
  "pick_list": {
    "name": "Lista di prelievo",
    "qty": "Quantità"
  },
  "shipments": {
    "sent_orders": "Ordini inviati",
    "24h": "24 ore",
    "> 2 days": "> 2 giorni",
    "on_the_way": "In viaggio",
    "not_sorted": "Non ordinato",
    "ordered_by": "Ordinato da",
    "order_date": "Data dell'ordine",
    "sent_date": "Data di spedizione",
    "delivery_date": "Data di consegna",
    "shipment_method": "Metodo di spedizione",
    "day_delivered": "< 24 ore consegnate",
    "two_day_delivered": "< 48 ore consegnate",
    "search_placeholder": "Cerca per nome, ordine o CAP",
    "Shipment Address": "Indirizzo di spedizione",
    "Amount": "Quantità",
    "Ready for shipment": "Pronto per la spedizione",
    "Packed": "Imballato",
    "Shipped": "Spedito",
    "Shipment options": "Opzioni di spedizione",
    "Open": "Aperto",
    "Pallets": "Pallet",
    "Coli": "Colli/Scatole",
    "Type": "Tipo",
    "remark": "Nota"
  },
  "checkinv": {
    "created_at": "Spedito",
    "expected_delivery": "Previsto",
    "status": "Stato",
    "checked_in_date": "Registrato",
    "product_name": "Prodotto",
    "product_ean": "Ean/SKU",
    "expected": "Previsto",
    "final_check_in": "Registrato",
    "comment": "Commento",
    "pallets": "Pallet",
    "pallet": "Pallet",
    "box": "Scatola",
    "boxes": "Scatole",
    "change_checkin": "Visualizza/modifica registrazione",
    "search": "Cerca registrazione per nome",
    "edit_checkin": "Visualizza/modifica registrazione",
    "add_checkin": {
      "overview": "Panoramica",
      "out_of_stock": "Esaurito",
      "application": "Applicazione",
      "reason": "Cosa vuoi fare?",
      "stock_checkin": "Registrazione magazzino",
      "stock_checkin_info": "Clicca qui per informarci sulle tue spedizioni pianificate, così possiamo prepararci ottimalmente per la ricezione e la gestione accurata dei tuoi beni.",
      "change_checkin": "Visualizza/modifica registrazione",
      "change_checkin_info": "Visualizza o modifica le tue spedizioni precedentemente riportate per mantenere aggiornati i dettagli e garantire un'esperienza logistica senza interruzioni.",
      "type": "Tipo",
      "products": "Prodotti",
      data: "fatti",
      "confirmation": "Conferma"
    },
    "type": {
      "package": "Spedizione pacchetto",
      "package_text": "Scegli questa opzione per registrare articoli più piccoli, imballati singolarmente. Consegnato da PostNL, UPS, DpD o altri servizi di corriere",
      "pallet": "Spedizione pallet",
      "pallet_text": "Seleziona questa opzione per spedizioni più grandi su pallet per un trasporto organizzato.",
      "container_other": "Spedizione container / altri",
      "container_other_text": "Per la registrazione di spedizioni in contenitori o formati non standard."
    },
    "checkin_products": {
      "choose_products_in_box": "Scegli il prodotto che si trova in questa scatola",
      "no_products": "Non hai ancora prodotti nel nostro sistema, fai questo prima",
      "search_products": "Cerca prodotto per nome o EAN"
    },
    "checkin_product": {
      "unit_amount": "Numero di unità (scatole)*",
      "amount": "Quantità",
      "box_amount": "Quantità nella scatola*",
      "total_amount": "Numero totale di pezzi (CE)",
      "expiration_date": "Data di scadenza",
      "batch_number": "Numero di lotto",
      "add": "Aggiungi",
      "divergent": "* Una scatola è diversa? Completa questo passaggio con le scatole corrispondenti e poi aggiungi lo stesso prodotto ancora una volta, nel passaggio precedente.",
      "check": "Controlla se i tuoi prodotti sono impostati correttamente",
      "back": "Indietro",
      "totals": "Totali",
      "per_box": "Prodotti per scatola",
      "boxes": "Scatole",
      "submit_checkin": "Conferma registrazione"
    },
    "checkin_success": {
      "shipment_instructions": "Istruzioni di spedizione:",
      "documents_sent": "I documenti sono stati inviati via email. Puoi anche scaricarli direttamente da qui",
      "checkin_step1": "Scarica il documento di imballaggio e mettilo nel pacco",
      "checkin_step2": "Poi scarica il codice QR e attaccalo all'esterno del pacco",
      "checkin_important": "Importante: questo codice QR registrerà il tuo pacco. Se manca, il processo di registrazione sarà ritardato."
    },
    "checkin_information": {
      "type": "Indica dove si trovano i prodotti",
      "products": "Scegli uno per uno i prodotti che vuoi aggiungere",
      "information": "Compila le informazioni qui sotto per completare la registrazione"
    },
    "checkin_confirmation": {
      "contact_person_label": "Persona di contatto",
      "contact_person_placeholder": "Persona di contatto",
      "contact_person_email_label": "Email della persona di contatto",
      "contact_person_email_placeholder": "Email della persona di contatto",
      "phone_label": "Numero di telefono",
      "phone_placeholder": "Telefono",
      "shipping_address_label": "Indirizzo di spedizione",
      "shipping_address_placeholder": "Indirizzo di spedizione",
      "track_trace_label": "Tracciamento",
      "track_trace_placeholder": "Tracciamento",
      "expected_delivery_label": "Consegna prevista",
      "expected_delivery_placeholder": "Consegna prevista",
      "button_submit": "Crea registrazione"
    },
    "checkin_done": {
      "confirmation_message": "La tua registrazione è stata ricevuta!",
      "email_instructions": "I documenti sono stati inviati via email. Puoi anche scaricarli direttamente da qui.",
      "shipping_instructions_title": "Istruzioni di spedizione:",
      "shipping_instruction_1": "Scarica il documento di imballaggio e mettilo con il pacco / fai in modo che sia messo con il pacco.",
      "shipping_instruction_2": "Poi scarica il codice QR e attaccalo all'esterno della scatola.",
      "important": "Importante:",
      "important_note": "Questo codice QR registrerà il tuo pacco. Se manca, la tua spedizione non sarà accettata e sarà restituita al fornitore.",
      "view_information_button": "Visualizza informazioni",
      "documents_per_product": "Documenti per prodotto",
      "box_label": "Scatola",
      "packing_slip_label": "Documento di imballaggio",
      "qr_code_label": "Codice QR",
      "download_all_documents_button": "Scarica tutti i documenti",
      "processing_shipment": "Stiamo elaborando la tua registrazione. Rimani in questa pagina!"
    },
  },
  "inbound_details": {
    "delivery": {
      "delivery": "Consegna",
      "expected": "Previsto",
      "delivered": "Consegnato",
      "organisation": "Organizzazione",
      "contactInformation": "Informazioni di contatto",
      "units": "Unità",
      "packedPer": "Imballato per",
      "transit": "In transito"
    },
    "location": {
      "location": "Località",
      "expirationDate": "Data di scadenza",
      "isPackageClosed": "Il pacco è arrivato chiuso"
    },
    "extraWork": "Lavori aggiuntivi & supplementi",
    "note": "Note"
  },
  "history_locations": {
    "button_name": "Località storiche",
    "name": "Località storiche del prodotto",
    "none": "Nessuna località storica trovata"
  },
  "stock_analysis": {
    "button_name": "Analisi delle scorte",
    "analysis": "Analisi",
    "checked_in": "Registrato",
    "date": "Data",
    "amount": "Quantità",
    "retours": "Resi",
    "period": "Periodo",
    "orders": "Ordini",
    "logical_stock": "Scorta logica"
  },
  "automations": {
    "button_name": "Automazioni"
  },
  "validation_trx": {
    "fieldIsRequired": "Questo campo è obbligatorio!",
    "invalidFormat": "Formato non valido!",
    "numberTooSmall": "Il numero è troppo piccolo! Minimo: {0}",
    "numberTooBig": "Il numero è troppo grande! Massimo: {0}",
    "invalidNumber": "Numero non valido",
    "invalidInteger": "Il valore non è un intero",
    "textTooSmall": "La lunghezza del testo è troppo piccola! Attuale: {0}, Minimo: {1}",
    "textTooBig": "La lunghezza del testo è troppo grande! Attuale: {0}, Massimo: {1}",
    "thisNotText": "Questo non è testo!",
    "thisNotArray": "Questo non è un array!",
    "selectMinItems": "Seleziona almeno {0} elementi!",
    "selectMaxItems": "Seleziona al massimo {0} elementi!",
    "invalidDate": "Data non valida!",
    "dateIsEarly": "La data è troppo presto! Attuale: {0}, Minimo: {1}",
    "dateIsLate": "La data è troppo tardi! Attuale: {0}, Massimo: {1}",
    "invalidEmail": "Indirizzo email non valido!",
    "invalidURL": "URL non valido!",
    "invalidCard": "Formato della carta non valido!",
    "invalidCardNumber": "Numero della carta non valido!",
    "invalidTextContainNumber": "Testo non valido! Non deve contenere numeri o caratteri speciali",
    "invalidTextContainSpec": "Testo non valido! Non deve contenere caratteri speciali",
    "add_product_name": "Aggiungi nome prodotto",
    "add_ean": "Aggiungi EAN",
    "invalid_product_items": "Aggiungi almeno un prodotto",
    "invalid_streetname": "Aggiungi nome della strada",
    "invalid_housenumber": "Aggiungi numero civico",
    "invalid_postcode": "Aggiungi codice postale",


    "invalid_country": "Aggiungi paese",
    "invalid_ship_option": "Scegli un'opzione di spedizione",
    "invalid_type": "Scegli perché stai creando questo ordine",
    "invalid_email": "Aggiungi email",
    "invalid_EAN_input": "EAN non valido, deve contenere 13 cifre",
    "invalid_phone": "Aggiungi numero di telefono",
    "invalid_firstname": "Aggiungi nome",
    "invalid_surname": "Aggiungi cognome",
    "invalid_company": "Aggiungi nome azienda",
    "invalid_city": "Aggiungi città",
    "invalid_hs": "Aggiungi un Codice HS",
    "invalid_value": "Aggiungi un valore",
    "invalid_weight": "Aggiungi un peso",
    "invalid_shipment_method": "Scegli un'opzione di spedizione",
    "invalid_attachment_text": "Aggiungi una nota al tuo allegato",
    "invalid_attachment_files": "Aggiungi un allegato se hai istruzioni"
  }
}