<template>
  <div class="organization">
        <h3>{{trx.settings.apis}}</h3>

        <div class="apis flex">
          <div class="apis__bol flex-50">
            <h5> Bol.com</h5>
            key
            <input autocomplete="nope" v-model="current_org.apis.bol.key" v-on:change="editApis(current_org)" />
            secret
            <input autocomplete="nope" class="sec"  v-model="current_org.apis.bol.secret" v-on:change="editApis(current_org)" />
            <a class="btn" href="https://partnerplatform.bol.com/nl/hulp-nodig/automatiseren/hoe-krijg-ik-toegang-tot-de-api/">Waar vindt ik mijn bol.com API keys?</a>
          </div>
          <div class="apis__bol flex-50">
            <h5> Bol.com BE</h5>
            key
            <input autocomplete="none" v-model="current_org.apis.bol_be.key" v-on:change="editApis(current_org)" />
            secret
            <input autocomplete="none" class="sec" v-model="current_org.apis.bol_be.secret" v-on:change="editApis(current_org)" />
            <a class="btn" href="https://partnerplatform.bol.com/nl/hulp-nodig/automatiseren/hoe-krijg-ik-toegang-tot-de-api/">Waar vindt ik mijn bol.com API keys?</a>
          </div>
          
         
        </div>  
        <br> 
        <div class="apis flex">
          <div class="apis__woo flex-50">
            <h5>WooCommerce</h5>
            url
            <input autocomplete="none" v-model="current_org.apis.woo.url" v-on:change="editApis(current_org)" />
            key
            <input autocomplete="none" v-model="current_org.apis.woo.key" v-on:change="editApis(current_org)" />
            secret
            <input autocomplete="none" class="sec"  v-model="current_org.apis.woo.secret" v-on:change="editApis(current_org)" />
            <a class="btn" href="https://calendly.com/harut-smf/smf-integration">Maak een integratie afspraak</a>
          </div>
          <div class="apis__woo flex-50">
            <h5>Shopify</h5>
            url
            <input autocomplete="off" v-model="current_org.apis.shopify.url" v-on:change="editApis(current_org)" />
            key
            <input autocomplete="off" v-model="current_org.apis.shopify.key" v-on:change="editApis(current_org)" />
            secret
            <input autocomplete="off" v-model="current_org.apis.shopify.secret" v-on:change="editApis(current_org)" />
            <a class="btn" href="https://calendly.com/harut-smf/smf-integration">Maak een integratie afspraak</a>
          </div>
         
        </div>   
        <br>
        <div class="apis flex">
            <div class="apis__woo flex-50">
              <h5>Mirakl</h5>
              url
              <input autocomplete="off" v-model="current_org.apis.mirakl.url" v-on:change="editApis(current_org)" />
              key
              <input autocomplete="off" v-model="current_org.apis.mirakl.key" v-on:change="editApis(current_org)" />
              
            </div>
            <div class="apis__woo flex-50">
              <h5>Lightspeed</h5>
              url
              <input autocomplete="off" v-model="current_org.apis.lightspeed.url" v-on:change="editApis(current_org)" />
              key
              <input autocomplete="off" v-model="current_org.apis.lightspeed.key" v-on:change="editApis(current_org)" />
              secret
              <input autocomplete="off" v-model="current_org.apis.lightspeed.secret" v-on:change="editApis(current_org)" />
              Shop language
              <input autocomplete="off" v-model="current_org.apis.lightspeed.shop_language" v-on:change="editApis(current_org)" />
              
            </div>
        </div>

        <h3>{{trx.settings.apis}} - (BETA)</h3>
        <div class="table_controles">
            <div class="table_controles__btns">
                <router-link v-if="!$route.path.includes('add')" :to="{path:'/settings/apis/add'}" class="button">
                        + {{trx["Add"]}}
                </router-link>
            </div>
            
            <div class="add_container" v-if="$route.path.includes('add') > 0" >
                <router-view ></router-view>
            </div>
        </div> 
       

        <div class=" ">
          <div class="cus_apis__conn">
              <div v-for="a in apis" v-bind:key="a._id" class="cus_apis__item flex-wrap flex">
                  <div class="cus_apis__name flex-100">
                    <h3>{{ a.api_name }}</h3>
                  </div>
                  <div class="cus_apis__secret flex-15">
                    <label>Platform</label>
                    <select>
                      <option :value="a.platform">{{ a.platform }}</option>
                    </select>
                  </div>
                  <div class="cus_apis__secret flex-25">
                    <label>Url</label>
                    <input :value="a.url" type="input" autocomplete="off">
                  </div>
                  <div class="cus_apis__key flex-20" v-if="a.platform !== 'Mirakl'">
                    Api key
                    <input :value="a.api_key" type="input" autocomplete="off">
                  </div>
                  <div class="cus_apis__secret flex-20">
                    <label>Secret</label>
                    <div class="cus_apis__secret--pass">
                      <input :value="a.encrypted_secret" :type="showPassword == false ? 'password' : 'textarea'" autocomplete="off">
                      <span @click="showPassword = !showPassword;"> <svg :class="showPassword ? '' : '' "  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg></span>
                     
                    </div>
                    
                  </div>
                  <div class="cus_apis__secret flex-10">
                    <label>Platform ID</label>
                    <input :value="a.platform_id" type="input" autocomplete="off">
                  </div>
                  <div class="cus_apis__secret flex-10">
                    <label>Language</label>
                    <input :value="a.platform_lang" type="input" autocomplete="off">
                  </div>
                  <div class="cus_apis__secret flex-10">
                    <label for="send_checkbox">Send invoice</label>
                    <input name="send_checkbox" :value="a.send_invoice" @change="update_api_invoice({id: a._id, event: $event})" type="checkbox" autocomplete="off">
                  </div>
              </div>
          </div>
        </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import trx from '@/translations';
/* eslint-disable no-debugger */

export default {
  name: 'Shops',
  methods: {
    ...mapActions(['fetch_curr_org_details', 'createOrg', 'editApis', 'get_apis', 'update_api_invoice']),
  },
  computed: {
    ...mapGetters(['current_org', 'apis']),
  },
  components: {
    
  },
  created() {
    this.fetch_curr_org_details();
    this.get_apis();
  },
  data () {
    return {
      trx,
      showPassword: false,
      showPassInfo: false,
      model: {
            name: '',
            address: {
                line1: ""
            }, 
            contact_person: '',
            contact_person_email: '',
            phone: "",
            branche: "",
            about: "",
            apis: {
              bol: {
                key: "",
                secret: ""
              },
              woo: {
                url: "",
                key: "",
                secret: ""
              }
            }

        },
    }
  }
}
</script>

<style lang="scss" scoped>
   h3 {
     margin-bottom: 5rem;
   }

   input.sec {
    input-security: square;
    -webkit-text-security: square;
   }

   .apis {
     >div {
       @include box-bg-white;
       margin-right: 2rem;
       min-height: 30rem;
     }
   }

   .cus_apis {
      &__conn {
        margin-top: 5rem;
        >div {
          @include box-bg-white;
          margin-right: 2rem;
          >div {
            padding: 1rem;
            border-bottom: 1px solid var(--color-grey);
            align-items: center;
          }
        }
      }
      &__name {
        align-items: center;
        display: flex;
        h3 {
          margin-bottom: 0;
        }
      }
      &__secret {
        &--pass {
          position: relative;
          input {
            padding-right: 4rem; 
          }
          span {
            position: absolute;
            z-index: 1;
            cursor: pointer;
            top: 12px;
            right: 5px;
          }
          &--info {
            position: absolute;
            z-index: 1;
            cursor: pointer;
            top: -60px;
            right: 5px;
            svg {
              width: 2.5rem;
            }
          }
        }
      }
   } 

   
    
  
   
</style>
