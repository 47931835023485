export default {
  date_format: "DD-MM-YY",
  date_time_format: "DD-MM-YYY HH:MM",
  time_format: "HH:MM",
  delete: "Verwijderen",
  continue: "Doorgaan",
  update: "Update",
  "Show preview": "Toon voorbeeld",
  "Data preview": "Data voorbeeld",
  "Select a file to start import":"Selecteer een bestand om het importeren te starten",
  Total: "Totaal",
  Count: "Aantal",
  "Sub total": "Sub totaal",
  Discount: "Korting",
  Add: "Toevoegen",
  Import: "Import",
  Date: "Datum",
  "Track & Trace": "Track & Trace",
  Phone: "Telefoon",
  "Click here your select an Excel file":
    "Klik hier om een excel bestand te selecteren",
  "Something went wrong, please conduct your logs":
    "Er gaat iets mis, bekijk uw logbestanden",
  Sender: "Afzender",
  Email: "Email",
  Location: "Locatie",
  Comment: "Opmerking",
  Amount: "Aantal",
  search: "Zoeken",
  expand: "Uitvouwen",
  condense: "Invouwen",
  save: "Opslaan",
  read: "Gelezen",
  limit: "Limiet",
  refresh: "Verversen",
  import: "Importeren",
  pick_note: "Pick notitie",
  common: {
    name: "Naam",
    search_results: "Zoekresultaten",
    select_date_time: "Selecteer datum & tijd",
  },
  admin: {
    name: "Admin instellingen",
    search_orderid: {
      name: "Zoeken naar order id",
    },
    ship_boxes: {
      name: "Verzenddozen & afmetingen",
      max_size: "Maximale afmeting (l,x,h)",
      package: "Verpakking",
      product_size: "Product formaat",
      package_cost: "Verpakking-kosten",
      purchase_cost: "Inkoop kosten",
      package_code: "Artikelnummer",
      fulfillment_cost: "Fulfilment kosten",
      location: "Locatie",
      create: "Maak een nieuwe verpakking-type",
    },
    labels: {
      name: "Labels voor scannen",
    },
    locations: {
      name: "Locatie instellingen",
    },
    shipment_method: {
      name: "Verzendopties",
      code: "Code",
      price: "Prijs",
      purchase_price: "Inkoop prijs",
      cut_off: "Cut-off",
      late_drop_surcharge: "Avondtoeslag",
      peak_surcharge: "Piektoeslag",
    },
    surcharge: {
      name: "Toeslagen",
      code: "Code",
      price: "Prijs",
      note: "Notitie",
      type: "Type",
    },
    users: {
      name: "Gebruikers & groepen",
      password_hint: "Minimaal 9 tekens",
    },
    transfers: {
      name: "Alle open transfers",
    }
  },
  settings: {
    apis: "Koppelingen",
    Settings: "Instellingen",
    "Your organization": "Jouw winkel",
    Subscription: "Abonnementen",
    Users: "Gebruikers",
    invoice: {
      total_used_packaging: "Verpakkingen",
      total_used_shipment_method: "Verzendzegels",
      total_used_surcharge: "Toeslagen",
      total_fulfilment: "Fulfilment",
      total: "Totaal",
      download_details: "Specificatie downloaden",
      "rack-box": "Schap locatie(s)",
      "half-box": "Halve schap locatie(s)",
      warehousing: "Warehouse locatie(s)",
      pallet: "Pallet locatie(s)",
      "1/2pallet": "Halve pallet locatie(s)",
      "1/3pallet": "Derde pallet locatie(s)",
    },
  },
  organizations: {
    name: "Winkels",
    organization: "Winkel",
    "Organization address": "Vestiging adres",
    description: "Een overzicht van al jouw winkels",
    "Organization name": "Winkelnaam",
    "Your organization details": "Gegevens van je winkel",
    type: "Winkel type",
    about: "Over",
    coc_number: "KVK nummer",
    vat_number: "Btw nummer",
    surcharges: "Toeslagen",
    "Contact Person": "Contact persoon",
    "Contact Person Email": "Contact persoon email",
    delete_org_help: "Gevaarlijke zone, je winkel verwijderen",
    Phone: "Telefoon",
    Branche: "Sector",
  },
  users: {
    Name: "Naam",
    Email: "Email",
    Login: "Inloggen",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    Password: "Wachtwoord",
    Phone: "Telefoonnummer",
    Licence: "Licentie",
    Verified: "Verified",
    Signup: "Aanmelden",
    "Create a new user": "Maak een nieuwe gebruiker aan",
    "Users in your organization": "Teamleden in jouw winkel",
    "Import users": "Gebruikers importeren",
    "Stakeholder Group": "Stakeholder Group",
    "Already have an account, login": "Heb je al een account? Login",
    "Signup to get started": "Meld je aan om te starten",
    "Not a user? Signup!": "Heb je geen account? Meld je nu aan!",
    reset_password: "Wachtwoord vergeten?",
    reset: "Wachtwoord herstellen",
    "Please enter your email": "Vul uw email in",
    "Know your password?, Login": "Heb je al een wachtwoord? Login!",
    adress_hint:
      "Vul je adres in, gebruik een comma voor het scheiden van postcode en plaats",
  },
  plans: {
    name: "Plans",
  },
  subscriptions: {
    name: "Prijzen",
    Standard: "Standaard",
    "Discount for yearly payment": "Korting voor jaarlijkse betaling",
    "Terms of Service": "Voorwaarden",
    "Your current subscription": "Uw huidige abonnement",
    "I have read and accept the terms of condition":
      "Ik heb de voorwaarden gelezen en ben hiermee akkoord.",
    "CSR Managers": "CSR Managers",
    free_plan_help: "",
    Stakeholders: "Stakeholders",
    "Select your plan": "Selecteer uw abonnement",
    Enterprise: "Enterprise",
    "Start for free": "Start gratis",
    "Price / order": "Prijs per order",
    Invoices: "Facturen",
    description: "Toont een overzicht van alle beschikbare abonnementen",
    new: "New subscription",
    "Pricing knowledge": "Kennisgeving prijzen",
  },
  shops: {
    "Create a new Shop": "Maak een nieuwe winkel aan",
    Shops: "Winkels",
  },
  products: {
    button_options_name: "Product opties",
    button_link_name: "Product links",
    Product: "Product",
    search_placeholder: "Zoek product op naam of EAN",
    ProductName: "Productnaam",
    "Add inventory": "Voormelden",
    "Inventory checkin": "Check-in",
    "Sync with bol.com": "Sync met bol.com",
    Note: "Notitie",
    "Return instruction": "Retour instructie",
    sf_stock: "Voorraad Smart",
    total_stock: "Totale voorraad",
    lvb_stock: "LVB voorraad",
    warehouse_stock: "Voorraad Warehouse",
    import_your_products: "Importeer hier jouw producten",
    create: "Nieuw product toevoegen",
    note: "Opmerking",
    ean: "EAN",
    search: "Zoek product op naam of EAN",
    pick_note: "Pick notitie",
    pack_note: "Pack notitie",
    total_sold_30d: "Verkocht 30 dagen",
    customs: {
      button_name: "Douane",
      settings: "Douane instellingen",
      customs: "Douane (Zendingen buiten EU)",
      hs: "HS Code",
      value: "Waarde in euro's",
      weight_kg: "Gewicht in kilo's",
    },
    recount: {
      button_name: "Hertellen",
      recounting: "Hertellen",
      settings: "Hertelling instellingen",
    },
    import: {
      add_product: "⥊ Product toevoegen",
      xlsx: "⥊ Excel",
      barcode_sku_ean: "EAN / SKU / barcode",
      Product_name: "Product",
      fullfilled_bysf: "Aan/Uit",
      length: "Lengte",
      height: "Hoogte",
      width: "Breedte",
      weight_kg: "Gewicht (kg)",
    },
    sync: {
      bol: "⥊ Bol.com",
      woo: "⥊ WooCommerce",
      shopify: "⥊ Shopify",
      mirakl: "⥊ Mirakl",
      lightspeed: "⥊ Lightspeed",
      shopware: "⥊ Shopware",
      excel: "⥊ Excel",
      help: "Met deze knoppen kun je producten importeren die je hebt gepubliceerd op verschillende platformen.",
      import_products: "Importeren",
    },
  },
  inventory: {
    "Pre-announced": "Voorgemeld",
    delivered: "Geleverd",
    Box: "Doos",
    Boxes: "Dozen",
    Pallet: "Pallet",
    Pallets: "Pallets",
  },
  returns: {
    name: "Retours",
    return_number_or_barcode: "Retournummer of barcode",
    scan_help_text: "Voer een retour nummer in of scan een barcode",
    location: "Locatie",
    product: "Product",
    return_id: "Retournummer",
    return_instruction: "Retour instructie",
    damage: "Schade",
    damaged_package: "Schade aan verpakking",
    damaged_product: "Schade op product",
    damaged_product_package: "Schade aan product verpakking",
    registered: "Registratie datum",
    product_state: "Product status",
    reason: "Retourreden klant/consument",
    customer_details: "Consument details",
    no_returns_to_show: "Er zijn geen retouren voor weergave",
    handel_return_close: "Afronden & sluiten",
    RETURN_RECEIVED: "Retour ontvangen",
    EXCHANGE_PRODUCT: "Product ruilen",
    RETURN_DOES_NOT_MEET_CONDITIONS: "Retour voldoet niet aan voorwaarden",
    REPAIR_PRODUCT: "Retour repareren",
    CUSTOMER_KEEPS_PRODUCT_PAID: "Klant wil het product houden",
    STILL_APPROVED: "STILL_APPROVED",
    product_excellent: "Product ziet er netjes uit en kan weer verkocht worden",
    product_good: "Product redelijk ok, kan weer terug in schap",
    product_bad_state: "Product/verpakking beschadigd kan niet verkocht worden",
  },
  orders: {
    "Order ID": "Order ID",
    order: "Order",
    multi_orders: "Multi orders",
    warning_orders: "Waarschuwing orders",
    Date: "Datum",
    "Add order": "Nieuwe order",
    "Your orders": "Jouw bestellingen",
    Orders: "Bestellingen",
    Status: "Status",
    Create: "Nieuwe order plaatsen",
    Shipments: "Verzonden",
    Products: "Producten",
    Receiver: "Ontvanger",
    name: "Naam",
    surename: "Achternaam",
    streetname: "Straatnaam",
    housenumber: "Huisnummer",
    addition: "Toevoeging",
    postalcode: "Postcode",
    place: "Plaats",
    city: "Stad",
    country: "Land",
    company: "Bedrijfsnaam",
    "Sync with bol.com": "Sync met bol.com",
    "Send products to us": "Verzend producten aan ons",
    EAN: "EAN",
    "Delivery time": "Levertijd",
    "Own stock": "Eigen voorraad",
    "SF stock": "Onze voorraad",
    Stock: "Voorraad",
    "Fulfil by SF": "Verwerk door SF",
    "Fulfilled by BOL (FBB)": "Fulfilled by BOL (FBB)",
    FBB: "FBB",
    SF: "SF",
    "Add inventory": "Voorraad toevoegen",
    "Send your products to us": "Verzend jouw producten naar ons",
    "Default shipment": "Standaard verzendwijze",
    Location: "Locatie",
    "Box size": "Doos afmeting",
    email: "Email",
    phone: "Telefoon",
    "Select products you want to send":
      "Selecteer de producten die je wilt verzenden",
    "Wrong product": "Verkeerd product",
    "Wrong quantity": "Verkeerde hoeveelheid",
    "Damaged product": "Beschadigd product",
    "Webshop order": "Webshop bestelling",
    order_comments: "Order notities",
    import_your_orders: "Importeer hier jouw orders",
    shipping_info: "Verzendgegevens",
    confirmation: "Bevestiging",
    product_selector_text: "Selecteer de producten die je wilt verzenden",
    products_in_order: "Producten in deze order",
    to_sending: "Naar verzendgegevens",
    to_confirm: "Naar bevestigen",
    to_create: "Bevestigen en sturen",
    shipping_method: "Verzendmethode",
    attachment: "Bijlage (Alleen PDF)",
    attachment_placeholder: "Instructies wat er met de bijlage gedaan moet worden",
    send: "Order aangemaakt",
    import: {
      order_id: "Order ID",
      product_name: "Product",
      quantity: "Aantal",
      city: "stad",
      countryCode: "Landcode",
      email: "Email",
      firstName: "Voornaam",
      surName: "Achternaam",
      houseNumber: "Huisnummer",
      houseNumberExtension: "Extensie Huisnummer",
      language: "Taal",
      salutation: "Salutatie",
      streetName: "Straatnaam",
      zipcode: "Postcode",
      deliveryPhoneNumber: "Telefoonnummer",
      reason: "Reden",
      notes_text: "Notitie",
      notes_color: "Notitiekleur",
      company: "Bedrijf"
    },
    manual_send: {
      reason: "Reden handmatige order",
      received: "Order niet ontvangen door PostNL",
      incorrect: "Verkeerd product",
      quantity: "Verkeerde hoeveelheid",
      damaged: "Beschadigd product",
      webshop: "Webshop bestelling",
    }
  },
  pick_list: {
    name: "Picklijst",
    qty: "Aantal stuks",
  },
  shipments: {
    sent_orders: "Verzonden orders",
    "24h": "24 uur",
    "> 2 days": "> 2 dagen",
    on_the_way: "Onderweg",
    not_sorted: "Niet gesorteerd",
    orderd_by: "Besteld door",
    order_date: "Besteld op",
    sent_date: "Verzonden op",
    delivery_date: "Bezorgd op",
    shipment_method: "Verzendmethode",
    day_delivered: "< 24 uur geleverd",
    two_day_delivered: "< 48 uur geleverd",
    search_placeholder: "Zoeken op naam, order of postcode",
    "Shipment Address": "Verzendadres",
    Amount: "Aantal",
    "Ready for shipment": "Klaar voor verzending",
    Packed: "Ingepakt",
    Shipped: "Verzonden",
    "Shipment options": "Verzendopties",
    Open: "Open",
    Pallets: "Pallets",
    Coli: "Coli/Dozen",
    Type: "Soort",
    remark: "Opmerking",
  },
  checkinv: {
    created_at: "Verzonden",
    expected_delivery: "Verwacht",
    status: "Status",
    checked_in_date: "Ingeboekt",
    product_name: "Product",
    product_ean: "Ean/SKU",
    expected: "Verwacht",
    final_check_in: "Ingeboekt",
    comment: "Opmerking",
    pallets: "Pallets",
    pallet: "Pallet",
    box: "Doos",
    boxes: "Dozen",
    change_checkin: "Een aanmelding inzien/wijzigen",
    search: "Aanmelding zoeken op naam",
    edit_checkin: "Aanmelding inzien/wijzigen",
    received: "Ontvangen",
    add_checkin: {
      overview: "Overzicht",
      out_of_stock: "Uitverkocht",
      application: "Aanmelding",
      reason: "Wat wil je doen?",
      stock_checkin: "Voorraad aanmelding",
      stock_checkin_info: "Klik hier om ons te informeren over uw geplande zendingen, zodat wij de ontvangst en opslag optimaal kunnen voorbereiden en uw goederen zorgvuldig kunnen beheren.",
      change_checkin: "Een aanmelding inzien/wijzigen",
      change_checkin_info: "Bekijk of bewerk uw eerder gemelde zendingen om de details up-to-date te houden en een naadloze logistieke ervaring te waarborgen.",
      type: "Type",
      products: "Producten",
      data: "Gegevens",
      confirmation: "Bevestiging",
    },
    type: {
      package: "Pakketzending",
      package_text: "Kies deze optie voor het aanmelden van kleinere, individueel verpakte items. Bezorgd door PostNL, UPS, DpD of overige pakketdiensten",
      pallet: "Palletzending",
      pallet_text: "Selecteer dit voor grotere zendingen op pallets voor georganiseerd transport.",
      container_other: "Containerzending / overige",
      container_other_text: "Voor het aanmelden van zendingen in containers of niet-standaard formaten.",
    },
    checkin_products: {
      choose_products_in_box: "Kies het product dat in deze doos zit",
      no_products: "Je hebt nog geen producten in ons systeem staan, doe dit eerst",
      search_products: "Product zoeken op naam of EAN",
    },
    checkin_product: {
      unit_amount: "Aantal units (dozen)*",
      amount: "Aantal",
      box_amount: "Aantal in doos*",
      total_amount: "Totaal aantal stuks (CE)",
      expiration_date: "Houdbaarheidsdatum",
      batch_number: "Batchnummer",
      add: "Toevoegen",
      divergent: "* Is 1 doos afwijkend? Rond deze stap af met de even gelijke dozen en voeg dan het zelfde product nog 1x toe, in de vorige stap.",
      check: "Controleer of je producten goed zijn ingesteld",
      back: "Terug",
      totals: "Totalen",
      per_box: "Producten per doos",
      boxes: "Dozen",
      submit_checkin: "Bevestig aanmelding",
    },
    checkin_success: {
      shipment_instructions: "Verzendinstructies:",
      documents_sent: "De documenten zijn per mail verstuurd. Je kunt ze ook direct downloaden vanuit hier",
      checkin_step1: "Download de pakbon en stop deze in het pakket",
      checkin_step2: "Download vervolgens de QR-code en plak deze op de buitenkant van het pakket",
      checkin_important: "Belangrijk: met deze QR-code wordt jouw pakket ingecheckt. Als deze ontbreekt zal het incheckproces vertragen."
    },
    checkin_information: {
      type: "Geef aan waar de producten in zitten",
      products: "Kies een voor een de producten die je wilt toevoegen",
      information: "Vul de onderstaande informatie in om de aanmelding te voltooien",
    },
    checkin_confirmation: {
      contact_person_label: "Contactpersoon",
      contact_person_placeholder: "Contactpersoon",
      contact_person_email_label: "E-mail Contactpersoon",
      contact_person_email_placeholder: "E-mail Contactpersoon",
      phone_label: "Telefoonnummer",
      phone_placeholder: "Telefoon",
      shipping_address_label: "Verzendadres",
      shipping_address_placeholder: "Verzendadres",
      track_trace_label: "Track & Trace",
      track_trace_placeholder: "Track & Trace",
      expected_delivery_label: "Verwachte Levering",
      expected_delivery_placeholder: "Verwachte Levering",
      button_submit: "Registratie Aanmaken"
    },
    checkin_done: {
      confirmation_message: "Je aanmelding is ontvangen!",
      email_instructions: "De documenten zijn per mail verstuurd. Je kunt ze ook direct downloaden vanuit hier.",
      shipping_instructions_title: "Verzendinstructies:",
      shipping_instruction_1: "Zorg ervoor dat de producten verpakt zijn volgens onze Aanlever voorwaarden",
      shipping_instruction_2: "Download de QR-code en plak deze op de buitenkant van de doos.",
      important: "Belangrijk:",
      important_note: "Met deze QR-code wordt jouw pakket ingecheckt. Als deze ontbreekt wordt jouw zending niet aangenomen en wordt deze teruggestuurd naar de leverancier.",
      view_information_button: "Informatie inzien",
      documents_per_product: "Documenten per product",
      box_label: "Doos",
      packing_slip_label: "Pakbon",
      qr_code_label: "QR-code",
      download_all_documents_button: "Download alle documenten",
      processing_shipment: "We zijn je aanmelding aan het verwerken. Blijf op deze pagina!",
    }
  },
  inbound_details: {
    delivery: {
      delivery: "Levering",
      expected: "Verwacht",
      delivered: "Geleverd",
      organization: "Organisatie",
      contactInformation: "Contactgegevens",
      units: "Units",
      packedPer: "Verpakt per",
      transit: "In transit"
    },
    location: {
      location: "Locatie",
      expirationDate: "Houdbaarheidsdatum",
      isPackageClosed: "Is het pakket gesloten aangekomen",
    },
    extraWork: "Extra werkzaamheden & toeslagen",
    note: "Opmerkingen"
  },
  history_locations: {
    button_name: "Historische locaties",
    name: "Historische locaties van product",
    none: "Geen historische locaties gevonden",
  },
  stock_analysis: {
    button_name: "Voorraad analyse",
    analysis: "Analyse",
    checked_in: "Ingeboekt",
    date: "Datum",
    amount: "Aantal",
    retours: "Retours",
    period: "Periode",
    orders: "Orders",
    logical_stock: "Logische voorraad",
  },
  automations: {
    button_name: "Automatiseringen",
  },
  validation_trx: {
    fieldIsRequired: "Dit veld is verplicht!",
    invalidFormat: "Ongeldige indeling!",
    numberTooSmall: "Het getal is te klein! Minimum: {0}",
    numberTooBig: "Het getal is te groot! Maximum: {0}",
    invalidNumber: "Ongeldig getal",
    invalidInteger: "De waarde is geen geheel getal",
    textTooSmall:
      "De lengte van de tekst is te klein! Huidig: {0}, Minimum: {1}",
    textTooBig: "De lengte van de tekst is te groot! Huidig: {0}, Maximum: {1}",
    thisNotText: "Dit is geen tekst!",
    thisNotArray: "Dit is geen array!",
    selectMinItems: "Selecteer ten minste {0} items!",
    selectMaxItems: "Selecteer maximaal {0} items!",
    invalidDate: "Ongeldige datum!",
    dateIsEarly: "De datum is te vroeg! Huidig: {0}, Minimum: {1}",
    dateIsLate: "De datum is te laat! Huidig: {0}, Maximum: {1}",
    invalidEmail: "Ongeldig e-mailadres!",
    invalidURL: "Ongeldige URL!",
    invalidCard: "Ongeldig kaartformaat!",
    invalidCardNumber: "Ongeldig kaartnummer!",
    invalidTextContainNumber:
      "Ongeldige tekst! Mag geen getallen of speciale tekens bevatten",
    invalidTextContainSpec:
      "Ongeldige tekst! Mag geen speciale tekens bevatten",
    add_product_name: "Voeg productnaam toe",
    add_ean: "Voeg EAN toe",
    invalid_product_items: "Voeg minimaal 1 product toe",
    invalid_streetname: "Voeg straatnaam toe",
    invalid_housenumber: "Voeg huisnummer toe",
    invalid_postcode: "Voeg postcode toe",
    invalid_country: "Voeg land toe",
    invalid_ship_option: "Kies verzendoptie",
    invalid_type: "Kies waarom je deze order aanmaakt",
    invalid_email: "Voeg email toe",
    invalid_EAN_input: "EAN ongeldig, moet 13 cijfers bevatten",
    invalid_phone: "Voeg telefoonnummer toe",
    invalid_firstname: "Voeg naam toe",
    invalid_surname: "Voeg achternaam toe",
    invalid_company: "Voeg bedrijfsnaam toe",
    invalid_city: "Voeg stad toe",
    invalid_hs: "Voeg een HS Code toe",
    invalid_value: "Voeg een waarde toe",
    invalid_weight: "Voeg een gewicht toe",
    invalid_shipment_method: "Kies een verzendoptie",
    invalid_attachment_text: "Voeg een opmerking toe bij je bijlage",
    invalid_attachment_files: "Voeg een bijlage toe als je instructies hebt",
  },
};
