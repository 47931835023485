<template>
  <div class="users-wrp">
    
    <MobileMenu />

    <ControlButtons 
      @refresh="getOrders(dateRange); code ? add_selected_product(code) : '' ;"  
      className="control_buttons_desktop" 
    />

    <div class="order_controles">
      <div class="flex filter_controles">
        <div class="flex-30">
          <h6>{{ trx.common.select_date_time }}</h6>
          <date-range-picker ref="picker" class="datePicker" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :showWeekNumbers="true" :showDropdowns="true" :timePicker="true" :autoApply="true" opens="right" @select="getOrders" @update="getOrders" v-model="dateRange">
            <template v-slot:input="picker">
              {{ moment(picker.startDate).format("DD-MM-YYYY") | date }} -
              {{ moment(picker.endDate).format("DD-MM-YYYY") | date }}
            </template>
          </date-range-picker>
        </div>
        <div class="flex-20">
          <h5>{{ trx.Location }}</h5>
          <v-select v-if="locations" @input="filter_locations" taggable multiple push-tags :options="locations.map((x) => {
            return { name: x.name };
          })
            " :reduce="(location) => location.name" label="name" />
        </div>
        <div class="flex-20" v-if="shipment_methods_list">
          <h5>{{trx.shipments.shipment_method}}</h5>
          <v-select @input="filterDelivery" taggable multiple push-tags :options="shipment_methods_list.map((x) => {
            return { _id: x._id, name: x.name };
          })
            " :reduce="(option) => option._id" label="name" />
        </div>
        <div class="flex-20">
          <h5>Limit</h5>
          <v-select @input="modify_limit" :options="['10', '20', '50', '100', '200', '500']" label="" />
        </div>
      </div>
    </div>

    <iframe ref="pdfFrame" style="display:none"></iframe>

    <div v-if="orders || orders.length < 0" class="orders">
      <!-- <input type="text" id="myInput" v-on:keyup.enter="search()" placeholder="Zoek op bedrijf.."> -->
      <div v-for="l in orders" v-bind:key="'location__' + l._id"
        :data-location="l.location_rack + '-' + l.location_number" class="orders__location">
        <div class="orders__location__info">
          <span v-if="l.pick_location">
            VAK
            <h1>{{ l.pick_location }}</h1>
          </span>
          <span v-if="l.count > 0">
            Aantal
            <h1>{{ l.count }}</h1>
          </span>
          <span v-if="l.ship_box && l.ship_box.name">
            Verpakking
            <h1 v-if="l.ship_box">{{ l.ship_box.name }}</h1>
            <h1 v-else>-</h1>
          </span>
        </div>

        <!-- new component -->
        <OrderItem v-for="order in l.orders" :ref="`order-${order.orderId}`" v-bind:key="order._id" :order="order" class="orders__location__container"
        :data-order_id="order.orderId" :iframeRef="iframeRef">
        </OrderItem>

      </div>
    </div>

    <div v-else>NO ORDERS TO SHOW</div>
    
    <Popup :title="'popup'" :closed="!product_popup" @handleClose="handleProductPopupClose">
      <div class="popup__content">
        <h1>Product</h1>
        <p>Hoeveelheid: {{ product_popup_quantity }}</p>
        <p v-if="product_popup_note !== null || product_popup_note !== ''">Pack notitie: {{ product_popup_note }}</p>
      </div>
    </Popup>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";

import duplicateWarning from "@/assets/duplicate_warning.svg";
import trx from "@/translations";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import vSelect from "vue-select";

import OrderItem from "./order_item.vue";

import MobileMenu from "./components/mobile_menu.vue";
import ControlButtons from "./components/control_buttons.vue";

import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import "vue-select/dist/vue-select.css";

import Popup from "../../components/Layout/popup.vue";

Vue.component("v-select", vSelect);

/* eslint-disable no-debugger */

export default {
  name: "Orders",
  computed: {
    ...mapGetters([
      "orders",
      "p_size",
      "order_report",
      "locations",
      "shipping_options",
      "shipment_methods_list",
      "shipment_boxes_list",
      "selected_product"
    ]),
  },
  methods: {
    ...mapActions([
      "fetch_shipment_boxes_list",
      "getOrders",
      "filter_locations",
      "fetchLocations",
      "startTimer",
      "ship",
      "modify_limit",
      "modify_red_orders",
      "filterDelivery",
      "shipPostNL",
      "setNotes",
      "getOrderReport",
      "downloadOrderList",
      "toggleDoubleOrders",
      "fetch_shipment_methods_list",
      "filter_picked_location",
      "filter_product",
      "product_ship",
      "add_selected_product"
    ]),
    listen_to_ean_input() {
      let code = "";
      const self = this;

      window.addEventListener("keypress", async (e) => {
        // Check if the keyCode is an enter
        if (e.keyCode === 13) {

          // This is probably an ObjectId
          if (code.length > 23) {
            self.filter_picked_location(code);
            code = "";
          }

          // This is probably an EAN code
          if (code.length === 13) {
            if (self.selected_product == null) {
               self.add_selected_product(code);
               await self.filter_product(code);
              if (self.orders && (self.orders.length === undefined || self.orders.length === 0 )) {
                // There were no orders for the selected product, set the selected_product back to null
                self.add_selected_product(null);
              }
            } else if (self.selected_product == code) {
              self.orders.forEach((x) => {
                x.orders.forEach((y) => {
                  if (y.products[0].product_id.ean === code) {
                    //console.log("Correct product ID");
                    self.product_order = y;
                  }
                });
              });

              
              //console.log("Orders: ", self.orders);
              //console.log("Product order: ", self.product_order);
              if (self.product_popup === true) {
                // Ship the order if the popup is open
                
                if (self.product_order?.products[0]?.product_id?.def_shipping_id == null) {
                  // Reject sending of the order if there is no selected_ship_option
                  //console.log("Select a shipping option first");
                  return;
                }

                self.product_popup = false;
                self.product_popup_quantity = "";
                self.product_popup_note = "";
                self.$refs[`order-${self.product_order.orderId}`][0].$el.querySelector('.ship_button').click();
                self.getOrders();
                await self.add_selected_product(null)
                self.product_order = null;
              } else {
                // Check if there is a quantity higher than 1 or if there is a packing note
                if (self.product_order?.products[0]?.quantity > 1 || self.product_order?.products[0]?.product_id.pack_note) {
                  self.product_popup = true;
                  self.product_popup_quantity = self.product_order.products[0].quantity;
                  self.product_popup_note = self.product_order.products[0].product_id.pack_note;
                } else {
                  // Ship the order if there is no quantity higher than 1 or a packing note

                  if (self?.product_order?.selected_ship_option == null) {
                    // Reject sending of the order if there is no selected_ship_option
                    //console.log("Select a shipping option first");
                  }

                  //console.log(self.$refs);
                  //console.log(self.$refs[`order-${self.product_order.orderId}`][0]);
                  self.$refs[`order-${self.product_order.orderId}`][0].$el.querySelector('.ship_button').click();
                  // self.getOrders();
                  
                  if (self.orders[0]?.orders?.length > 1) {
                    self.add_selected_product(code);
                  } else {
                    self.add_selected_product(null);
                  } 
                  self.product_order = null;
                }
              }
            } else {
              //console.log("Setting Selected product to the code");
              self.add_selected_product(code);
            }
          }

          debugger;

          code = "";
        } else {
          code += e.key; //while this is not an 'enter' it stores the every key
        }
      });
    },
    handleProductPopupClose() {
      this.product_popup = false
    },
    handleKeyDown(e) {
      if (e.key === 'Control') this.isCtrlPressed = true;
      if (e.key === 'Shift') this.isShiftPressed = true;

      if (this.isCtrlPressed && this.isShiftPressed) {
        console.log('Hello World');
        console.log(Object.values(this.$refs)[2][0].$el.querySelector('.ship_button').click());
        // self.$refs[`order-${self.product_order.orderId}`][0].$el.querySelector('.ship_button').click();
      }
    },
    handleKeyUp(e) {
      if (e.key === 'Control') this.isCtrlPressed = false;
      if (e.key === 'Shift') this.isShiftPressed = false;
    },
  },
  components: {
    DateRangePicker,
    OrderItem,
    MobileMenu,
    ControlButtons,
    Popup,
  },
  mounted() {
    this.iframeRef = this.$refs.pdfFrame;
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  created() {
    this.fetchLocations();
    this.getOrders(this.dateRange);
    this.listen_to_ean_input();
    if (!this.shipment_boxes_list) {
      this.fetch_shipment_boxes_list();
      this.fetch_shipment_methods_list();
    }
  },
 
  data() {
    let startDate = new Date(moment().subtract(4, "days"));
    let endDate = new Date(moment().set({ hour: 22, minute: 0 }));

    return {
      trx,
      menuOpen: false,
      moment: moment,
      timerCount: 60,
      duplicateWarning,
      dateRange: { startDate, endDate },
      picked_location: null,
      product_order: null,
      product_popup: false,
      product_popup_quantity: "",
      product_popup_note: "",
      iframeRef: null,
      SMART_CONTRACT: [
        "SMF-BRIEF-NL",
        "SMF-POSTNL-NL",
        "SMF-POSTNL-NL",
        "SMF-POSTNL-BE",
        "SMF-STAMP1-NL",
        "SMF-STAMP2-NL",
        "OWN-SHIPMENT",
      ],
      isCtrlPressed: false,
      isShiftPressed: false,
      search: function () {
        // Declare variables
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        ul = document.querySelector(".orders");
        li = ul.querySelectorAll(".order__location");

        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < li.length; i++) {
          a = li[i].querySelector(".searchable");

          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            li[i].style.display = "";
          } else {
            li[i].style.display = "none";
          }
        }
      },
    };
  },
  filters: {
    date(val) {
      return val ? val.toLocaleString() : "";
    },
  },
};
</script>

<style lang="scss">

.products-wrp {
  margin-top : 2rem;
}

.off_canvas_menu {
  height: 100%;
  @include box-bg-white;
  z-index: 999999999999;
  // transform: translateY(-100%);
  transition: transform 0.3s ease;
  justify-content: space-around;
  align-content: space-around;
  gap: 1rem;
  off_canvas_menu span {
    font-size: 0rem;
    cursor: pointer;
    width: 50px;
    background: #ffffff;
    margin-top: 1rem;
    height: 5px;
    border-radius: var(--border-radius);
  }
}

.hidden {
  display: none;
}

.visible-bp-small {
  position: fixed;
  top: 1rem;
  left: 0;
  z-index: 100;
  width: 100vw;
  margin-top: 5.7rem;
  background: white;
  padding-left: 3rem;
  padding-right: 3rem;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.off_canvas_menu_item {
  text-align: center;
}

.mobile-menu-toggle {
  @include hamburger-menu
}

.mobile-menu-hamburger-line {
  @include hamburger-menu-line
}

.button {
  @include button;
  margin-right: 2rem;
}

.routerLink {
  @include button
}

.container--buttons-order-page {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 2.5rem;
  button, a {
    font-size: 3rem;
  }
}

.filter_controles {
  display: flex;
  align-items: center;

  h5 {
    margin:0;
  }

  @mixin base_ctrl {
    flex-direction: row !important;
    justify-content: center;
    position: fixed;
    border-bottom-left-radius: 20rem;
    border-bottom-right-radius: 20rem;
    z-index: 999;
    background: var(--color-white);
  }

  @media only screen and (max-width: $bp-small) {
    @include base_ctrl;
    visibility: hidden;
    display: none;
    top: 8rem !important;
    width: 100% !important;
    left: 0px !important;
    max-height: 2rem;

    h6 {
      display: none;
    }
  }

  @media only screen and (min-width: $bp-small) and (max-width: $bp-medium) {
    @include base_ctrl;
    top: -1.3rem;
    visibility: hidden;
    display: none;
    height: 7rem;
    width: 75% !important;
    left: 6.5rem;

    h6 {
      display: none;
    }
  }

  >div {
    @media only screen and (max-width: $bp-medium) {
      flex: 0 0 auto !important;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }

  
}

.order_controles {
  padding: 2rem 0rem;

  @media only screen and (max-width: $bp-medium) {
    flex-direction: row !important;
    justify-content: center !important;

    [class^="flex"] {
      padding: 1rem 0rem;
      align-items: center;
      align-content: center;
      display: flex;
      flex-direction: column;
    }
  }
}

.datePicker {
  max-width: 23rem !important;
}

.filters {
  text-align: center;

  @media only screen and (max-width: $bp-medium) {
    flex: 0 0 100% !important;
    flex-direction: row !important;
  }
}

.orders {
  @media only screen and (min-width: $bp-medium) {
    max-height: calc(100vh - 25rem);
  }

  overflow: auto;

  &__location {
    background: var(--color-grey-light-1);
    border: 2px solid var(--color-primary);
    padding: 2rem;
    margin-bottom: 1rem;
    border-radius: var(--border-radius);
    position: relative;
    margin-top: 8rem;

    @media only screen and (max-width: $bp-medium) {
      padding: 2rem 0rem;
    }

    &__info {
      display: flex;
      position: absolute;
      top: -8rem;

      >span {
        border: 2px solid var(--color-primary);
        margin: 2rem;
        @include box-bg-white;
        padding: 1rem 1.5rem !important;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;

        h1 {
          line-height: 4rem;
        }
      }
    }

    &__container {
      margin-top: 2rem;

      .order {
        justify-content: space-between;
        align-items: flex-start;
        @include box-bg-white;
        position: relative;

        &__platform,
        &__api_name {
          right: 0;
          top: 0;
          background: var(--color-grey-dark-3);
          color: var(--color-grey-dark-1);
          padding: 0.5rem 2rem;
          border-bottom-left-radius: 2rem;
          width: 15rem;
          text-align: center;
          position: absolute;
        }

        &__api_name {
          right: 13rem !important;
          background: var(--color-grey-light-3) !important;
        }

        &__order_list {
          @include button;
          background: var(--color-grey-dark-2);
          height: 3rem;
          width: 3rem;
          display: flex;
          position: absolute;
          bottom: 0px;
          left: 0px;
          justify-content: center;
          align-content: center;
          align-items: center;
          padding: 0.5rem;
          font-size: 1.5rem;
          margin-right: 2rem;
        }

        &__remark {
          position: relative;

          span {
            position: absolute;
            padding: 0.5rem;
            top: 0px;
            left: 0px;
            border-bottom-right-radius: 5px;

            &.title {
              margin-left: 2rem;
            }

            background: var(--color-white);
          }

          textarea {
            padding: 1rem;
            padding-top: 3rem;
            padding-right: 5rem;
            max-width: 20rem;
            min-width: 20rem;
            overflow: auto;

            &.red {
              background: var(--color-red);
              color: var(--color-white);
            }

            &.yellow {
              background: var(--color-yellow);
              color: var(--color-white);
            }

            &.green {
              background: var(--color-green);
              color: var(--color-white);
            }
          }

          &__colors {
            position: absolute;
            right: -2rem;
            top: 0rem;

            // @media only screen and (max-width: $bp-medium) {
            //     top: 6rem;
            //     right: 0rem;
            // }
            // @media only screen and (-width: $bp-medium) {
            //     top: 6rem;
            //     right: 0rem;
            // }
            input {
              background: none;
              box-shadow: none;
            }
          }

          &__email {
            display: flex;
            align-content: center;
            align-items: center;

            input {
              box-shadow: none;
              max-width: 2rem;
            }
          }
        }

        &__details {
          margin-top: 1rem;
          justify-content: space-around;
          align-content: center;
          align-items: center;
          flex-wrap: wrap;

          &__shipment {
            position: relative;
            padding: 2rem;
            align-self: flex-start;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;

            &__option {
              @include box-bg-white;
              padding: 1rem;

              flex-wrap: wrap;

              &:not(:last-child) {
                margin-bottom: 0.5rem;
              }

              &:hover,
              &.active {
                @include box-bg;
                padding: 1rem;
                cursor: pointer;
                color: var(--color-white);

                p {
                  color: var(--color-white);
                }
              }
            }
          }
        }
      }
    }
  }
}

.items {
  .item {
    display: flex;

    p {
      margin: 0rem 2rem;
    }
  }
}

.product__box_size,
.product__ship_method {
  padding: 0rem 1rem;

  &--title {
    margin: 0rem 0rem !important;
    font-weight: 100 !important;
    font-size: 1.1rem !important;
  }

  &--value {
    top: 2.7rem !important;
    left: 2rem;
  }

  &__select {
    select {
      padding: 1rem;
    }
  }
}
</style>
