<template>
    <div class="product__ship_method" v-if="shipment_methods_list">
          <h4 class="product__ship_method--title">
            <img src="../../assets/icons/delivery_icons/J05.svg" :title="trx.orders['Default shipment']" > 
          </h4>
          <v-select v-model="product.def_shipping_id" @input="setShippingMethod({ product_id: product._id , item: product.def_shipping_id})" :options="shipment_methods_list" label="name">
            <template slot="selected-option" slot-scope="option" >
                <div style="display: flex; align-items: baseline">
                    <strong style="margin-right:2px">{{ option.name }} </strong>   € {{ option.price }}
                </div>
            </template>
            <template slot="option" slot-scope="option" >
                <p>{{option.name}}</p>
                <small class="text-muted">
                    € {{ option.price }}
                </small>
            </template>    
          </v-select>   
    </div>
</template>

<script>

/* eslint-disable no-debugger */

import trx from '@/translations';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SelectShippingMethod',
  computed: {
        ...mapGetters(['products', 'shipment_methods_list'])
  },
  methods: {
        ...mapActions(['setShippingMethod', 'fetch_shipment_methods_list'])
  },
  components: {

  },
  created() {
    if (this.shipment_methods_list === null) {
        this.fetch_shipment_methods_list()
    }
  },
  props: {
    product: Object,
  },
  data () {
    return {
      trx
    }
  }
}
</script>

<style lang="scss" scoped>

    .product__ship_method {
      position: relative;
      &--title {
       position: absolute;
       width: 5rem;
       z-index: auto;
       left: -3.8rem;
       display: flex;
       align-content: center;
       align-items: center;
       justify-content: center;
       height: 4rem;
       border-top-left-radius: var(--border-radius);
       border-bottom-left-radius: var(--border-radius);
       background-color: var(--color-grey-light-2);
       img {
        width: 3.4rem;
       }
      }
      .v-select.vs--single.vs--searchable {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      &--value {
          position: absolute;
          top: 3.7rem;
          left:2rem;
          z-index: 99;
      }
    }
</style>


    