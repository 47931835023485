<template>
  <div>
    <div>
      <div class="header">
        <h3>Locatie  <span @click="expandedLocationFinder = !expandedLocationFinder" class="hoverButton">
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            stroke-width="2" 
            stroke-linecap="round" 
            stroke-linejoin="round" 
            class="svgIcon"
          >
            <circle cx="11" cy="11" r="8" stroke-width="2"/>
            <line x1="17" y1="17" x2="21" y2="21" stroke-width="2" />
          </svg>
        </span> </h3>
        <div >
          
        <span @click="toggleManualLocation" class="hoverButton">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 2 22 22" fill="none"
            stroke="currentColor" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" class="svgIcon">
            <circle cx="12" cy="12" r="10" fill="white" />
            <line x1="12" y1="10" x2="12" y2="14" stroke-width="1.5" />
            <line x1="10" y1="12" x2="14" y2="12" stroke-width="1.5" />
          </svg>
        </span>
        </div>
        
      </div>
      <button class="btn btn-delete" v-if="inbound_item.reservedLocations && inbound_item.reservedLocations.length > 0"
        @click="deleteReservedLocations({ id: inbound_item._id })">Delete all reserved locations</button>
    </div>
    
    <transition name="slide">
      <div v-if="manual_location" class="loc_f__manl_loc">
        <div class="flex">
          <div class="flex-60 flex-70-sm">
            <label for="manual_amount">Aantal</label>
            <div class="input-group flex">
              <button class="flex-20 flex-20-sm" type="button" @click="changeAmount(-inbound_item.packed_per)" :disabled="manual_amount <= inbound_item.packed_per">−</button>
              <input class="flex-60 flex-50-sm" style="text-align: center;"
                id="manual_amount" 
                type="number" 
                v-model.number="manual_amount" 
                :min="inbound_item.packed_per" 
                aria-label="Aantal" 
              />
              <button class="flex-20 flex-30-sm" type="button" @click="changeAmount(inbound_item.packed_per)">+</button>
            </div>
          </div>
          <div class="flex-40 flex-20-sm">
            <label for="manual_location">Locatie ID</label><br>
            <input 
              id="manual_location" 
              ref="addToLocation"
              v-focus-on-visible
              @change="addToLocation(inbound_item.product_id)" 
              aria-label="Locatie ID"
            />
          </div>
        </div>
      </div>
    </transition>  

    <transition name="slide">
      <div v-if="expandedLocationFinder" class="loc_f">
        
          <div class="loc_f__amnt">
            <label for="pieces">Locatie(s)</label>
            <number-field v-model="locationFinder.locationFinderQuantity"></number-field>
          </div>
          <div class="loc_f__types">
            <label for="location_types">Type</label>
            <select-list :options="locationFinder.locationTypes.map(x => {
              const svg = x.replace('/', '-');
              return { value: x, text: x, svg: svg ? svg : '' };
            })" v-model="locationFinder.selectedLocationType"></select-list>

          </div>
          <div class="loc_f__spec">
            <label for="old_locations">Specifieke locatie</label>

            <v-select v-model="selected_location" :options="locations" :reduce="location => location._id"
              :getOptionLabel="location => location.name" :multiple="true">
              <template slot="option" slot-scope="option">
                <div selectable="true"> {{ option.name }}</div>
              </template>
            </v-select>
          </div>
          <div class="loc_f__old_loc">
            <input v-model="enable_old_locations" id="old_locations" type="checkbox" checked />
            <label for="old_locations">Toon bestaande locatie(s)</label>
          </div>
          <button
            @click="() => { findLocation({ type: locationFinder.selectedLocationType, amount: locationFinder.locationFinderQuantity, inbItemId: inbound_item._id, product: inbound_item.product_id, enable_old_locations: enable_old_locations, included: selected_location }); this.expandedLocationFinder = false; }"
            class="buttonLocationFinder">
            Vind locatie
          </button>
      </div>
    </transition>  
    
    <div v-if="inbound_item && inbound_item.reservedLocations && inbound_item.reservedLocations.length > 0"
      class="foundLocationsContainer">
      <div>
        <div class="tableHeadContainer">
          <p>Units</p>
          <p>Stuks</p>
        </div>
        <LocationFinderItem v-for="(location) in inbound_item.reservedLocations.filter(x => x.transfer === false)"
          :key="location._id" :inbound_item="inbound_item" :location="location"
          :total_locations="inbound_item.reservedLocations.length" />
      </div>
    </div>

  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import LocationFinderItem from './locationFinderItem.vue'
import ean_checked from '@/assets/audio/ean_checked.mp3'
import NumberField from '@/components/Controls/number.vue';
import SelectList from '@/components/Controls/select_list.vue';


export default {
  name: "LocationFinder",
  data() {
    return {
      expandedLocationFinder: false,

      locationFinder: {
        selectedLocationType: null,
        locationTypes: ['half-box', 'rack-box', 'warehousing', '1/2pallet', 'pallet'],
        locationFinderQuantity: null,
      },
      enable_old_locations: true,
      selected_location: '',
      manual_location: false,
      manual_amount: this.inbound_item.pieces_expected,
    }
  },
  props: {
    inbound_item: Object,
    fetchInboundItem: Function,
  },
  components: {
    LocationFinderItem,
    NumberField,
    SelectList,
  },
  methods: {
    ...mapActions(['findLocation', 'deleteReservedLocation', 'fetchLocations', 'link_barcode', 'deleteReservedLocations']),
    closePopup() {
      this.expandedLocationFinder = false
    },
    changeAmount(value) {
      const newValue = this.manual_amount + value;
      if (newValue >= this.inbound_item.packed_per) {
        this.manual_amount = newValue;
      }
    },
    async addToLocation(elm) {
      const self = this;
      var audio = new Audio(ean_checked)
      audio.play();

      if (this.manual_location && this.manual_amount && this.$refs.addToLocation.value) {
        await this.link_barcode({ product_id: elm._id, location_id: this.$refs.addToLocation.value, amount: this.manual_amount, type: "inbound", inbound_item_id: this.inbound_item._id })
        await this.deleteReservedLocation({ inbItemId: this.inbound_item._id, locationId: this.$refs.addToLocation.value })
        this.expandedLocationFinder = false;
        this.manual_amount = (this.inbound_item.pieces_expected - this.inbound_item.pieces_checked_in) > 0 ? this.inbound_item.packed_per : null;
        this.manual_location = !this.manual_location;
        self.fetchInboundItem({ id: this.$route.params.id });
      }
    },
    toggleManualLocation() {
      this.manual_location = !this.manual_location;
      this.manual_amount = (this.inbound_item.pieces_expected - this.inbound_item.pieces_checked_in) > 0 ? this.inbound_item.packed_per : null;
      
    }
  },
  computed: {
    ...mapGetters(['locations']),
    isAllTransfer() {
      return this.inbound_item.reservedLocations.every(x => x.transfer === false)
    }
  },
  created() {
    if (this.manual_amount != this.inbound_item.pieces_expected) {
        this.manual_amount = (this.inbound_item.pieces_expected - this.inbound_item.pieces_checked_in) > 0 ? this.inbound_item.packed_per : null;
    }

    this.fetchLocations();
  }
}
</script>

<style lang="scss" scoped>
.popup_content_between {
  background-color: var(--color-black);
}

.loc_f {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;

  &__manl_loc {
    transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
    gap: 2rem;
    button {
      width: 100%;
      min-height: 4.5rem;
      margin:0;
     
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    input {
      width: 100%;
      margin: 0;
      padding: 0;
      max-width: 15rem;
      font-size: 2rem;
      text-align: center;
      min-height: 4.5rem;
      box-shadow: none;     
      border: 1px solid var(--color-primary);
      border-radius: 0;
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
      &:last-child {
       border-radius:var(--border-radius); 
       width: 100%;
       max-width: 100%;
      }
      
    }
  }

  &__old_loc {
    display: flex;
    justify-content: flex-start;

    input {
      flex: 0 0 5%;
      height: 4rem;
      width: 100%;
      border: 2px solid var(--color-primary);
      // border-radius: 4px;
      transition: background-color 0.2s;
    }

    label {
      flex: 0 0 90%;
      max-width: 200px;
      margin-left: -1rem;
      font-size: 1.7rem;
      padding: 0.3rem;
    }

    align-items: center;
  }

}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem 1rem 0;

  p {
    font-weight: bold;
  }
}

.foundLocationsContainer {
  overflow-y: auto;
  max-height: 15rem;

  .tableHeadContainer {
    display: flex;
    gap: 3.5rem;
    margin-left: 2rem;

    p:first-child {
      font-weight: normal !important;
    }
  }
}

.buttonLocationFinder {
  width: 100%;
  margin-top: 0.5rem;
}

.locationFinder {
  position: absolute;
  @include box-bg-white;
  z-index: 9999;
  transform: translate(110%, 0);
  width: 35rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_flex {
    display: flex;
    flex-direction: column;
  }
}

.close_button {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    stroke: var(--color-red);
  }
}

.btn-delete {
  background-color: #f44336;
}

.btn-delete:hover {
  background-color: #d32f2f;
}

.hoverButton:hover {
  cursor: pointer
}

.svgIcon {
  vertical-align: middle;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));
}

//// scroll bar
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
}

/* Slide-down animation */
.slide-enter-active, .slide-leave-active {
  transition: max-height 0.4s ease-in-out, opacity 0.3s ease-in-out;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-enter-to, .slide-leave {
  max-height: 200px; /* Adjust height if needed */
  opacity: 1;
}

</style>