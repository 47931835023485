<template>
    <div :class="`control_buttons ${className}`">
        <router-link :title="trx.orders['Add order']" :to="{ path: '/new_order' }" class="button">
          <img :src="require('@/assets/icons/new_order.svg')" />
          {{ trx.orders['Add order'] }}
        </router-link>
        <router-link to="/orders/import" class="routerLink">
              <img class="xls-svg" :src="require('@/assets/icons/xls_icon.svg')" />
              {{ trx.import }}
        </router-link>
        <button :title="trx.refresh" v-on:click="refresh">
          <img :src="require('@/assets/icons/refresh.svg')" />
          {{ trx.refresh }}
        </button>
        <button class="button" :title="trx.pick_list.name" v-on:click="beforeRouteChange">
          <img :src="require('@/assets/icons/pick_list.svg')" />
          {{ trx.pick_list.name }}
        </button>
        <button :title="trx.orders.multi_orders" :class="p_size === 1 ? '' : 'active'" v-on:click="toggleDoubleOrders">
          <img :src="require('@/assets/icons/multi_orders.svg')" />
          {{ trx.orders.multi_orders }}
        </button>
        <button :title="trx.orders.warning_orders" :class="red_active === false ? '' : 'active'" v-on:click="handleRedOrderClick">
          <img :src="require('@/assets/icons/warning_orders.svg')" />
          {{ trx.orders.warning_orders }}
        </button>
        <router-link :title="trx.returns['name']" :to="{ path: '/returns' }" class="button">
            <img :src="require('@/assets/icons/returns.svg')" />
            {{ trx.returns['name'] }}
        </router-link>
        
    </div>
</template>

<script>

import trx from "@/translations";
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "ControlButtons",
    computed: {
        ...mapGetters([
            "p_size",
        ])
    },
    methods: {
        ...mapActions([
            "toggleDoubleOrders",
            "modify_red_orders"
        ]),
        handleRedOrderClick() {
          this.modify_red_orders();
          this.red_active = !this.red_active;
        },
        refresh() {
          this.$emit("refresh");
        },
        beforeRouteChange() {
          // Wait for voices to be loaded if they aren't available yet
          const voices = speechSynthesis.getVoices();
          if (voices.length === 0) {
            // If voices are not ready yet, listen for onvoiceschanged event
            speechSynthesis.onvoiceschanged = () => {
              this.unlockAndSpeak();
            };
          } else {
            this.unlockAndSpeak();
          }

          this.$router.push('/pick');
        },
        unlockAndSpeak() {
          // Use a non-empty dummy utterance. Use a space or a short word.
          const unlockUtterance = new SpeechSynthesisUtterance(' ');
          unlockUtterance.volume = 0; // Silent but still considered a speech action
          unlockUtterance.lang = "en-US"; // A language that is commonly supported

          // Try using onstart instead of onend, because some iOS versions
          // may not fire onend for a silent or very short utterance.
          unlockUtterance.onstart = () => {
            // Give a slight delay to ensure speech is unlocked before speaking again
            setTimeout(() => {
              const msg = new SpeechSynthesisUtterance("");
              msg.volume = 1.0;
              msg.pitch = 1.0;
              msg.rate = 1;
              msg.lang = "nl-NL";
              msg.onerror = (e) => console.error("Speech error:", e);

              window.speechSynthesis.speak(msg);
            }, 100);
          };

          unlockUtterance.onerror = (e) => console.error("Unlock utterance error:", e);

          // Speak the dummy utterance directly inside this user-initiated event handler
          window.speechSynthesis.speak(unlockUtterance);
        }
    },
    data() {
        return {
            trx,
            menuOpen: false,
            red_active: false,
        }
    },
    props: {
        className: String,
    }
}
</script>

<style lang="scss">
.button {
  @include button;
  margin-right: 2rem;
}

.control_buttons {
  padding: 2rem;
  padding-top: 0;
  background: var(--color-white);
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border: 1px solid var(--color-primary);
  position: relative;
  margin-top: -5rem;
  display: flex;
  width: auto;
  text-align: center;

  img {
    width: 4rem;
    height: 4rem;
  }

  

  a,
  button {
    @include box-bg-solid;
    width: 100%;
    min-width: 5rem;
    min-height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-right: 1rem;
    font-size: 1rem;
    background: var(--color-grey-light);
    &.active {
      background: var(--color-primary);
    }
    &:hover {
      img {
        background-color: var(--color-white);
        padding: 1rem;
        border-radius: var(--border-radius);
      }    
    }
  }
  @media only screen and (max-width: $bp-small) {
    position: relative;
    display: flex;
    width: auto;
    text-align: center;
    flex-direction: column;
    right: 0;

    a, button {
      min-width: 0rem;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
    }
  }
}

.control_buttons_desktop {
  @media only screen and (max-width: $bp-small) {
    visibility: hidden;
    display: none;
  }
}
</style>