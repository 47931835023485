var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.order_report_all?.length > 1)?_c('div',{staticClass:"charts"},[_c('h3',{staticClass:"no-margin text-center"},[_vm._v("Orders afgelopen 30 dagen")]),_c('GChart',{staticStyle:{"height":"320px"},attrs:{"type":"ColumnChart","data":_vm.order_report_all,"options":{ 
            title: '', 
            curveType: 'function', 
            legend: { position: 'bottom'},
            isStacked: false, 
            backgroundColor: {
                fill: 'none',
            },
            colors: ['#009344', '#006433'],
            hAxis: {
                format: 'd-MM',
                gridlines: {
                    count: 32,
                    units: {
                        days: {format: ['m']}
                    }
                },
                minorGridlines: {
                    count: 0
                }
            },
            vAxis: {
                gridlines: {
                    count: 0,
                    units: {
                        days: {format: ['MM']}
                    }
                },
                minorGridlines: {
                   count: 0
                }
            }
        }}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }