<template>
    <router-link :class="'menu-item ' + hasOrg" :to="link" >
       <img :src="require('@/assets/icons/' + icon + '.svg')" />
        <p> {{name}} </p>
   </router-link>
</template>

<script>
export default {
  name: 'MenuItem',
  props: {
      name: String,
      icon: String,
      link: String,
      hasOrg: Boolean
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.menu-item {
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    &.router-link-active {
        background: var(--color-primary-dark-o);
        @media only screen and (min-width: $bp-medium) {
            border-left: 5px solid var(--color-primary-light);
        }
         @media only screen and (max-width: $bp-medium) {
            border-bottom: 5px solid var(--color-primary-light);
         }

         @include super-retina-iphone {
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
        
        margin-left: -1rem;
        margin-right: -1rem;
    }
    p {
        color: var(--color-white);
        font-size: 1rem;
    }
    img {
        @media only screen and (min-width: $bp-medium) {
            width: 30px;
        }
        width: 20px;
        height: auto;
    }
}
</style>
