<template>
    <div>
        <div>
            <div v-on:click="menuOpen = !menuOpen" class="mobile-menu-toggle">
                <span class="mobile-menu-hamburger-line"></span>
                <span class="mobile-menu-hamburger-line"></span>
                <span class="mobile-menu-hamburger-line"></span>
            </div>
            <div v-if="menuOpen" class="off_canvas_menu flex">
                    
                    <div class="flex-50 flex-100-md flex-100-sm">
                    <h3>Locatie</h3>
                    <v-select v-if="locations" @input="filter_locations" taggable multiple push-tags :options="locations.map((x) => {
                        return { name: x.name };
                    })
                        " :reduce="(location) => location.name" label="name" />
                    </div>
                    
                    <div class="flex-50 flex-100-md flex-100-sm" v-if="shipment_methods_list">
                    <h3>Verzendwijze</h3>
                    <v-select @input="filterDelivery" taggable multiple push-tags :options="shipment_methods_list.map((x) => {
                        return { _id: x._id, name: x.name };
                    })
                        " :reduce="(option) => option._id" label="name" />
                    </div>

                    <div class="flex-50 flex-100-md flex-100-sm">
                    <h3>Limit</h3>
                    <v-select @input="modify_limit" :options="['10', '20', '50', '100', '200', '500']" label="" />
                    </div>

                    <div class="flex flex-100-md flex-100-sm">

                        <div class="flex-30 flex-30-md flex-30-sm">
                            <button title="Refresh" v-on:click="getOrders(dateRange)">⟳</button>
                        </div>

                        <div class="flex-30 flex-30-md flex-30-sm">
                            <router-link :title="trx.orders['Add order']" :to="{ path: '/new_order' }" class="button">+</router-link>
                        </div>

                        <div class="flex-30 flex-30-md flex-center flex-30-sm">
                            <router-link to="/orders/import" class="routerLink">
                            <!-- <img class="xls-svg" :src="require('@/assets/icons/xls_icon.svg')" /> -->
                            <span class="buttons-order-page-emoji">
                                📥
                            </span>
                            </router-link>
                        </div>

                    </div>

                    <ControlButtons class="flex-50 flex-100-md flex-100-sm" className="" />
            </div>
        </div>
    </div>
</template>

<script>

import trx from "@/translations";
import { mapActions, mapGetters } from 'vuex';

import ControlButtons from "./control_buttons.vue";

export default {
    name: "MobileMenu",
    computed: {
        ...mapGetters([
            "p_size",
            "locations",
            "shipment_methods_list",
        ])
    },
    methods: {
        ...mapActions([
            "filter_locations",
            "filterDelivery",
            "modify_limit",
            "toggleDoubleOrders",
        ]),
    },
    data() {
        return {
            trx,
            menuOpen: false,
        }
    },
    components: {
        ControlButtons,
    }
}
</script>
