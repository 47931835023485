<template>
  <div class="app-container">

    <section v-if="perfm_d[0]" class="header-section">
      
      <div class="flex">
        <div class="flex-50">
          <h2>{{trx.shipments.sent_orders}}</h2>
        </div>
       
        <div  class="flex-50  flex-100-sm order-stats flex">
          <div class="stat flex-20 flex-30-md flex-30-sm">
            <span class="label"> {{ trx.shipments["24h"] }} </span>
            <span class="percentage">{{  perfm_d[0].percentage_d_24h.toFixed()  }}%</span>
          </div>
          <div class="stat flex-20 flex-30-md flex-30-sm">
            <span class="label">{{ trx.shipments["> 2 days"]  }}</span>
            <span class="percentage">{{perfm_d[0].percentage_d_48H.toFixed()}}%</span>
          </div>
          <div class="stat flex-20 flex-30-md flex-30-sm">
            <span class="label"> {{ trx.shipments.on_the_way }}</span>
            <span class="percentage">{{(perfm_d[0].percentage_on_the_way + perfm_d[0].percentage_not_sorted).toFixed() }}%</span>
          </div>
        </div>
      </div>
      
      {{ trx.common.select_date_time }}
      <date-range-picker
        ref="picker"
        class="datePicker flex-20"
        :locale-data="{ firstDay: 1, format: trx.date_format }"
        :showWeekNumbers="true"
        :showDropdowns="true"
        :timePicker="false"
        :autoApply="true"
        opens="right"
        @select="f_shimpt_perf"
        @update="f_shimpt_perf"
        v-model="dateRange"
        >
        <template v-slot:input="picker" style="min-width: 350px;">
            {{ moment(picker.startDate).format(trx.date_format) | date }} - {{ moment(picker.endDate).format(trx.date_format) | date }}
        </template>
      </date-range-picker>
      
    </section>

    <div class="search-bar">
      <input @keydown.enter="s_shipmt(search_term)" v-model="search_term" :placeholder="trx.shipments.search_placeholder" />
    </div>
    <section class="order-list">
      <div :class="(s_reslt ? 'active ' : ' ')+ 'order-item order-item-search'" v-if="search_term">
          <div class="order-item--info">
            <span>{{ trx.common.search_results }}</span>
            <div class="">
              <span class="amount">{{s_reslt.length}}</span>
            </div>
          </div>
          <div class="items" v-if="s_reslt && s_reslt.length">
            <OrderItem v-for="si in s_reslt" v-bind:key="si._id" :node="si" />
          </div>
      </div>
    </section>
    <section v-if="perfm_d[0]" class="order-list">
      <div :class="(not_sorted_orders_exp ? 'active ' : ' ')+ 'order-item'">
        <div class="order-item--info">
          <span>{{ trx.shipments.not_sorted }}</span>
          <div class="">
            <span class="amount">{{perfm_d[0].not_sorted}}</span>
            <span v-on:click="not_sorted_orders_exp = !not_sorted_orders_exp" class="dots">...</span>
          </div>
          
        </div>
        <div class="items" v-if="not_sorted_orders_exp">
          <OrderItem v-for="oi in perfm_d[0].not_sorted_orders" v-bind:key="oi._id" :node="oi" />
        </div>
      </div>
      <div :class="(on_the_way_exp ? 'active ' : ' ')+ 'order-item'">
        <div class="order-item--info">
          <span>{{ trx.shipments.on_the_way }}</span>
          <div class="">
            <span class="amount">{{perfm_d[0].on_the_way}}</span>
            <span v-on:click="on_the_way_exp = !on_the_way_exp" class="dots">...</span>
          </div>
        </div>
        <div class="items" v-if="on_the_way_exp">
          <OrderItem v-for="oi in perfm_d[0].on_the_way_orders" v-bind:key="oi._id" :node="oi" />
        </div>
      </div>
      <div :class="(d_48H_orders_exp ? 'active ' : ' ')+ 'order-item'">
        <div class="order-item--info">
          <span>{{ trx.shipments.two_day_delivered }}</span>
          <div>
            <span class="amount">{{perfm_d[0].d_48H}}</span>
            <span v-on:click="d_48H_orders_exp = !d_48H_orders_exp" class="dots">...</span>
          </div>
        </div>
        <div class="items" v-if="d_48H_orders_exp">
          <OrderItem v-for="oi in perfm_d[0].d_48H_orders" v-bind:key="oi._id" :node="oi" />
        </div>
      </div>
      <div :class="(d_24h_orders_exp ? 'active ' : ' ')+ 'order-item'">
        <div class="order-item--info">
          <span>{{ trx.shipments.day_delivered }}</span>
          <div>
            <span class="amount">{{perfm_d[0].d_24h}}</span>
            <span v-on:click="d_24h_orders_exp = !d_24h_orders_exp" class="dots">...</span>
          </div>
        </div>
        <div class="items" v-if="d_24h_orders_exp">
          <OrderItem v-for="oi in perfm_d[0].d_24h_orders" v-bind:key="oi._id" :node="oi" />
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import infoBox from '@/components/Layout/InfoBox';
import moment from 'moment';
import trx from '@/translations';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import OrderItem from './order_item.vue';

/* eslint-disable no-debugger */

export default {
  name: "OrderApp",
  computed: {
    ...mapGetters(['s_reslt', 'perfm_d'])
  },
  methods: {
        ...mapActions(['f_shimpt_perf', 'restore_order', 's_shipmt', 'get_order_image']),
        recreate_order(shipment) {

            // Set the order_products state in vuex
            this.resetOrderProducts();
            this.resetShipmentDetails();
            shipment.products.forEach(async (product) => {
                await this.get_order_image(product.product_id._id);
                let p = {};
                p._id = product.product_id._id;
                p.title = product.title;
                p.ean = product.ean;
                p.quantity = product.quantity;
                p.img = 'data:image/' + this.order_image.img_ext + ';base64,' + this.order_image.img_data;

                this.addOrderProduct(p);
            });


            // Set the shipment_details state in vuex
            this.shipment_details.company = shipment.shipmentDetails.company ? shipment.shipmentDetails.company : '';
            this.shipment_details.name = shipment.shipmentDetails.firstName;
            this.shipment_details.surename = shipment.shipmentDetails.surname;
            this.shipment_details.countryCode = shipment.shipmentDetails.countryCode;
            this.shipment_details.postalcode = shipment.shipmentDetails.zipCode;
            this.shipment_details.housenumber = shipment.shipmentDetails.houseNumber;
            this.shipment_details.houseNumberExtension = shipment.shipmentDetails.houseNumberExtension;
            this.shipment_details.streetname = shipment.shipmentDetails.streetName;
            this.shipment_details.city = shipment.shipmentDetails.city;
            this.shipment_details.email = shipment.shipmentDetails.email;
            this.shipment_details.phone = shipment.shipmentDetails.phone ? shipment.shipmentDetails.phone : '';
            this.$router.push('/new_order');
        },
        handleKeyUp() {
          if (this.search_term.length >= 3) {
            // Trigger your search functionality here
            this.s_shipmt(this.search_term)
          } else if (this.search_term.length < 1) {
            debugger;
            this.s_shipmt("null")
          }
        }
    },
    components: {
        DateRangePicker,
        OrderItem,
    },
    created() {
      debugger;
      this.f_shimpt_perf(this.dateRange);
    },
    data() {
      let startDate = moment(new Date()).subtract(1, 'week' )._d;
      let endDate = new Date();

      return {
        dateRange: {startDate, endDate},
        search: null,
        moment,
        trx,
        not_sorted_orders_exp: false,
        on_the_way_exp: true,
        d_48H_orders_exp: false,
        d_24h_orders_exp: false,
        search_term: null
      }
    },
    filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    }
};
</script>

<style lang="scss">

h2 {
  margin-bottom: 1rem !important;
}

.header-section {
  margin-bottom: 2rem;
 
  .datePicker {
    width: 100%;
    min-width: 100%;
    text-align: left;
    margin-top: 0rem;
    padding: 0;
    max-width: 500px;
  }
  .order-stats {
    @media all and (min-width : $bp-medium) {
      justify-content: flex-end;
    };
    @include super-retina-iphone {
      margin-top: 2rem;
    }
    .stat {
        padding: 1rem 2rem;
        border-radius: var(--border-radius-box);
        background: linear-gradient(to left, var(--color-primary), var(--color-primary-dark)); 
        color: white;
        margin-right: 0.5rem;
        flex-direction: column;
        align-items: center;
        position: relative;
        height: 10rem;
        // max-width: 13rem;
        margin-top:-2rem ;
        @media all and (min-width : $bp-medium) {
          max-width: 20rem;
        };

        .label {
          position: absolute;
          font-size: 14px;
        }

        .percentage {
          position: absolute;
          font-size: 3rem;
          bottom: 1rem
        }
    }
  }
}

.search-bar {
  @include search-bar;
}

.order-list {

  @media all and (min-width : $bp-medium) {
    display: flex;
  };

  .amount {
    margin-right: 2rem;
    font-size: 3rem;
  }
  .dots {
      display: inline-block; 
      transform: rotate(90deg);
      font-size: 3rem;
      &:hover {
        cursor: pointer;
        color: var(--color-primary)
      }
  }
  .order-item {
    @media all and (min-width : $bp-medium) {
     
     &:not(.order-item-search) {
      flex-basis: 25%;
     }

     &:not(:last-child) {
      margin-right: 2rem;
     }

     &.order-item-search {
      flex-basis: 100%
     }
     
    };
    @include box-bg-white;
    border-radius: 10px;
    margin-top: 2rem;
    
    &--info {
      display: flex;
      justify-content: space-between;
      align-items: center;
     
      font-size: 2rem;
    }
    &.active {
      background: linear-gradient(to left, var(--color-primary), var(--color-primary-dark)); 
    
      .order-item--info {
        color: var(--color-white);
        margin-bottom: 15px;
      }
    }
  }
}
</style>