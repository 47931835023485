<template>
  <div>
    <div class="a container">
      <h2>{{ trx.orders['Add order'] }}</h2>
      <div class="cancel-button" @click="cancelOrder">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C13.1819 3 14.3522 3.23279 15.4442 3.68508C16.5361 4.13738 17.5282 4.80031 18.364 5.63604C19.1997 6.47177 19.8626 7.46392 20.3149 8.55585C20.7672 9.64778 21 10.8181 21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4441 20.3149C14.3522 20.7672 13.1819 21 12 21L12 21Z"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path d="M9 9L15 15" stroke-width="2" stroke-linecap="round" />
          <path d="M15 9L9 15" stroke-width="2" stroke-linecap="round" />
        </svg>
      </div>
      <!-- Cancel button-->
    </div>
    <ProgressBar
      :elements="progressElements"
      :currentStep="currentStep"
      :updateStep="updateStep"
    />
    <!-- Render view-component based upon current state -->
    <div class="container">
      <component :is="currentComponent" :updateStep="updateStep" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import trx from "@/translations";
import error from "@/error";

import ProgressBar from "@/components/Controls/progress_bar.vue";
import ProductSelector from "./components/product_selecter.vue";
import OrderInformation from "./components/order_information.vue";
import OrderConfirmation from "./components/order_confirmation.vue";
import OrderSuccess from "./components/order_success.vue";

export default {
  name: "NewOrder",
  computed: {
    ...mapGetters(["order_products", "shipment_details"]),
  },
  data() {
    return {
      trx,
      progressElements: [
        {
          name: trx.products.Product,
        },
        {
          name: trx.orders.shipping_info,
        },
        {
          name: trx.orders.confirmation,
        },
      ],
      currentComponent: ProductSelector,
      currentStep: 0,
      attachments: false,
      send_state: "loading",
    };
  },
  methods: {
    ...mapActions(["createOrder", "resetOrderProducts", "resetShipmentDetails"]),
    async updateStep(step) {
      switch (step) {
        case 0:
          this.currentComponent = ProductSelector;
          break;
        case 1:
          // Check if the user has selected products, if not, don't change the view
          if (this.order_products.length === 0) {
            error(trx.validation_trx.invalid_product_items);
            return;
          }
          this.currentComponent = OrderInformation;
          break;
        case 2:
          // Check if the user has filled out all the fields, if not, don't change the view
          if (
            !this.validate({
              shipment_details: this.shipment_details,
              products: this.order_products,
            })
          ) {
            return;
          }
          this.currentComponent = OrderConfirmation;
          break;
        case 3:
          // Check if the user has filled out every field in the last steps and additionall ones
          // if not, don't send the data to the database
          // prettier-ignore
          if (this.validate({shipment_details: this.shipment_details,products: this.order_products,}, true)
          ) {
            // Send the data to the database
            // this.$store.dispatch("createOrder", {
            //   shipment_details: this.shipment_details,
            //   products: this.order_products,
            //   attachments: this.attachments,
            // });

            // Prepare a new model for the next order
            let model = {
              products: this.order_products,
              shipment_details: {
                name: this.shipment_details.name,
                surename: this.shipment_details.surename,
                streetname: this.shipment_details.streetname,
                housenumber: this.shipment_details.housenumber,
                postalcode: this.shipment_details.postalcode,
                city: this.shipment_details.city,
                houseNumberExtension: this.shipment_details.houseNumberExtension,
                countryCode: this.shipment_details.countryCode,
                email: this.shipment_details.email,
                phone: this.shipment_details.phone,
                company: this.shipment_details.company,
              },
              remark: this.shipment_details.remark,
              type: this.shipment_details.type,
              ship_option: this.shipment_details.shipment_method,
              attachments: {
                available: this.shipment_details.files.length > 0 ? true : false,
                instructions: this.shipment_details.attachment_text,
              } 
            }
            await this.createOrder(model)
            this.currentComponent = OrderSuccess;

          }
          break;
        default:
          break;
      }
      this.currentStep = step;
    },
    validate(model, submitCheck = false) {
      if (!model.products.length > 0) {
        error(trx.validation_trx.invalid_product_items);
        return false;
      }

      if (!model.shipment_details.name) {
        error(trx.validation_trx.invalid_firstname);
        return false;
      }

      if (!model.shipment_details.surename) {
        error(trx.validation_trx.invalid_surname);
        return false;
      }

      if (!model.shipment_details.countryCode) {
        error(trx.validation_trx.invalid_country);
        return false;
      }

      if (!model.shipment_details.postalcode) {
        error(trx.validation_trx.invalid_postcode);
        return false;
      }

      if (!model.shipment_details.housenumber) {
        error(trx.validation_trx.invalid_housenumber);
        return false;
      }

      if (!model.shipment_details.streetname) {
        error(trx.validation_trx.invalid_streetname);
        return false;
      }

      if (!model.shipment_details.city) {
        error(trx.validation_trx.invalid_city);
        return false;
      }

      if (!model.shipment_details.email) {
        error(trx.validation_trx.invalid_email);
        return false;
      }

      if (submitCheck) {
        // Do some additional checks before we submit
        //Check if the user has selected a shipment method
        if (!model.shipment_details.shipment_method) {
          error(trx.validation_trx.invalid_shipment_method);
          return false;
        }

        if (!model.shipment_details.type) {
          error(trx.validation_trx.invalid_type);
          return false;
        }

        // Check if we are working with a shipment outside of the EU
        // if so check if the user has filled out the customs fields and has filled out the weight of the product
        // let eu_countries = ["BE", "BG", "HR", "CZ", "DK", "DE", "EE", "GR", "ES", "FR", "IE", "IT", "CY", "LV", "LT", "LU", "HU", "MT", "NL", "AT", "PL", "PT", "RO", "SI", "SK", "FI", "SE"];
        // if (!eu_countries.includes(model.shipment_details.countryCode)) {
        //   // Check if the user has filled out the customs fields
          
        //   for (let i = 0; i < model.products.length; i++) {
        //     if (!model.products[i]?.customs?.hs) {
        //       console.log(model.products[i]);
        //       error(trx.validation_trx.invalid_hs + ": " + model.products[i].title);
        //       return false;
        //     }

        //     if (!model.products[i].value || model.products[i]?.customs?.value === 0) {
        //       error(trx.validation_trx.invalid_value  + ": " + model.products[i].title);
        //       return false;
        //     }

        //     if (!model.products[i].weight_kg ) {
        //       error(trx.validation_trx.invalid_weight  + ": " + model.products[i].title);
        //       return false;
        //     }
        //   }
        // }

        // Check if there are attachments
        if (
          (!model.shipment_details.attachment_text ||
            model.shipment_details.attachment_text === "") &&
          (!model.shipment_details.files ||
            model.shipment_details.files.length === 0)
        ) {
          // No attachments are on the object
          this.attachments = false;
        } else if (
          !model.shipment_details.attachment_text ||
          !model.shipment_details.files ||
          model.shipment_details.files.length === 0
        ) {
          // Check which variable is empty and throw an error
          if (!model.shipment_details.attachment_text) {
            error(trx.validation_trx.invalid_attachment_text);
          } else {
            error(trx.validation_trx.invalid_attachment_files);
          }
          return false;
        } else {
          // Attachments are on the object and are valid
          this.attachments = true;
        }
      }

      return true;
    },
    cancelOrder() {
      // Reset all the data and send the user back to the first step
      this.resetOrderProducts();
      this.resetShipmentDetails();

      this.updateStep(0);
    },
  },
  created() {
    let step = 0;
    // Check if the user has selected products
    if (this.order_products && this.order_products.length !== 0) {
      // If not, send the user back to the product selector
      step = 1;

      if (this.validate({ shipment_details: this.shipment_details, products: this.order_products })) {
        step = 2;
      }
    }
    this.updateStep(step);
  },
  components: {
    ProgressBar,
    ProductSelector,
    OrderInformation,
    OrderConfirmation,
    OrderSuccess,
  },
};
</script>
<style lang="scss">
.a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 0 0;
}

.state-text {
  margin: 0 0 2rem 0;
}

.cancel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 25%;
  cursor: pointer;
  box-shadow: var(--shadow-light);
  transition-duration: .3s;

  svg {
    stroke: var(--color-red);
  }
}

.cancel-button:hover {
  box-shadow: none;
}
</style>
