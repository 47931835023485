<template>
  <div class="auth-container">
    <Logo/>
    <SignupForm v-if="type === 'signup'" />
    <LoginForm v-if="type === 'login'" />
    <ResetForm v-if="type === 'reset'" />
  </div>
</template>

<script>
// import LoginBg from '@/components/Layout/LoginBg.vue';
import Logo from '@/components/Layout/Logo.vue';
import SignupForm from '@/components/Users/SignupForm.vue';
import LoginForm from '@/components/Users/LoginForm.vue';
import ResetForm from '@/components/Users/ResetForm.vue';


export default {
  name: 'AuthContainer',
  props: {
    type: String
  },
  components: {
    SignupForm,
    LoginForm,
    ResetForm,
    Logo
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" >

.auth-container {
    display: flex;
    position: fixed;
    right: 0rem;
    height: 100vh;
    min-width: 50vw;
    flex-direction: column;
    justify-content: center;
    max-width: 75vw;
    padding: 5rem;
    border-top-left-radius: 5rem ;
    border-bottom-left-radius: 5rem ;
    background: var(--color-white);
    h1 {
        margin:2rem 0rem;
    }
    .form-link {
       text-align: center;
       display: block;
       margin-top: 2rem;
   }
   @media only screen and (max-width: $bp-medium) {
        max-width: 100vw;
        width: 100vw;
        min-height: 80vh;
        height: 80vh;
        border-top-left-radius: 0rem ;
        border-bottom-left-radius: 5rem;
        border-bottom-right-radius: 5rem;
        font-size: 2rem;
        .form-link {
            margin-top: 1rem;
            font-size: 2rem;
        }
    }
}

.signupbg {
    position: fixed;
    z-index: -5;
    height: 100vh;
    width: auto;
}

.signupform-container {
 
    fieldset {
        display: flex;
        flex-direction: column;
        input {
          transition: all 0.8s;
          width: 100%;
          margin-left: 0 !important;
          &:not(:focus) {
           border: 2px solid var(--color-black)
          }
         
        }
        .form-group:not(:nth-child(even)) {
            margin-right: 0rem !important;
        }
    }

    @media only screen and (max-width: $bp-medium) {

      fieldset {
        display: flex;
        flex-direction: column;
      }

      input[type="submit"] {
        line-height: 3rem;
        height: 5rem;
      }

      .form-group { 
        width: 100%;
        input {
          border: 1px solid var(--color-primary)
        }
        
        label {
          span {
            // color:var(--color-white)
          }
        }
        .hint {
            // color: var(--color-primary-light)
          }
      }
      .errors {
        // color:var(--color-white)
      }

    }
    
  }

</style>
