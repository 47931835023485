<template>
    <div class="inb" v-if="Object.keys(inbound_item).length !== 0">
        
        <div class="flex flex-wrap">
          <div class="view flex flex-100 flex-100-md flex-100-sm flex-wrap">

            <!-- Products -->
            <div class="prd flex-45 flex-100-md flex-100-sm">
              <div class="flex flex-wrap locationFinderBox">
               
               <div class="box flex-100">
               
                   <LocationFinder v-if="inbound_item" :inbound_item="inbound_item" :fetchInboundItem="fetchInboundItem" />
                 </div>
                 
                 <div class="box flex-100 locationTransferBox" v-if="inbound_item && inbound_item.transfers && inbound_item.transfers.length > 0">
                   <TransferLocation v-if="inbound_item" :inbound_item="inbound_item" />
                 </div>
             </div>
              <div class="products inb__products productInformation">
                EAN / BSKU
                <h3><b>{{inbound_item.product_id.ean}}</b> - {{inbound_item.product_id.bsku}}  <button class=" btn-i btn-primary text-center" @click="submitAndGoBack()">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    width="20" height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                  <polyline points="20 6 9 17 4 12" />
                </svg> 
              </button></h3> 
                <ProductItem :hide_req="true" :product_id="inbound_item.product_id._id" :product="inbound_item.product_id" :user="user" :i="0" :inboundItemId="inbound_item._id" class="productItem" />
              </div>
              <div class="opmerkingen flex-100 flex-100-md">
                <div class="box" v-if="inbound_item.org">
                  <p>{{inbound_item.product_id.name}}</p>
                  <p><strong>{{ trx.inbound_details.delivery.organization }}</strong></p>
                  <p>{{ inbound_item.org.name }}, {{ inbound_item.org.contact_person }}</p>
                  <p><strong>{{ trx.inbound_details.delivery.contactInformation }}</strong></p>
                  <p>{{ inbound_item.org.contact_person_email }}, {{ inbound_item.org.phone }}</p>
                </div>
              </div>
            </div>

            <!-- Levering -->
            <div class="levering flex-30m flex-100-md flex-100-sm">
              <h3 class="header">
                <strong>{{trx.inbound_details.delivery.delivery}}</strong>
              </h3>

              <div class="flex flex-wrap">
                <div class="box flex-50 flex-25-md flex-50-sm" v-if="inbound_item">
                  <p><strong>{{trx.inbound_details.delivery.expected}}</strong></p>
                  <h3>{{inbound_item.pieces_expected}}</h3>
                </div>
                <div class="box flex-50 flex-25-md flex-50-sm">
                  <p><strong>{{trx.inbound_details.delivery.delivered}}</strong></p>
                  <h3>{{inbound_item.pieces_checked_in}}</h3>
                </div>
                <div class="box flex-50 flex-25-md flex-50-sm">
                  <p><strong>{{ trx.inbound_details.delivery.transit }}</strong></p>
                  <h3>{{inbound_item.pieces_in_transfer}}</h3>
                </div>
                <div class="box flex-50 flex-25-md flex-50-sm type">
                  <p><strong>Type</strong></p>
                  <h3>{{ inbound_item.type !== null ? inbound_item.type : '' }}</h3>
                </div>
                <div class="box flex-50 flex-50-md flex-100-sm">
                  <p><strong>Units</strong></p>
                  <input @blur="editInboundItemDetails(inbound_item)" v-model="inbound_item.units" />
                </div>
                <div class="box flex-50 flex-50-md flex-100-sm">
                  <p><strong>{{ trx.inbound_details.delivery.packedPer }}</strong></p>
                  <input @blur="editInboundItemDetails(inbound_item)" v-model="inbound_item.packed_per" />
                </div>
                
              </div>
             
              <div class="flex flex-wrap">
                <div class="box flex-100 flex-100-sm trackTrace">
                  <p><strong>Track and trace</strong></p>
                  <p>{{ inbound_item.track_trace !== '' ? inbound_item.track_trace : '' }}</p>
                </div>
              </div>
            </div>
      
            <!-- Extra -->
            <div class="extra flex-20 flex-100-md flex-100-sm flex flex-wrap">
              <div class="toeslagen flex-100 flex-100-md hidden">
                <InboundItemSurcharges v-if="inbound_item" :inbound_item="inbound_item" />
              </div>
              <div class="bijlagen flex-100 flex-100-md hidden">
                <h3>Bijlagen</h3>
                <input type="file" ref="fileInput" multiple @change="handleFileUpload" />
                <button @click="uploadImages(inbound_item.product_id._id)" :disabled="uploadStatus === 'uploading'">Upload</button>
                <p v-if="uploadStatus === 'success'">Images uploaded successfully</p>
                <p v-if="uploadStatus === 'error'">Error uploading images</p>
              </div>
              <div class="opmerkingen flex-100 flex-100-md">
                <h3>{{ trx.inbound_details.note }}</h3>
                <textarea @blur="editInboundItemDetails(inbound_item)" v-model="inbound_item.notes"></textarea>

                <div class="box">
                  <p><strong>Batch</strong></p>
                  <input @blur="editInboundItemDetails(inbound_item)" v-model="inbound_item.batch_nr" />
                </div>
                <div class="box">
                  <p><strong>{{ trx.inbound_details.location.expirationDate }}</strong></p>
                  <input @blur="editExpirationDate()" v-model="formattedExpirationDate" type="date" />
                </div>
                <div class="box packageClosedCheckbox">
                  <p><strong>{{ trx.inbound_details.location.isPackageClosed }}?</strong></p>
                  <input type="checkbox" v-model="inbound_item.package_closed" @change="editInboundItemDetails(inbound_item)" />
                </div>
              </div>
              
              
            </div>
          
          </div>

        </div>
  
     
    </div>
    <div v-else>
      <div v-if="fetching_data">
        <h3>Loading data...</h3>
      </div>
      <div v-else>
        <h3>Geen inbound item gevonden</h3>
        <h4>Inbound met ID: {{ this.$route.params.id }} bestaat niet</h4>
      </div>
    </div>
  </template>
  
  <script>
  import { mapMutations, mapGetters, mapActions } from "vuex";
  import ProductItem from "../product_item.vue";
  import LocationFinder from "./locationFinder.vue";
  import InboundItemSurcharges from '../components/inboundItemSurcharges.vue';
  import TransferLocation from './transferLocation.vue';
  import trx from '@/translations';
  
  export default {
    name: "InboundDetails",
    computed: {
      ...mapGetters(["inbound_item", "uploadStatus", "user"])
    },
    components: {
      ProductItem,
      LocationFinder,
      InboundItemSurcharges,
      TransferLocation
    },
    data() {
      return {
        trx,
        formattedExpirationDate: null,
        fetching_data: false,
      };
    },
    methods: {
      ...mapActions([
        "fetchUser",
        "fetchInboundItem",
        "getProducts",
        "editInboundItemDetails",
        "uploadImages",
        "addLocationToInbitem",
        "editStatusLocationFinder",
        "submitInbItem"
      ]),
      ...mapMutations(["SET_SELECTED_FILES"]),
  
      handleFileUpload(event) {
        const files = Array.from(event.target.files);
        this.SET_SELECTED_FILES(files);
      },
  
      submitAndGoBack() {
        this.submitInbItem({ id: this.inbound_item._id });
        this.$router.push("/checkin_inventory");
      },
      editExpirationDate() {
        this.inbound_item.expiration_date = this.formattedExpirationDate;
        this.editInboundItemDetails(this.inbound_item);
      }
    },
    beforeRouteUpdate(to, from, next) {
      if (to.params.id !== from.params.id) {
        const newId = to.params.id;
        this.fetchInboundItem({ id: newId });
      }
      next();
    },
    async created() {
      this.fetchInboundItem({ id: this.$route.params.id });
      this.formattedExpirationDate = this.inbound_item.expiration_date != null ? this.inbound_item.expiration_date.slice(0, 10) : null;
    }
  };
  </script>
  
  <style lang="scss" scoped >

  

  .inb {
    
    // max-width: 800px;
    &__products {
      margin-top: 0rem;
      
    }
    &__save_btn {
        margin-bottom: 2rem;
        width: 100%;
        min-height: 5rem;
        font-size: 2rem;
        height: 7rem !important;
        text-align: center;
    }
  }
  .view {
    align-items: flex-start;
    margin-bottom: 2rem;
    gap: 2rem;
    .levering,
    .locatie {
      border-radius: 2rem;
      padding: 2rem;
      margin-right: 1.5rem;
      gap: 1.5rem;
      .box {
        @include box-bg-white;
        border-radius: 1rem;
        padding: 1.5rem;
      }
    }

    .locationFinderBox {
        padding-bottom: 1rem !important;
        @include box-bg-dash;
        padding: 2rem;
        min-height: 5rem;
    }

    .prd {
      gap: 2rem;
      display: flex;
      flex-direction: column;
    }

    .levering {
      background-image: linear-gradient(to right bottom, var(--color-primary-dark), var(--color-primary-mid));
      display: flex;
      flex-direction: column;
      gap: 1rem;
      > div {
        gap: 1rem
      }
      filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.4));

      .header {
        color: var(--color-white);
      }
      @media all and (max-width: $bp-medium) {
        order: 3;
      }
      p {
        text-overflow: ellipsis;
        max-width: 25rem;
        white-space: nowrap;
        overflow: hidden; 
      }
    }

    .locatie {
      border: 1px solid var(--color-primary-dark);
      display: flex;
      flex-direction: column;
      .box {
        border: 1px solid var(--color-primary-dark);
      }
      @media all and (max-width: $bp-medium) {
        order: 1;
      }
    }

    .extra {
      gap: 1rem;
      align-items: stretch;
      .toeslagen,
      .bijlagen,
      .opmerkingen {
        border: 1px solid var(--color-primary-dark);
        padding: 2rem;
        border-radius: 2rem;
      }
      @media all and (max-width: $bp-medium) {
        order: 3;
      }
    }
  }

  .packageClosedCheckbox {
    display: flex;
    gap: 4rem;
    input {
      margin: 0;
      padding: 0;
      max-width: max-content;
    }
  }

  .submitInbItem {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
  }

  </style>