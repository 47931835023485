import axios from 'axios';
import {elementReady} from './lib/elementExist';
// import router from './router';
// import store from '@/store';
// import router from '@/router';
import trx from '@/translations'
/* eslint-disable no-debugger */

let api = axios.create({
    baseURL: '/api'
});


api.interceptors.response.use(function (response) {
    
    return response
    
    }, function(error) {
        // if (error.response.status === 404) {
        //     return Promise.reject(error.response.data)
        // }

        let msg = trx['Something went wrong, please conduct your logs']
    
        if (error.response.status === 401) {
            msg = "Your are not authorized for this action"
            if (window.location.pathname == '/') {
                return;
            }
        }

        if (error.response.status === 406 || error.response.status === 400) {
            msg = error.response.data;
        }
        
        document.body.classList.remove('loading-indicator');
        document.querySelector('.loading').remove();
        
        document.body.classList.add('loading-indicator-error');

        document.querySelectorAll('a, button, [href], .order__details__shipment__option ').forEach(x => {
            x.classList.remove('loading-button')
        });

        document.querySelector('.loading-indicator-error').insertAdjacentHTML('afterbegin', `<div class="loading-error"> 
                <p class="loading-error__message">
                    ${msg}
                </p>
            </div>`);

        return Promise.reject(error.response.data)

   });
   
api.interceptors.request.use(config => {

    if (!document.body.classList.contains('loading-indicator')) {

        document.body.classList.add('loading-indicator');
        document.querySelectorAll('a, button, [href], .order__details__shipment__option').forEach(x => {
            x.classList.add('loading-button')
        });

        document.querySelectorAll('input, textarea').forEach(y => {
            y.classList.add('loading-input')
            y.insertAdjacentHTML('afterend', '<span class="loading-spinner"></span>')
        });

        document.querySelector('.loading-indicator').insertAdjacentHTML('afterbegin', 
            `<div class="loading"> <p class="loading__message"> Loading ...</p></div>`);

    }
    
    return config
});

api.interceptors.response.use(response => {
    elementReady('body').then(x => {
        if (document.body.classList.contains('loading-indicator')) {
            x.classList.remove('loading-indicator');
            x.classList.remove('loading-indicator-error');
            document.querySelector('.loading').remove()  
            document.querySelectorAll('.loading-spinner').forEach(y => {
                y.remove()
            });  
            document.querySelectorAll('input, textarea').forEach(x => {
                x.classList.remove('loading-input')
            }); 
            document.querySelectorAll('a, button, [href], .order__details__shipment__option ').forEach(x => {
                x.classList.remove('loading-button')
            });
        }

    });

    return response
});

export default api